import { CitiesModel } from "../../types/models/Cities";
import {
  CitiesActionTypes,
  SEARCH_CITY,
  LOADING_CITY
} from "../../types/actions/Cities.action";

const initialState = {
  cities: [],
  loading: false
};

const Cities = (state = initialState, action: CitiesActionTypes) => {
  switch (action.type) {
    case SEARCH_CITY:
      return {
        ...state,
        cities: action.payload

      };
    case LOADING_CITY:
      return {
        ...state,
        loading: action.payload

      };

    default:
      return state;
  }
};

export default Cities;
