import { Grid, TableContainer, Chip, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"
import { allPreResultVariables, areaToLocaleString, calagemSatCTC, localeString } from "../../../../shared/utils/utils"
import { Fonts } from "../../../../shared/constants/AppEnums"
import { useEffect, useState } from "react"
import { THEME_H4_APLICATION, THEME_TABLE_ROW } from "../../../../shared/themes/themeConst"


export default function CalagemSatBasesCTC({ data }) {
  const {
    setPreResultZones,
    preResultZones,
    labResult,
    formValues
  } = data

  const defineDolomiticoCalcitico = (CaMg) => {
    if(CaMg && CaMg > 0){
      if(CaMg >= 3){
        return <Chip label="Dolomítico" color="info" /> 
      } else if(CaMg < 3) {
        return <Chip label="Calcítico" color="primary" />
      }
    }
    return '-'
  }

  const [labKeys, setKeys] = useState([]) as any
  const [totalArea, setTotalArea] = useState(0)

  useEffect(() => {
    const arrayKeys = Object.keys(labResult).sort((a:any, b:any) => a.split('-')[0] - b.split('-')[0])
    setKeys(arrayKeys)

    let auxZones = {}
    var auxTotalNC = 0
    var auxTotalArea = 0
    
    arrayKeys.forEach((key) => {
      auxTotalArea=0
      labResult[key].forEach((result) => {
        const correctionDepth = (formValues['analysisDepth'].find((als) => als.key == key))['correction'] / 100
        
        if(!(result.zone.name in auxZones)){
          auxZones[result.zone.name] = {
            totalNC: 0
          }
        }
        var resultCalagemSatCTC = calagemSatCTC( formValues['parameters']['V2%'], result['SatBases'], formValues['parameters']['PRNT%'], formValues['parameters']['efficiencyFactor'], result['CTC(T)'], correctionDepth )

        if(resultCalagemSatCTC > 0){
          auxTotalNC = auxTotalNC + (result.zone.area * resultCalagemSatCTC)
        }

        auxTotalArea += result.zone.area ? result.zone.area : 0

        auxZones[result.zone.name]['totalNC'] = auxZones[result.zone.name]['totalNC'] + (resultCalagemSatCTC > 0 ? resultCalagemSatCTC : 0)
        auxZones[result.zone.name]['name'] = result.zone.name
        auxZones[result.zone.name]['area'] = result.zone.area
        auxZones[result.zone.name][key] = {
          ...allPreResultVariables,
          value: result['Ca/Mg'],
          satBase: result['SatBases'],
          NC: resultCalagemSatCTC > 0 ? resultCalagemSatCTC : 0
        }
      })
    })

    setPreResultZones({
      totalNC: auxTotalNC,
      zones: Object.values(auxZones)
    })
    setTotalArea(auxTotalArea)
  
  }, [])

  return (
    <Grid container sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ overflow: 'hidden' }}>
        <Table size="small" stickyHeader >
        <caption><h4 style={THEME_H4_APLICATION}>Total NC: {localeString(preResultZones.totalNC)} (Kg)</h4></caption>
        <caption><h4 style={THEME_H4_APLICATION}>Área Total: {localeString(totalArea)} (ha)</h4></caption>
          <TableHead>
            <TableRow sx={THEME_TABLE_ROW}>
              <TableCell style={{minWidth: 100}} align="center">Zona</TableCell>
              <TableCell style={{minWidth: 100}} align="center">Área (ha)</TableCell>
              {
                labKeys.map((key, index) => {
                  return(
                    <>
                      <TableCell style={{minWidth: 100}} align="center">{`V% (${key})`}</TableCell>      
                      <TableCell style={{minWidth: 100}} align="center">{`${key} Calcário`}</TableCell>    
                      <TableCell style={{minWidth: 100}} align="center">{`NC ${key} (Kg/ha)`}</TableCell>
                    </>
                  )
                })
              }
              <TableCell style={{minWidth: 100}} align="center">Total</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              preResultZones.zones.map(((zone, index) => {
                return (
                  <TableRow hover key={index} sx={THEME_TABLE_ROW}>
                    <TableCell align="center"> {zone.name} </TableCell>
                    <TableCell align="center"> {localeString(zone.area)} </TableCell>
                    {
                      labKeys.map((key, index) => {
                        if(!zone[key]) return <></>
                        return (<>
                          <TableCell align="center">{areaToLocaleString(zone[key]['satBase'],2,0)}</TableCell>      
                          <TableCell align="center"> {defineDolomiticoCalcitico(zone[key]['value'])}</TableCell>
                          <TableCell align="center">{localeString(zone[key]['NC'])}</TableCell>
                        </>
                        )
                      })
                    }
                    <TableCell align="center"> {localeString(zone.area * zone.totalNC)} </TableCell>
                </TableRow>
                )
              }))
            }
          </TableBody>
        </Table>
        
      </TableContainer>
    </Grid>
  )
}