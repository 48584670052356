import jwtAxios from "@crema/services/auth/jwt-auth";
import { sleep } from "@crema/utility/Utils";
import { Dispatch } from "redux";
import { AppActions } from "types";
import { SEARCH_CITY } from "types/actions/Cities.action";


export const getCities = (
  name: string,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      let { data } = await jwtAxios
        .get("/cities", {
          params: {
            city: name,
          },
        })
      dispatch({ type: SEARCH_CITY, payload: data });

    } catch (e) {

    }


  };
};

