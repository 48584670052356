import React from "react"
const RecommendationsPage = React.lazy(() => import("./recommendations"))
const WorkResults = React.lazy(() => import("./results"))
const LimingPage = React.lazy(() => import("./liming"))
const OSCreate = React.lazy(() => import("./register"))
const OSList = React.lazy(() => import("./list"))


export const osPagesRoutes = [
  {
    path: "/os",
    element: <OSList />,
  },

  {
    path: "/os/:osId/subordem/:workId/resultados",
    element: <WorkResults data={{osId: null, workId: null}} />,
  },

  {
    path: "/os/:osId/subordem/:workId/recomendacoes",
    element: <RecommendationsPage data={{}} />,
  },

  {
    path: "/os/:osId/subordem/:workId/recomendacao",
    element: <LimingPage />,
  },

  {
    path: "/cliente/:idClient/nova/os",
    element: <OSCreate />,
  },

  {
    path: "/cliente/:idClient/os/:osId",
    element: <OSCreate />,
  },

  {
    path: "/cliente/:idClient/os/:osId/subordem/:workId",
    element: <OSCreate />,
  },  
]
