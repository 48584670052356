import { TextMaskCustomCpfOrCnpj, TextMaskCustom } from "@crema/core/TextMaskCustom"
import { isValidCPF, isValidCNPJ } from '@brazilian-utils/brazilian-utils'
import LayoutPadrao from "../../../@crema/core/AppLayout/LayoutPadrao"
import AppTextField from "@crema/core/AppFormComponents/AppTextField"
import { fetchSuccess, getCities, showMessage } from "redux/actions"
import CircularProgress from '@mui/material/CircularProgress'
import { Grid, Paper, Typography } from "@mui/material"
import IntlMessages from '@crema/utility/IntlMessages'
import { useDispatch, useSelector } from "react-redux"
import Autocomplete from '@mui/material/Autocomplete'
import jwtAxios from '@crema/services/auth/jwt-auth'
import { onlyNumbers } from '@crema/utility/Utils'
import LoadingButton from '@mui/lab/LoadingButton'
import AppInfoView from "@crema/core/AppInfoView"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { AppState } from "redux/store"
import { Form, Formik } from "formik"
import { useIntl } from "react-intl"
import * as yup from "yup"


const RegisterClient = () => {
  const { messages } = useIntl()
  const dispatch = useDispatch()
  let { id } = useParams()
  const navigate = useNavigate()
  const [client, setClient] = useState({}) as any


  const { cities } = useSelector<AppState, AppState["cities"]>(
    ({ cities }) => cities
  )

  const validationSchema = yup.object({
    cpfCnpj: yup.string()
      .test("isCpfOrCnpj", String(messages["validation.documentFormat"]), value => (isValidCPF(value as string) || isValidCNPJ(value as string)))
      .required(String(messages["validation.documentRequired"])),
    email: yup
      .string()
      .email(String(messages["validation.emailFormat"]))
      .required(String(messages["validation.emailRequired"])),
    phone: yup
      .string()
      .min(13, String(messages["validation.phoneFormat"]))
      .required(String(messages["validation.phoneRequired"])),
    address: yup
      .string()
      .min(10, String(messages["validation.enderecoFormat"]))
      .required(String(messages["validation.enderecoRequired"])),
    name: yup
      .string()
      .min(3, String(messages["validation.nameFormat"]))
      .required(String(messages["validation.nameRequired"])),
    city_id: yup
      .object().typeError(String(messages["validation.cidadeRequired"])).required(String(messages["validation.cidadeRequired"])),
  })

  useEffect(() => {
    if (id) {
      getClient(id!)
    }
  }, [id])

  const getClient = async (uuid: string) => {
    try {
      let { data } = await jwtAxios.get("/clients/" + uuid)
      setClient(data)
    } catch (e) {

    }
  }

  return (
    <LayoutPadrao>
      <Typography sx={{ mb: 5 }} variant="h1" component="h2">
        Cliente
      </Typography>
      <Paper elevation={0} sx={{padding: '5%'}}>
        <>
          {
            (client!.uuid || !id ) ?
              <Formik
                validateOnChange={true}
                initialValues={{
                  email: client!.email,
                  name: client!.name,
                  address: client!.address,
                  cpfCnpj: client!.cpfCnpj,
                  city_id: client!.city,
                  note: client!.note,
                  phone: client!.phone
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                  try {
                    let dataForPost = {
                      ...data,
                      name: data['name'].trim(),
                      cpfCnpj: onlyNumbers(data.cpfCnpj),
                      phone: "+55" + onlyNumbers(data.phone),
                      cityId: data.city_id.id
                    }
                    if (!id) {
                      let { data } = await jwtAxios.post("/clients/", dataForPost)
                      navigate("/clientes/" + data.uuid, {
                        replace: true
                      })
                    }
                    else {
                      await jwtAxios.put("/clients/" + id, dataForPost)
                    }
                    setSubmitting(false)
                    dispatch(fetchSuccess())
                    dispatch(showMessage(id ? String(messages["common.editSuccess"]) : String(messages["common.registerSuccess"])))
                  } catch (e) {
                    console.log(e)
                  }
                }}
              >
                {({ isSubmitting, values, handleChange, setFieldValue }) => (
                  <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                    <Grid container spacing={6}>
                      <Grid item xs={12}>

                        <AppTextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="false"
                          placeholder={messages["common.name"] as string}
                          name="name"
                          label={<IntlMessages id="common.name" />}
                          variant="outlined"

                          sx={{
                            width: "100%",
                            "& .MuiInputBase-input": {
                              fontSize: 14,
                            },
                          }}
                        />
                      </Grid>
                      
                      <Grid item xs={6}>
                        <AppTextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="false"
                          placeholder={messages["common.email"] as string}
                          name="email"
                          label={<IntlMessages id="common.email" />}
                          variant="outlined"
                          sx={{
                            width: "100%",
                            "& .MuiInputBase-input": {
                              fontSize: 14,
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <AppTextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: TextMaskCustom as any,
                            inputProps: {
                              mask: "(##) #########"
                            }
                          }}

                          autoComplete="false"
                          placeholder={messages["common.phone"] as string}
                          name="phone"
                          label={<IntlMessages id="common.phone" />}
                          variant="outlined"
                          sx={{
                            width: "100%",
                            "& .MuiInputBase-input": {
                              fontSize: 14,
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <AppTextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: TextMaskCustomCpfOrCnpj as any,
                          }}
                          autoComplete="false"
                          placeholder={messages["common.cpfoucnpj"] as string}
                          name="cpfCnpj"
                          label={<IntlMessages id="common.cpfoucnpj" />}
                          variant="outlined"
                          sx={{
                            width: "100%",
                            "& .MuiInputBase-input": {
                              fontSize: 14,
                            },
                          }}
                        />

                      </Grid>

                      <Grid item xs={12}>
                        <AppTextField
                          autoComplete="false"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder={messages["common.endereco"] as string}
                          name="address"
                          label={<IntlMessages id="common.endereco" />}
                          variant="outlined"
                          sx={{
                            width: "100%",
                            "& .MuiInputBase-input": {
                              fontSize: 14,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>

                        <Autocomplete
                          onChange={(_, value) => {
                            if (value) {
                              setFieldValue('city_id', value)
                            }

                          }}
                          onInputChange={async (event, newInputValue) => {
                            if (newInputValue && newInputValue.length > 3) {
                              dispatch(getCities(newInputValue))
                            }
                          }}
                          filterOptions={(x) => x}
                          defaultValue={client.city}
                          getOptionLabel={(option: any) => `${option!.name} - ${option!.state.uf}  `}
                          options={cities}
                          sx={{ width: '100%' }}
                          renderInput={(params) => {
                            return (
                              <AppTextField
                                {...params}
                                required
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder={messages["common.cidade"] as string}
                                name="city_id"
                                label={<IntlMessages id="common.cidade" />}
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                  "& .MuiInputBase-input": {
                                    fontSize: 14,
                                  },
                                }}
                              />
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AppTextField
                          autoComplete="false"
                          multiline

                          rows={4}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder={messages["common.observation"] as string}
                          name="note"
                          label={<IntlMessages id="common.observation" />}
                          variant="outlined"
                          sx={{
                            width: "100%",
                            "& .MuiInputBase-input": {
                              fontSize: 14,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item container direction="row" alignItems="center" justifyContent="flex-end">
                        <LoadingButton
                          loading={isSubmitting}
                          variant="contained"
                          color="primary"
                          type="submit"
                          size="large">
                          <IntlMessages id="common.save" />
                        </LoadingButton>
                      </Grid>

                    </Grid>
                  </Form>
                )}

              </Formik> : <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
          }
        </>
        <AppInfoView />
      </Paper>
    </LayoutPadrao>
  )
}

export default RegisterClient
