import React from "react"
const ConfigLabel = React.lazy(() => import("./configLabel"))

export const configPage = [
  {
    path: "/configuracao",
    element: <ConfigLabel />,
  },
];


