import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { Typography } from "@mui/material"

export default function AlertDialogDataLoss({ data }) {
  const { handleClose, open } = data

  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => handleClose(false)} >
        <DialogTitle>
        <Typography variant="h3" gutterBottom sx={{fontWeight: 700}}>
          {"Aviso: Alterações não salvas"}
        </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' sx={{ m: 0, p: 2, fontWeight: 700 }}>
            Você fez alterações neste formulário que ainda não foram salvas. Se você sair desta página agora, todas as alterações serão perdidas. Certifique-se de salvar suas alterações antes de
            prosseguir.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={() => handleClose(false)}>Cancelar</Button>
          <Button color="error" onClick={() => handleClose(true)} autoFocus>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
