import React from "react"
const UsersList = React.lazy(() => import("./list"));
const EditUser = React.lazy(() => import("./register"));

export const UsersPagesRoutes = [
  {
    path: "/usuarios",
    element: <UsersList />,
  },

  {
    path: "/usuario/:userUUID",
    element: <EditUser />,
  },

  {
    path: "/usuario/novo",
    element: <EditUser />,
  },
  
]
