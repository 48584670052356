import { Avatar, Button, Typography } from '@mui/material'
import { Fonts } from 'shared/constants/AppEnums'
import AppCard from '@crema/core/AppCard'
import Box from '@mui/material/Box'


const Card = ({data}) => {
  const { 
    avatarInitials,
    navigate,
    cardId,
    edtURL,
    viewURL,
    name,

  } = data

  return (
    <Box>
      <AppCard
        footerPosition="right"
        sxStyle={{ borderRadius: 4 }}
        className="card-hover"
      >
        <Box></Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: 'row' }}>
            <Avatar
              sx={{
                color: "#00800d",
                background: "#E7F4FC",
                p: 3,
                height: { xs: 46, md: 50, xl: 60 },
                width: { xs: 46, md: 50, xl: 60 },
              }}
            >
              {(avatarInitials.length > 1 ? avatarInitials[0][0] + avatarInitials[1][0] : avatarInitials[0].slice(0,2)).toLocaleUpperCase()}
            </Avatar>
            <Box
              sx={{
                position: "relative",
                ml: 4,
              }}
            >
              <Box
                component="p"
                sx={{
                  fontSize: 12,
                  color: "text.secondary",
                  mb: 2,
                }}
              >
                #{cardId}
              </Box>
              <Box
              onClick={() =>navigate(viewURL)}
               
              >
                <Typography fontWeight={Fonts.MEDIUM} fontSize={17} mr={3} sx={{cursor: 'pointer'}}>
                  {name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ float: "right", width: '60px'}}>
            <Button size="small" onClick={ () => navigate(edtURL)}>Editar</Button>
            <Button size="small" onClick={ () => navigate(viewURL) }>Ver</Button>
          </Box>
        </Box>
      </AppCard>
    </Box>
  );
};

export default Card;