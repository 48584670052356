import React from "react";

const UserProfile = React.lazy(() => import("./enterpriseProfile"));

export const profilePage = [
  {
    path: "/perfil",
    element: <UserProfile />,
  },
];
