import * as kriging from "@sakitam-gis/kriging"
import * as turf from "@turf/turf"
import chroma from "chroma-js"

export const ARCHIVER_COMPRESSION_SUPPORTED = [
  "application/x-zip-compressed",
  "application/zip",
  "application/octet-stream",
  "application/zip-compressed",
  "application/x-zip-compressed",
  "application/x-zip",
]
export const RESULT_SUPPORTED = ["text/csv"]

export const assemblePolygonsGoogleMaps = (zones: any, turf: any) => {
  zones.sort((a, b) => (Number.parseInt(a.name) > Number.parseInt(b.name) ? 1 : -1))
  return zones.map((pol, index) => {
    return {
      name: pol.name,
      center: turf.centerOfMass(turf.polygon(pol.polygon.coordinates)),
      area: pol.area,
      id: pol.fieldId,
      pointsCollection: pol.points,
      coordinatesArray: pol.polygon.coordinates[0],
      technicalTeamId: pol.technicalTeamId ? pol.technicalTeam.uuid : "",
      coordinates: pol.polygon.coordinates[0].map(coordinates => {
        return {
          lat: coordinates[0],
          lng: coordinates[1],
        }
      }),
    }
  })
}

export const simpleAssemblePolygonsGoogleMaps = (zones: any, turf: any) => {
  zones.sort((a, b) => (Number.parseInt(a.name) > Number.parseInt(b.name) ? 1 : -1))
  return zones.map(pol => {
    return {
      name: pol.name,
      center: turf.centerOfMass(turf.polygon(pol.polygon.coordinates)),
      area: pol.area,
      id: pol.fieldId,
      coordinatesArray: pol.polygon.coordinates[0],
      coordinates: pol.polygon.coordinates[0].map(coordinates => {
        return {
          lat: coordinates[0],
          lng: coordinates[1],
        }
      }),
    }
  })
}

export const calagemSatCTC = (V2, VSatBases, PRNT, FE, CTC_TOTAL, correction = 1) => {
  const parceEfficiencyFactor = FE / 100
  return (((((V2 - VSatBases) * CTC_TOTAL) / PRNT) * 100) / parceEfficiencyFactor) * correction
}

export const fosfatagem = (p, Pres, Arg, efficiencyFactor) => {
  const parceEfficiencyFactor = efficiencyFactor < 2 ? efficiencyFactor : efficiencyFactor / 100

  if (Pres < 15) {
    return (15 - Pres) * (2.5495 + 0.0227 * Arg) + ((p - 15) * 2.29 * 2) / parceEfficiencyFactor
  } else {
    return ((p - Pres) * 2.29 * 2) / parceEfficiencyFactor
  }
}

export const plastering = (SValor, SatArg) => {
  const pResult = -0.0007 * Math.pow(SatArg, 2) + 1.0055 * SatArg - 18.309 - SValor * 4
  return pResult < 0 ? 0 : pResult
  // return (CTCTotal < 50) ? ((-3.3333 * SValor + 50) < 0 ? 0 : -3.3333 * SValor + 50) : ((-1.2105 * SValor + 52.895) < 0 ? 0 : -1.2105 * SValor + 52.895);
}

export const plasteringV2 = (SValorTotal, SatArgAvg) => {
  if (SValorTotal * 2 >= 45) {
    return 0
  }
  let sResult = (-0.0001 * Math.pow(SatArgAvg, 2) + 0.3037 * SatArgAvg + 10.729) - (SValorTotal * 2)
  return sResult <= 0 ? 0 : sResult
}

export const preResultPotashCriticalLevel = (ctcTotal, metaK, kmgValue, efficiencyFactor) => {
  return metaK - kmgValue < 0
    ? 0
    : kmgValue < 80
    ? ctcTotal < 50
      ? 113.03 - 0.8672 * kmgValue + 0.0041 * kmgValue ** 2
      : 251.56 - 2.9416 * kmgValue + 0.0127 * kmgValue ** 2
    : ((metaK - kmgValue) * 1.205 * 2) / (efficiencyFactor / 100)
}

export const preResultPotashCriticalLevelV2 = (metaK, kmgValue, efficiencyFactor) => {
  return metaK - kmgValue < 0 ? 0 : (metaK - kmgValue) * 1.20458 * 2
}

export const preResultBoron = BValue => {
  return 1.6541 * Math.pow(BValue, 2) - 3.2884 * BValue + 2.0338 < 0 ? 0 : 1.6541 * Math.pow(BValue, 2) - 3.2884 * BValue + 2.0338
}

export const preResultZinc = zValue => {
  return -1.9709 * zValue + 3.8481 < 0 ? 0 : -1.9709 * zValue + 3.8481
}

export const preResultPotashCriticalLevelExcedente = (metaK, kmgValue) => {
  return metaK - kmgValue < 0 ? Math.abs(metaK - kmgValue) * 1.2 * 2 : 0
}

export const preResultPotash_K_CTC = (ctcTotal, kctcDesejado, k_value, efficiencyFactor) => {
  return ctcTotal * (kctcDesejado / 100) - k_value <= 0 ? 0 : (ctcTotal * (kctcDesejado / 100) - k_value) * 39.1 * 1.205 * 2
}

export const preResultPotashExcedente = (ctcTotal, kctcDesejado, k_value) =>
  ctcTotal * (kctcDesejado / 100) - k_value >= 0 ? 0 : Math.abs(ctcTotal * (kctcDesejado / 100) - k_value) * 39.1 * 1.205 * 2

export const fosfatagemExedente = (p, Pres) => {
  return p - Pres < 0 ? Math.abs(p - Pres) * 2.29 * 2 : 0
}

export const aplicationPhosphate = (NC, maintenance, additional, concentration, efficiencyFactor = 1) => {
  return NC / concentration
}

export const aplicationPhosphateV2 = (NC, concentration) => {
  return NC / concentration
}

export const aplicationPotash = (NC, maintenance, additional, tpPotash, efficiencyFactor = 100, exedente = 0) => {
  const parceEfficiencyFactor = efficiencyFactor / 100
  const phosphateValue = tpPotash == "kcl" ? 0.6 : 1
  return (NC + (additional + maintenance - exedente) / parceEfficiencyFactor) / phosphateValue
}

export const aplicationPotashV2 = (NC, concentration, efficiencyFactor = 100) => {
  const parceEfficiencyFactor = efficiencyFactor / 100
  return NC / parceEfficiencyFactor / concentration
}

export const aplicationPlastering = (NC, concentration, efficiencyFactor = 100) => {
  const parceEfficiencyFactor = efficiencyFactor / 100
  return NC / parceEfficiencyFactor / concentration
}

export const aplicationBoro = (NC, concentration, efficiencyFactor = 100) => {
  const parceEfficiencyFactor = efficiencyFactor / 100
  return NC / parceEfficiencyFactor / concentration
}

export const aplicationZinc = (NC, concentration, efficiencyFactor = 100) => {
  const parceEfficiencyFactor = efficiencyFactor / 100
  return NC / parceEfficiencyFactor / concentration
}

export const auxRecommendationPhosphatage = (value, minValue, maxValue) => {
  if (maxValue && value > maxValue) {
    value = maxValue
  } else if (minValue && value < minValue) {
    value = minValue
  }
  return value
}

export const convertToGeojson = (coordinates, type, properties) => {
  return {
    type: "Feature",
    geometry: {
      type: type,
      coordinates: coordinates,
    },
    properties: properties,
  }
}

export const auxRecommendation = (value, additional, minValue, maxValue, zeroBelowMinimum = false) => {
  value = value + (additional ? additional : 0)
  if (maxValue && value > maxValue) {
    value = maxValue
  } else if (minValue && value < minValue) {
    value = zeroBelowMinimum ? 0 : minValue
  }
  return value
}

export const localeString = (value: number) => (value ? Math.trunc(value).toLocaleString("pt-br") : value)
export const areaToLocaleString = (value: number, maximumFractionDigits = 2, minimumFractionDigits = 2) =>
  !value ? 0 : value.toLocaleString("pt-br", { maximumFractionDigits: maximumFractionDigits, minimumFractionDigits: minimumFractionDigits })

export const allPreResultVariables = {
  potash: "",
  exedente: "",
  k: "",
  SatArg: "",
  fosfatagem: "",
  preResult: "",
  Zn: "",
  B: "",
  NC: "",
  value: "",
  PRes: "",
  S: "",
  CTC: "",
  plastering: "",
}

export const DEFAULT_COORDENATES = [-23.7779078, -49.9608293]

export const AUX_APLICATION_NAME = (appRec, application) => {
  let applicationName = application["typeRecomendation"] ? application["typeRecomendation"].name : ""

  if (appRec.recommendationsId == 73) {
    if (application["limestoneType"] && appRec.recommendationsId == 73) {
      applicationName = application["limestoneType"].name
    } else if (application["typePhosphate"] && appRec.recommendationsId == 74) {
      applicationName = application["typePhosphate"].name
    }
  }
  return applicationName
}

export const auxRecommendationV2 = (value, minValue, maxValue, zeroBelowMinimum = false, disposalLimit = 0) => {
  if (maxValue && value > maxValue) {
    value = maxValue
  } else {
    value = disposalLimit && value <= disposalLimit ? 0 : minValue && value < minValue ? (zeroBelowMinimum ? 0 : minValue) : value
  }

  return value
}

export const roundApplicationValue = (value, decimalPlaces) => {
  const maxDecimalPlaces = value.toString().split(".")[0].length
  const multiplier = Math.pow(10, decimalPlaces >= maxDecimalPlaces ? maxDecimalPlaces - 1 : decimalPlaces)
  return Math.round(value / multiplier) * multiplier
}

export const roundSimplyApplicationValue = (value, decimalPlaces) => {
  return Number.parseFloat((Math.ceil(value) / 1000).toFixed(decimalPlaces)) * 1000
}

export const bboxAdjustingSquare = (bboxPolygon, expandFactor = 0.35, equalSides = true) => {
  var width = bboxPolygon[2] - bboxPolygon[0]
  var height = bboxPolygon[3] - bboxPolygon[1]

  var expandWidth = width * expandFactor
  var expandHeight = height * expandFactor
  var centerX = (bboxPolygon[2] + bboxPolygon[0]) / 2
  var centerY = (bboxPolygon[3] + bboxPolygon[1]) / 2

  var newWidth = width + expandWidth
  var newHeight = height + expandHeight

  if (equalSides) {
    if (newWidth > newHeight) {
      newHeight = newWidth
    } else {
      newWidth = newHeight
    }
  }

  var minX = centerY - newHeight / 2
  var minY = centerX - newWidth / 2
  var maxX = centerY + newHeight / 2
  var maxY = centerX + newWidth / 2

  return [maxY, maxX, minY, minX]
}

export const getFieldCoodnatesAndBboxx = polygonBbox => {
  if (polygonBbox) {
    const polyTurf = turf.polygon([polygonBbox.coordinates.map(polyCoord => [polyCoord[1], polyCoord[0]])])
    const bounds = new google.maps.LatLngBounds()
    var arrayPoint = [] as any

    polygonBbox.coordinates.forEach(element => {
      bounds.extend(new google.maps.LatLng(element[0], element[1]))
      arrayPoint.push({ lat: element[0], lng: element[1] })
    })

    return {
      polyTurfBbox: turf.bbox(polyTurf),
      polyTurf: polyTurf,
      boundsGoogle: bounds,
      fieldArea: turf.area(polyTurf) / 1000,
      polyGoogleCoodinates: polygonBbox.coordinates.map(cood => {
        return {
          lat: cood[0],
          lnt: cood[1],
        }
      }),
    }
  }
  return null
}

export const getZoneColorInterpolationv = (value, rangeLabels) => {
  let colorFinal = "#000000" as any
  let interval = 0

  if (value != null) {
    for (let i = 0; i < rangeLabels.length; i++) {
      if (rangeLabels[i].start <= value) {
        interval = i
      }
    }
  }

  if (interval == rangeLabels.length - 1) {
    var scaleChroma = chroma.scale([rangeLabels[interval].color, chroma(rangeLabels[interval].color).saturate(0.3).hex()])
    colorFinal = scaleChroma((value - rangeLabels[interval].start) / (rangeLabels[interval].final - rangeLabels[interval].start)).hex()
  } else if (interval == 0) {
    var scaleChroma = chroma.scale([rangeLabels[interval].color, rangeLabels[interval + 1].color])
    const minMaxValue = (value - rangeLabels[interval].start) / (rangeLabels[interval].final - rangeLabels[interval].start)
    colorFinal = scaleChroma(minMaxValue).hex()
  } else {
    var scaleChroma = chroma.scale([rangeLabels[interval].color, rangeLabels[interval + 1].color])
    const minMaxValue = (value - rangeLabels[interval].start) / (rangeLabels[interval].final - rangeLabels[interval].start)
    colorFinal = scaleChroma(minMaxValue).hex()
  }

  return colorFinal
}

export const krigingInterpolation = async (arrayPolygon, newRangeLabels, polygonField) => {
  const { polyTurfBbox, fieldArea, polyTurf } = getFieldCoodnatesAndBboxx(polygonField) as any

  var extent = polyTurfBbox as any

  var cellSide = (Math.sqrt(fieldArea) * 0.8) as any
  var options = { units: "meters" } as any
  var grid = turf.squareGrid(extent, cellSide, options)

  var t_variable = [] as any
  var x_axis = [] as any
  var y_axis = [] as any

  arrayPolygon.forEach((arrPol: any) => {
    t_variable.push(arrPol.value)
    x_axis.push(arrPol.center.geometry.coordinates[1])
    y_axis.push(arrPol.center.geometry.coordinates[0])
  })

  var model = "Exponencial"
  var sigma2 = 0,
    alpha = 100
  var variogram = kriging.train(t_variable, x_axis, y_axis, model, sigma2, alpha)

  var squareGridIntersect = [] as any

  turf.featureEach(grid, (currentFeature: any) => {
    var intersected = turf.intersect(polyTurf, currentFeature) as any
    if (intersected) {
      intersected.geometry.coordinates.map((coord, index) => {
        let newSquarePol = turf.polygon(coord.length == 1 ? coord : [coord])

        const center = turf.centerOfMass(newSquarePol)
        let value = kriging.predict(center.geometry.coordinates[0], center.geometry.coordinates[1], variogram)

        intersected["properties"]["area"] = turf.area(newSquarePol)
        intersected["properties"]["center"] = center
        intersected["properties"]["name"] = index + 1
        intersected["properties"]["value"] = value
        intersected["properties"]["color"] = getZoneColorInterpolationv(value, newRangeLabels)
        // intersected['properties']['color'] = getZoneColor(value, newRangeLabels)
        squareGridIntersect.push(intersected)
      })
    }
  })
  return squareGridIntersect
}

export const krigingInterpolationSimplify = async (arrayPolygon, newRangeLabels, polygonField) => {
  const { polyTurfBbox, fieldArea, polyTurf } = getFieldCoodnatesAndBboxx(polygonField) as any

  var extent = polyTurfBbox as any

  var cellSide = (Math.sqrt(fieldArea) * 0.8) as any
  var options = { units: "meters" } as any
  var grid = turf.squareGrid(extent, cellSide, options)

  var t_variable = [] as any
  var x_axis = [] as any
  var y_axis = [] as any

  arrayPolygon.forEach((arrPol: any) => {
    t_variable.push(arrPol.value)
    x_axis.push(arrPol.center.geometry.coordinates[1])
    y_axis.push(arrPol.center.geometry.coordinates[0])
  })

  var model = "exponential"
  var sigma2 = 0,
    alpha = 100
  var variogram = kriging.train(t_variable, x_axis, y_axis, model, sigma2, alpha)

  let squareGridIsobands = turf.featureCollection([])
  turf.featureEach(grid, (currentFeature: any) => {
    const centerOfMass = turf.centerOfMass(currentFeature)
    let value = kriging.predict(centerOfMass.geometry.coordinates[0], centerOfMass.geometry.coordinates[1], variogram)

    squareGridIsobands.features.push(turf.point([centerOfMass.geometry.coordinates[0], centerOfMass.geometry.coordinates[1]], { value: value }))
  })

  let rangeLabel2 = [] as any

  for (let i = 0; i <= newRangeLabels.length - 1; i++) {
    if (i == 0) {
      rangeLabel2.push(newRangeLabels[i].start)
      rangeLabel2.push(newRangeLabels[i].final)
    } else {
      rangeLabel2.push(newRangeLabels[i].final)
    }
  }

  // @ts-ignore
  const isobands = turf.isobands(squareGridIsobands, rangeLabel2, { zProperty: "value", breaksProperties: squareGridIsobands.features.map(sGI => sGI.properties) })

  var squareGrid = turf.featureCollection([])
  isobands.features.forEach(interval => {
    interval.geometry.coordinates.map((coord: any) => {
      const value = interval?.properties?.value.split("-")
      squareGrid.features.push(turf.polygon(coord, { value: Number.parseInt(value[1]) / Number.parseInt(value[0] == "0" ? 1 : value[0]) }))
    })
  })
  let count = 0

  var squareGridIntersect = [] as any
  if (squareGrid.features.length == 0) {
    count = count + 1

    const center = turf.centerOfMass(polyTurf)

    const sum = t_variable.reduce((a, b) => a + b, 0)
    const avg = sum / t_variable.length || 0
    polyTurf.properties = {
      color: getZoneColorInterpolationv(avg, newRangeLabels),
      area: turf.area(polyTurf),
      center: center,
      name: count,
      value: avg,
    }
    squareGridIntersect.push(polyTurf)
  } else {
    turf.featureEach(squareGrid, (currentFeature: any) => {
      var intersected = turf.intersect(polyTurf, currentFeature) as any
      if (intersected) {
        intersected.geometry.coordinates.map(coord => {
          if (intersected.geometry.type == "Polygon") {
            let newSquarePol = turf.polygon([coord])
            count = count + 1
            const center = turf.centerOfMass(newSquarePol)
            let value = kriging.predict(center.geometry.coordinates[0], center.geometry.coordinates[1], variogram)
            squareGridIntersect.push(
              turf.polygon([coord], {
                color: getZoneColorInterpolationv(value, newRangeLabels),
                area: turf.area(newSquarePol),
                center: center,
                name: count,
                value: value,
              })
            )
          } else {
            coord.map(c => {
              count = count + 1
              let newSquarePol = turf.polygon([c])
              const center = turf.centerOfMass(newSquarePol)
              let value = kriging.predict(center.geometry.coordinates[0], center.geometry.coordinates[1], variogram)

              newSquarePol.properties = {
                color: getZoneColorInterpolationv(value, newRangeLabels),
                area: turf.area(newSquarePol),
                center: center,
                name: count,
                value: value,
              }

              squareGridIntersect.push(newSquarePol)
            })
          }
        })
      }
    })
  }

  return squareGridIntersect
}

export const idwInterpolation = async (arrayPolygon, newRangeLabels, polygonField, shape = "hex") => {
  const { polyTurfBbox, fieldArea, polyTurf } = getFieldCoodnatesAndBboxx(polygonField) as any

  var pointsInter = turf.featureCollection([]) as any

  arrayPolygon.forEach(element => {
    pointsInter.features.push(turf.point([element.center.geometry.coordinates[1], element.center.geometry.coordinates[0]], { id: element.name, value: element.value }))
  })

  const [maxY, maxX, minY, minX] = polyTurfBbox
  pointsInter.features.push(turf.point([maxY, maxX], { id: "-1", value: 0 }))
  pointsInter.features.push(turf.point([minY, minX], { id: "-2", value: 0 }))

  var options = { gridType: shape, property: "value", units: "meters", weight: 2 } as any
  var cellSide = (Math.sqrt(fieldArea) * 0.8) as any

  var grid = turf.interpolate(pointsInter, cellSide, options)
  var squareGridIntersect = [] as any

  await Promise.all(
    grid.features.map(async (currentFeature: any) => {
      var intersected = turf.intersect(polyTurf, currentFeature) as any
      if (intersected) {
        intersected.properties["value"] = currentFeature.properties["value"]
        squareGridIntersect.push(intersected)
      }
    })
  )

  let newSquareGridIntersect = squareGridIntersect.map((sqt, index) => {
    const value = sqt["properties"]?.value
    return {
      ...sqt,
      properties: {
        value: value,
        color: getZoneColorInterpolationv(value, newRangeLabels),
        name: index + 1,
        // color: getZoneColor(value, newRangeLabels),
      },
    }
  }) as any

  return newSquareGridIntersect
}

export const getZoneColor = (value, rangeLabels) => {
  let color = "#000000"
  if (value != null) {
    for (let i = 0; i < rangeLabels.length; i++) {
      if (rangeLabels[i].start <= value) {
        color = rangeLabels[i].color
      }
    }
  }

  return color
}

export const groupByCategory = (listArray, fieldName) => {
  return listArray.reduce((group, element) => {
    group[element[fieldName]] = group[element[fieldName]] ?? []
    group[element[fieldName]].push(element)
    return group
  }, {})
}
