import { Breadcrumbs, Grid, Button, FormControl, OutlinedInput, InputAdornment, IconButton, InputLabel, Typography } from "@mui/material"
import CircularProg from "../../../shared/components/CircularProgrees/Circular"
import LayoutPadrao from '@crema/core/AppLayout/LayoutPadrao'
import jwtAxios from "@crema/services/auth/jwt-auth"
import SearchIcon from '@mui/icons-material/Search'
import Card from 'shared/components/cards/Card'
import { useNavigate } from "react-router-dom"
import VerticalNav from "../VerticalNavItem"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import Link from '@mui/material/Link'


const ClientProperties = () => {
  let { id } = useParams()
  const navigate = useNavigate()

  const [filterProperties, setFilterProperties] = useState([]) as any
  const [loadingCustomer, setLoadingCustomer] = useState(false)
  const [properties, setProperties] = useState([]) as any
  const [customer, setCustomer] = useState() as any
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getProperties(id)
    getClient(id)
  }, [])

  const getClient = async (id) => {
    try {
      setLoadingCustomer(true)
      let { data } = await jwtAxios.get(`clients/${id}`)
      setCustomer(data)

    } catch (e) {
      console.error(e)

    } finally {
      setLoadingCustomer(false)
    }
  }

  const getProperties = async (id) => {
    try {
      setLoading(true)
      let { data: { properties } } = await jwtAxios.get(`clients/${id}/properties`)
      setProperties(properties)
      setFilterProperties(properties)

    } catch (e) {
      console.error(e)

    } finally {
      setLoading(false)
    }
  }

  const propertiesFilter = async (query) => {
    const filterProperties = properties.filter( prop => prop.name.toLowerCase().includes(query.toLowerCase()))
    setFilterProperties(filterProperties)
  }
  
  const _renderVerticalNav = () => VerticalNav(customer.name)
  return (
    <>
      {
        !loadingCustomer && customer? <>
          <LayoutPadrao verticalNav={_renderVerticalNav()}>
            <Breadcrumbs maxItems={2} aria-label="breadcrumb">
              <Link style={{ textDecoration: 'none', color: "black" }} href="/clientes">
                Clientes
              </Link>
              <Link href={`/clientes/${id}/fazendas`} style={{ textDecoration: 'none', color: "black" }}>
                { customer.name }
              </Link>
            </Breadcrumbs>

            <Typography sx={{
              mb: 5,
              mt: 5,
            }} variant="h1" component="h2">
              Fazendas
            </Typography>

            <Grid sx={{
              display: "flex",
              justifyContent: "end",
              mb: 5,
              alignItems: "center",
            }}>
              <Button onClick={() => navigate(`/cliente/${id}/fazenda`)} variant="contained">Nova Fazenda</Button>
            </Grid>

            <FormControl fullWidth sx={{ m: 1, mb: 10 }} >
              <InputLabel htmlFor="outlined-adornment-amount">  
                Buscar
              </InputLabel>
              <OutlinedInput
                sx={{ backgroundColor: "#ffffff" }}
                id="outlined-adornment-amount"
                onChange={ async (e) => {propertiesFilter(e.target.value)}}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Amount"
              />
            </FormControl>
            {
              !loading ? <>
                <Grid container spacing={{ xs: 4, md: 8 }}>
                  {
                    filterProperties.map((prop => {
                      return <>
                          <Grid item xs={12} sm={6}>
                            <Card
                              data={{
                                name: prop.name,
                                avatarInitials: prop.name.split(' '),
                                cardId:prop.id,
                                navigate: navigate,
                                edtURL: `/cliente/${id}/fazenda/${prop.uuid}`,
                                viewURL: `/fazenda/${prop.uuid}`
                              }}
                            ></Card>
                        </Grid>
                      </>
                    }))
                  }
                </Grid>
              </>
              :
              <>
                <CircularProg />
              </>
            }
          </LayoutPadrao>
        </> 
      :
        <>
          <CircularProg />
        </>
      }
    </>
  )
}

export default ClientProperties
