
import { onlyNumbers } from "@crema/utility/Utils"
import { Grid, TextField, FormControlLabel, Checkbox } from "@mui/material"
import messages from "../../../@crema/services/db/messages"
import IntlMessages from "../../../@crema/utility/IntlMessages"
import { THEME_APP_TEXTDIELD, THEME_CHECK_BOX } from "../../themes/themeConst"

const ApplicationFormConfig = ({ data }) => {
  const {
    formSetFieldValues,
    formValues,
  } = data

  return (
    <>
      <Grid item xs={4} padding='10px'>
        <TextField
          placeholder={messages["recommendations.additional"] as string}
          label={<IntlMessages id="recommendations.additional" />}
          value={formValues.additionalRecommendation}
          onChange={(e) => {
            if (e.target.value == '-') formSetFieldValues('additionalRecommendation', null)
            const number = Number.parseFloat(e.target.value)
            if (number > 0) formSetFieldValues('additionalRecommendation', number)
            else formSetFieldValues('additionalRecommendation', null)
          }}
          inputProps={{ min: 0 }}
          InputLabelProps={{ shrink: true }}
          name="additionalRecommendation"
          disabled={formValues.disable}
          sx={THEME_APP_TEXTDIELD}
          autoComplete="false"
          variant="outlined"
          type="number"
          required
        />
      </Grid>

      <Grid item xs={4} padding='10px'>
        <TextField
          placeholder={messages["recommendations.maintenance"] as string}
          label={<IntlMessages id="recommendations.maintenance" />}
          value={formValues.maintenance}
          inputProps={{ min: 0 }}
          onChange={(e) => {
            if (e.target.value == '-') formSetFieldValues('additionalRecommendation', null)
            const number = Number.parseFloat(e.target.value)
            if (number > 0) formSetFieldValues('maintenance', number)
            else formSetFieldValues('maintenance', null)
          }}
          InputLabelProps={{ shrink: true }}
          sx={THEME_APP_TEXTDIELD}
          autoComplete="false"
          disabled={formValues.disable}
          name="maintenance"
          variant="outlined"
          type="number"
        />
      </Grid>

      <Grid item xs={3} padding='10px'>
        <TextField
          placeholder={messages["recommendations.disposalLimit"] as string}
          label={<IntlMessages id="recommendations.disposalLimit" />}
          value={formValues.disposalLimit}
          onChange={(e) => {
            if (e.target.value == '-') formSetFieldValues('additionalRecommendation', null)
            const number = Number.parseFloat(e.target.value)
            if (number > 0) formSetFieldValues('disposalLimit', number)
            else formSetFieldValues('disposalLimit', null)
          }}
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: 0 }}
          sx={THEME_APP_TEXTDIELD}
          autoComplete="false"
          disabled={formValues.disable}
          name="disposalLimit"
          variant="outlined"
          type="number"
        />
      </Grid>

      <Grid item xs={3} padding='10px'>
        <TextField
          placeholder={messages["recommendations.minimumLimit"] as string}
          label={<IntlMessages id="recommendations.minimumLimit" />}
          value={formValues.minimumLimit}
          onChange={(e) => {
            if (e.target.value == '-') formSetFieldValues('minimumLimit', null)
            const number = Number.parseFloat(e.target.value)
            if (number > 0) formSetFieldValues('minimumLimit', number)
            else formSetFieldValues('minimumLimit', null)
          }}
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: 0 }}
          disabled={formValues.disable}
          sx={THEME_APP_TEXTDIELD}
          autoComplete="false"
          name="minimumLimit"
          variant="outlined"
          type="number"
        />
      </Grid>

      <Grid item xs={3} padding='10px'>
        <TextField
          placeholder={messages["recommendations.maximumLimit"] as string}
          label={<IntlMessages id="recommendations.maximumLimit" />}

          InputLabelProps={{ shrink: true }}
          inputProps={{ min: 0 }}
          sx={THEME_APP_TEXTDIELD}
          value={formValues.maximumLimit}
          onChange={(e) => {
            if (e.target.value == '-') formSetFieldValues('maximumLimit', null)
            const number = Number.parseFloat(e.target.value)
            if (number > 0) formSetFieldValues('maximumLimit', number)
            else formSetFieldValues('maximumLimit', null)
          }}
          autoComplete="false"
          disabled={formValues.disable}
          name="maximumLimit"
          variant="outlined"
          type="number"
        />
      </Grid>

      <Grid item xs={3} padding='10px'>
        <TextField
          placeholder={messages["recommendations.round"] as string}
          label={<IntlMessages id="recommendations.round" />}
          value={formValues.round}
          InputLabelProps={{ shrink: true }}
          disabled={formValues.disable}
          inputProps={{ min: 0 }}
          onChange={(e) => {
            const number = Number.parseInt(onlyNumbers(e.target.value))
            
            if (number > 0) formSetFieldValues('round', number)
            else formSetFieldValues('round', 0)
          }}
          sx={THEME_APP_TEXTDIELD}
          autoComplete="false"
          name="round"
          variant="outlined"
          type="number"
        />
      </Grid>

      <Grid item xs={12} padding='0px 8px' sx={THEME_CHECK_BOX}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={formValues.disable}
              onChange={() => formSetFieldValues('considerSurplus', !formValues.considerSurplus)}
              checked={formValues.considerSurplus}
              name="considerSurplus?"
            />
          }
          label="Considerar Excedente"
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={formValues.disable}
              checked={formValues.zeroBelowMinimum}
              onChange={() => formSetFieldValues('zeroBelowMinimum', !formValues.zeroBelowMinimum)} name="zeroBelowMinimum"
            />
          }
          label="Zerar Abaixo Mínimo?"
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={formValues.disable}
              checked={formValues.average}
              onChange={() => formSetFieldValues('average', !formValues.average)} name="average"
            />
          }
          label="Aplicar Média"
        />

      </Grid >
    </>
  )
}

export default ApplicationFormConfig;