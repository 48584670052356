export const SEARCH_CITY = "SEARCH_CITY";
export const LOADING_CITY = "LOADING_CITY";

export interface SearchCityAction {
  type: typeof SEARCH_CITY;
  payload: object
}

export interface LoadingCityAction {
  type: typeof LOADING_CITY;
  payload: boolean
}

export type CitiesActionTypes =
  | SearchCityAction
  | LoadingCityAction
