import { Dialog, DialogTitle, Typography, Grid, Box, Tabs, Tab, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { useEffect, useState } from "react"
import Graph from "./graph"

const PlotGraph = ({ data }) => {
  const {
    modalPlot,
    setModalPlot,
    listElement,
    worksResult,
  } = data

  const filterElments = {
    macro:['H20', 'CaCI', 'MO', 'P', 'K', 'KMG', 'Al', 'H+Al', 'S'],
    micro:['B', 'Cu', 'Fe', 'Mn', 'Zn'],
    fisica:['SatArg', 'SatAreia', 'SatSilte'],
    camposCalculados: ['CTC(T)', 'SatBases', 'SatK', 'SatMg', 'SatCa'],
    all: []
  }
  const [workSelected, setWorkSelected] = useState('')
  const [workPosition, setWorkPosition] = useState(0)
  const [filter, setFilter] = useState('all')

  useEffect(() => {
    setWorkSelected(worksResult[workPosition])
  }, [])

  return <>
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      open={modalPlot}
      onClose={() => {
        setFilter('all')
        setModalPlot(false)
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography variant="h1" component="h2">
          {"Resultados"}
        </Typography>
      </DialogTitle>
      
      <Grid container justifyContent={'flex-end'}>
        <Grid item xs={2} sx={{mr: 5}}>
          <FormControl fullWidth >
            <InputLabel variant="outlined" sx={{backgroundColor: 'white', padding: '1%'}}> Filtrar </InputLabel>

            <Select value={filter} onChange={(e) => setFilter(e.target.value)} size="small" >
              <MenuItem value={'all'}>Todos</MenuItem>
              <MenuItem value={'macro'}>Macro</MenuItem>
              <MenuItem value={'micro'}>Micro</MenuItem>
              <MenuItem value={'fisica'}>Física</MenuItem>
              <MenuItem value={'camposCalculados'}>Campos Calculados</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      
      <Box sx={{p: 2, pt:0, mt: 5}}>
        <Grid container>
          <Grid item xs={2} sx={{height: 650}}>
            <Grid item xs={12} sx={{paddingTop: 2}}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={workPosition}
                textColor='primary'
                onChange={(_, idValue: number) => {
                  setWorkSelected(worksResult[idValue])
                  setWorkPosition(idValue)
                }}
                sx={
                  { borderColor: 'divider', width: '100%', paddingRight: 2 }}
              >
                {
                  worksResult.map((wR, index) => <Tab sx={{ backgroundColor: workPosition == index ? '#e5f2e6' : 'transparent' }} label={`${wR.workId} - ${wR.fieldName}`} id={wR.workUUID} />)
                }
              </Tabs>
            </Grid>

          </Grid>

          <Grid item xs={10}> 
            <Grid container justifyContent="flex-start" xs={12} > 
              {
                workSelected && <>
                  {
                    listElement.map( element => {
                      if(filter != 'all' && !filterElments[filter].find(filEl => filEl == element.key)) return 
                      return <Grid item xs={6} height={300} sx={{padding: 1}}>
                        <Box sx={{height: '100%'}}>
                          <Graph data={{element, workSelected}}/>
                        </Box>
                      </Grid>
                    })
                  }            
                </>
              }
            </Grid>
            <Grid item> 
                
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  </>
}
export default PlotGraph