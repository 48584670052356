export interface LanguageProps {
  languageId: string;
  locale: string;
  name: string;
}

const languageData: LanguageProps[] = [
  {
    languageId: "english",
    locale: "br",
    name: "Português",
  },
  
];
export default languageData;
