import { Fonts } from "../constants/AppEnums"
import { grey } from "@mui/material/colors"

export const THEME_H4_APLICATION = { float: 'right', fontWeight: 'bolder', color: '#42454d' } as any

export const THEME_TABLE_ROW = {
  color: `${grey[500]} !important`,
  "& .css-1uz2f5v-MuiTableCell-root": {
    fontSize: '14px !important',
    fontWeight: '500 !important',
  },

  "& .css-lql9lw": {
    fontSize: '14px !important',
    fontWeight: '500 !important',
  },
  "& .css-1d0yjk8-MuiTableCell-root": {
    fontSize: '14px !important',
    fontWeight: `${Fonts.BOLD} !important`,
  },
  "& .tableCell": {
    fontSize: '14px !important',
    padding: '2px !important',
    fontWeight: `${Fonts.BOLD} !important`,
    "&:first-of-type": {
      pl: '5px !important',
    },
    "&:last-of-type": {
      pr: '5px !important',
    },

  },
  "& .css-n4jurv": {
    fontSize: '14px !important',
    padding: '2px !important',
  }
}

export const THEME_MODAL = {
  position: 'absolute' as 'absolute',
  top: '50% !important',
  left: '50% !important',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px #000',
  boxShadow: '24px !important',
  p: '5px !important',
}

export const THEME_APP_TEXTDIELD = {
  "& .MuiInputLabel-root": {
    // backgroundColor: 'red !important',
    fontSize: '16px !important',
    color: 'black',
  },
  width: "100%",
  "& .css-1app0lg-MuiFormLabel-root-MuiInputLabel-root": {
    color: 'rgb(17, 24, 39) !important',
    fontWeight: '700 !important',
  },
  "& .MuiInputBase-input": {
    fontSize: '16px !important',
    fontWeight: '700 !important',
    padding: '11px 10px !important',
  },
}


export const THEME_CHECK_BOX = {
  "& .css-1pm9ezf-MuiFormControlLabel-root .MuiFormControlLabel-label": {
    fontSize: '16px !important',
    fontWeight: '500 !important',
  },

  "& .css-yctf8o": {
    fontSize: '16px !important',
    fontWeight: '500 !important',
  }
}

export const THEME_INPUT_FORM = {
  width: "100%",
  "& .MuiInputLabel-root": {
    fontSize: 14,
    fontWeight: 1000
  },
  "& .MuiInputBase-input": {
    fontSize: 16,
    fontWeight: 500
  },
}

export const THEME_INPUT_LIST_TEXT_PRIMARY= {
  "& .MuiListItemText-primary": {
    fontSize: 14,
    fontWeight: 500
  }
}