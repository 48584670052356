import { Dialog, DialogContent } from "@mui/material"
import NewWorkV2 from "../newWorkV2"


const DialogNewWork = ({ data }) => {
  const {
    modalNewWork,
  } = data

  return <>
    <Dialog
      fullScreen
      fullWidth={true}
      open={modalNewWork}
    >
      <DialogContent sx={{ padding: 0, height: '100vh' }}>
        <NewWorkV2 data={{...data, newWorkPage: true}} />
      </DialogContent>

    </Dialog>
  </>
}
export default DialogNewWork