import { Alert, Box, Button, IconButton, TableBody, TablePagination } from "@mui/material"
import LayoutPadrao from "../../../@crema/core/AppLayout/LayoutPadrao"
import { useNavigate, useSearchParams } from "react-router-dom"
import AppsHeader from "@crema/core/AppsContainer/AppsHeader"
import { Fonts } from "../../../shared/constants/AppEnums"
import { useDispatch } from "react-redux"
import { useQuery } from "@crema/utility/helper/Utils"
import AppsContainer from "@crema/core/AppsContainer"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import { AppSearchBar } from "../../../@crema"
import TableRow from "@mui/material/TableRow"
import { grey } from "@mui/material/colors"
import { useEffect, useState } from "react"

import Table from "@mui/material/Table"
import { useIntl } from "react-intl"
import FormModal from "./modal/create"
import jwtAxios from "../../../@crema/services/auth/jwt-auth"
import TableSke from "./table/tableSke"
import TableItem from "./table/tableItem"
import { fetchError, fetchSuccess, showMessage } from "../../../redux/actions"
import AlertDelete from "../../utils/AlertDelete"

const moment = require('moment')

const LaboratoriesList = () => {
  const { messages } = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  let query = useQuery()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)


  const basicInitialValues = {
    uuid: null,
    name: '',
    startDate: moment().format('L'),
    finalDate: moment().add(1, 'y').format('L'),
  }
  const [initialValues, setInitialValues] = useState(basicInitialValues)

  const [harvest, setHarvest] = useState([]) as any
  const [loadingDelete, setLoadingDelete] = useState(false) as any
  const [harvestDelete, setHarvestDelete] = useState(null) as any

  const handleCloseDeleteModal = async () => {
    setDeleteModal(false)
  }

  const handleOpenDeleteModal = async () => {
    setDeleteModal(true)
  }


  const handleClickOpen = async () => {
    setOpenModal(true)
  }

  const handleClose = async () => {
    setOpenModal(false)
  }

  useEffect(() => {
    listHarvest()
  }, [query])

  const listHarvest = async () => {
    try {
      setLoading(true)
      let { data } = await jwtAxios.get('/harvest', {
        params: {
          page: query.get("page") ? query.get("page") : 1,
          query: query.get("q") ? query.get("q") : '',
        },
      })
      setCount(data.count)
      setHarvest(
        data.rows.map(harv => {
          const startDate = harv['startDate'].split('-')
          const finalDate = harv['finalDate'] ? harv['finalDate'].split('-') : null
          return {
            ...harv,
            startDate: `${startDate[2]}/${startDate[1]}/${startDate[0]}`,
            finalDate: finalDate ? `${finalDate[2]}/${finalDate[1]}/${finalDate[0]}` : ''
          }
        })
      )

    } catch (error) {
      console.error(error)
      dispatch(fetchError('Erro ao buscar safras'))
    } finally {
      setLoading(false)
    }
  }

  const deleteHarvest = async () => {
    try {
      setLoadingDelete(true)
      let { data } = await jwtAxios.delete(`/harvest/${harvestDelete['uuid']}`)
      setHarvest(
        harvest.filter(harv => harv['uuid'] != harvestDelete['uuid'])
      )
      setHarvestDelete(null)
      dispatch(fetchSuccess())
      dispatch(showMessage(`Safra Excluida`))
      handleCloseDeleteModal()

    } catch (error) {
      console.error(error)
      dispatch(fetchError('Erro ao deletar safra'))
    } finally {
      setLoadingDelete(false)
    }
  }

  const skeletonArray = Array(harvest.length ? harvest.length : 5).fill("")

  return (
    <LayoutPadrao>
      <AlertDelete data={{
        open: deleteModal,
        handleClose: handleCloseDeleteModal,
        textMessage: `Excluir a safra ${harvestDelete ? harvestDelete['name'] : ''} removerá permanentemente todos os seus dados. Tem certeza?`,
        textTitle: 'Confirme a Exclusão da Safra para continuar',
        loading: loadingDelete,
        submitForm: deleteHarvest
      }} />
      <FormModal data={{ handleClose, openModal, listHarvest, initialValues, deleteHarvest }} />
      <Box>
        <AppsContainer title={messages["harvest.title"]} fullView >
          <Box flex={1} sx={{ padding: '15px' }}>
            <AppsHeader>
              <Box
                justifyContent="space-between"
                flexDirection="row"
                alignItems="center"
                display="flex"
                width={1}
              >
                <AppSearchBar
                  placeholder={messages["common.searchHere"] as string}
                  iconPosition="right"
                  overlap={false}
                  onChange={async (e) => {
                    setSearchParams({
                      page: "1",
                      q: e.target.value,
                    })
                  }}
                />

                <Box display="flex" flexDirection="row" alignItems="center">
                  <Button
                    onClick={() => {
                      setInitialValues(basicInitialValues)
                      handleClickOpen()
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    {messages["harvest.new"]}
                  </Button>
                </Box>

              </Box>
            </AppsHeader>
            <Table className="table" sx={{ padding: '10px' }}>
              <TableHead>
                <TableRow
                  sx={{
                    color: grey[500],
                    "& .tableCell": {
                      fontSize: 13,
                      padding: 2,
                      fontWeight: Fonts.BOLD,
                      "&:first-of-type": {
                        pl: 5,
                      },
                      "&:last-of-type": {
                        pr: 5,
                      },
                    },
                  }}
                >
                  <TableCell width={'50%'} className="tableCell">
                    {messages["common.name"]}
                  </TableCell>

                  <TableCell width={'20%'} align="left" className="tableCell">
                    {messages["common.startDate"]}
                  </TableCell>

                  <TableCell width={'20%'} align="left" className="tableCell">
                    {messages["common.finalDate"]}
                  </TableCell>

                  <TableCell width={'10%'} align="left" className="tableCell">
                    {messages["common.option"]}
                  </TableCell>

                </TableRow>
              </TableHead>

              <TableBody>
                {loading
                  ? skeletonArray.map((item, index) => (
                    <TableSke data={{ id: index, numColun: 5 }} />
                  ))
                  : harvest.map((data) => <TableItem data={{ harvest: data, setHarvestDelete, handleOpenDeleteModal, setInitialValues, handleClickOpen }} />)}
              </TableBody>
            </Table>

            <TablePagination
              onPageChange={(_, page) => { setSearchParams({ page: (page + 1).toString() }) }}
              page={parseInt(query.get("page")) - 1 || 0}
              rowsPerPageOptions={[]}
              rowsPerPage={10}
              component="div"
              count={count}
            />
          </Box>
        </AppsContainer>
      </Box>
    </LayoutPadrao>
  )
}

export default LaboratoriesList
