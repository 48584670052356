import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { ARCHIVER_COMPRESSION_SUPPORTED, areaToLocaleString, convertToGeojson } from "../../../shared/utils/utils"
import { Circle, GoogleMap, InfoWindow, Polygon, useJsApiLoader } from "@react-google-maps/api"
import ColorGenerator from "random-color-array-generator/ColorGenerator.min.js"
import { fetchError, fetchSuccess, showMessage } from "../../../redux/actions"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import UploadModern from "../../../@crema/core/AppUpload/UploadModern"
import IntlMessages from "../../../@crema/utility/IntlMessages"
import jwtAxios from "../../../@crema/services/auth/jwt-auth"
import RotateRightIcon from "@mui/icons-material/RotateRight"
import FileRow from "../../../@crema/core/AppUpload/FileRow"
import RotateLeftIcon from "@mui/icons-material/RotateLeft"
import { useCallback, useEffect, useState } from "react"
import { FieldArray, Form, Formik } from "formik"
import { useDropzone } from "react-dropzone"
import { AppList } from "../../../@crema"
import { useDispatch } from "react-redux"
import { LoadingButton } from "@mui/lab"
import { useIntl } from "react-intl"
import * as turf from "@turf/turf"
import { element } from "prop-types"
import AlertDelete from "../../utils/AlertDelete"
import { useNavigate } from "react-router-dom"

const shp = require("shpjs")

const NewWork = ({ data }) => {
  const navigate = useNavigate()
  const {
    laboratory = [],
    edtDisable,
    getProperties,
    newWorkPage = false,
    setSelectedTab,
    technicalTeam = [],
    listPropertiesFields,
    updateWorks,
    orderService,
    selectedWork,
    setModalNewWork,
    initialValuesNewWork,
    setInitialValuesNewWork,
  } = data

  function getRandomUniqueNumbers(min, max, n) {
    const result = [] as any
    while (result.length < n) {
      const randomNum = Math.floor(Math.random() * (max - min + 1)) + min
      if (!result.includes(randomNum)) {
        result.push(randomNum)
      }
    }

    return result
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC-5csGO5FhX4qZkTcSgj2rlJc7T4rV1B0",
  })

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])
  const [centerMap, setCenterMap] = useState([-23.7779078, -49.9608293])
  const [selectedPlace, setSelectedPlace] = useState(null) as any
  const [selectedPoint, setSelectedPoint] = useState(null) as any
  const [arrayPoint, setArrayPoint] = useState([]) as any
  const [showNewDepth, setShowNewDepth] = useState(true)
  const [loadingWork, setLoadingWork] = useState(true)
  const [loadingDeleteWork, setLoadingDeleteWork] = useState(false)

  const [processWorkFinish, setProcessWork] = useState(false)
  const [labAnalysis, setLabAnalysis] = useState([]) as any
  const [MapBounds, setMapBounds] = useState(null) as any
  const [workUUID, setWorkUUID] = useState("") as any
  const [workTypes, setWorkTypes] = useState([]) as any
  const [polygons, setPolygons] = useState([]) as any
  const [osStatus, setOsStatus] = useState("create")
  const [mapType, setMapType] = useState("roadmap")
  const [arrayColor, setArrayColor] = useState([])
  const [infoOpen, setInfoOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState("")
  const [map, setMap] = useState(null) as any
  const [zoomMap, setZoomMap] = useState(5)
  const [uploadedFiles] = useState([])
  const { messages } = useIntl()
  const dispatch = useDispatch()
  const dropzone = useDropzone()

  const [subGridsCoodnates, setSubGridsCoodnates] = useState(null) as any

  const [polygonField, setPolygonField] = useState([]) as any
  const [valueRotation, setValueRotation] = useState(5)
  const [gridSquare, setGridSquare] = useState(null) as any

  const [visibleSquare, setVisibleSquare] = useState(true)

  const [modalDeleteWork, setModalDeleteWork] = useState(false)

  const [gridBbox, setGriBbox] = useState(null) as any
  const [valueTechnique, setValueTechnique] = useState(1)
  const [rectangleCoordinates, setrectangleCoordinates] = useState([]) as any

  const initialConfig = {
    squareArea: 4,
    gridAngle: 0,
    gridAngleTotal: 0,
    subsampleQuantity: 1,
    squareAreaUpdate: true,
    samplingType: "gridPerCell",
    sampleRadius: 30,
    polygonSelected: null as any,
    proccess: true,
    resetGrid: true,
  }

  const [configGrid, setConfigGrid] = useState(initialConfig)

  useEffect(() => {
    if (newWorkPage && configGrid.polygonSelected && configGrid.proccess && map && gridBbox.length) {
      const squareSize = configGrid.squareArea == 0 ? 1 : Math.round(Math.sqrt(configGrid.squareArea * 10000))
      var squareGridBasic
      var squareGeojson

      if (gridSquare) {
        squareGeojson = turf.featureCollection(
          gridSquare
            .getPaths()
            .getArray()
            .map(sqt => {
              const coord = sqt.getArray().map(coord => {
                return [coord.lng(), coord.lat()]
              })
              return convertToGeojson([coord], "Polygon", {})
            })
        )

        if (!configGrid.squareAreaUpdate) {
          squareGridBasic = squareGeojson
        }
      }

      if (!gridSquare || configGrid.squareAreaUpdate) {
        var options = { units: "meters" } as any
        squareGridBasic = turf.squareGrid(gridBbox, squareSize, options)

        if (squareGeojson && !configGrid.resetGrid) {
          const grid1Coordinates = turf.center(squareGeojson)
          const grid2Coordinates = turf.center(squareGridBasic)

          const distance = turf.distance(grid2Coordinates, grid1Coordinates, { units: "meters" })
          const bearing = turf.bearing(grid2Coordinates, grid1Coordinates)

          squareGridBasic = turf.transformTranslate(squareGridBasic, distance, bearing, { units: "meters" })
        }
      }

      const polyTurf = turf.polygon([configGrid.polygonSelected.coordinates.map(polyCoord => [polyCoord[1], polyCoord[0]])])

      var squareGrid = { type: "FeatureCollection", features: [] } as any
      var allPolygonCoordinates = [] as any
      var center = turf.center(squareGridBasic)

      turf.featureEach(squareGridBasic, (currentFeature: any) => {
        var rotatedGridCell = turf.transformRotate(currentFeature, !configGrid.squareAreaUpdate ? configGrid.gridAngle : configGrid.gridAngleTotal, {
          pivot: center.geometry.coordinates,
          mutate: true,
        })
        squareGrid.features.push(rotatedGridCell)
        allPolygonCoordinates.push(
          rotatedGridCell.geometry.coordinates[0].map(coord => {
            return { lat: coord[1], lng: coord[0] }
          })
        )
      })

      if (gridSquare) gridSquare.setMap(null)

      const rectangle = new google.maps.Polygon({
        draggable: true,
        paths: allPolygonCoordinates,
        strokeColor: "white",
        strokeOpacity: 1,
        visible: visibleSquare,
        zIndex: 0,
        strokeWeight: 1,
        fillColor: "white",
        fillOpacity: 0.02,
      })

      window.google.maps.event.addListener(rectangle, "dragend", () => setConfigGrid({ ...configGrid, proccess: true, gridAngle: 0, squareAreaUpdate: false }))
      rectangle.setMap(map)
      setGridSquare(rectangle)

      const squareGridIntersect = [] as any

      turf.featureEach(squareGrid, (currentFeature: any) => {
        var intersected = turf.intersect(polyTurf, currentFeature) as any
        if (intersected) {
          intersected.geometry.coordinates.map((coord, index) => {
            if (coord.length == 1) {
              var newPoligon = turf.polygon(coord) as any
              newPoligon["properties"]["area"] = turf.area(newPoligon)
              const proportionArea = newPoligon["properties"]["area"] / Math.pow(squareSize, 2)

              if (proportionArea > 0.03) {
                squareGridIntersect.push(newPoligon)
              }
            } else {
              intersected["properties"]["area"] = turf.area(turf.polygon([coord]))
              const proportionArea = intersected["properties"]["area"] / Math.pow(squareSize, 2)
              if (proportionArea > 0.03) {
                squareGridIntersect.push(intersected)
              }
            }
          })
        }
      })

      var arrayPoints = [] as any
      var arrayPointsTotal = [] as any
      var arrayPointsSubG = [] as any

      var gridGoogleMap = squareGridIntersect.map((pol, index) => {
        var polygon = turf.polygon(pol.geometry.coordinates)
        var center = turf.centerOfMass(polygon)

        if (configGrid.subsampleQuantity <= 1) {
          var point = {
            identifier: index + 1,
            coordinatesArray: {
              latCollected: null,
              lngCollected: null,
              lat: center.geometry.coordinates[1],
              lng: center.geometry.coordinates[0],
              identifier: index + 1,
              utmX: 0,
              utmY: 0,
              wasCollected: false,
            },
          }
          arrayPoints.push(point.coordinatesArray)
          arrayPointsTotal = arrayPointsTotal.concat(point)
        } else if (configGrid.samplingType == "gridPerCell") {
          var bboxPolygonGrid = turf.bbox(pol)
          const qtd = Math.ceil(Math.pow(configGrid.subsampleQuantity, 0.5))
          // const qtd = configGrid.subsampleQuantity

          var cellSide = Math.trunc(Math.sqrt(pol.properties.area) / qtd)
          // var cellSide = Math.trunc(Math.sqrt(configGrid.squareArea * 10000) / qtd)

          var squareGridBasic2 = turf.squareGrid(bboxPolygonGrid, cellSide * 0.99, { units: "meters", mask: pol })
          // var squareGridBasic2 = turf.squareGrid(bboxPolygonGrid, cellSide * .90, { units: 'meters', mask: pol })

          if (squareGridBasic2.features.length) {
            var squareGrid2 = { type: "FeatureCollection", features: [] } as any
            // var center = turf.center(squareGridBasic2)

            turf.featureEach(squareGridBasic2, (currentFeature: any) => {
              var rotatedGridCell = turf.transformRotate(currentFeature, configGrid.gridAngleTotal, { pivot: center.geometry.coordinates, mutate: true })
              squareGrid2.features.push(rotatedGridCell)
            })

            var points = [] as any
            const squareGridIntersect2 = [] as any

            turf.featureEach(squareGrid2, (currentFeature: any) => {
              var intersected2 = turf.intersect(pol, currentFeature) as any
              if (intersected2) {
                intersected2.geometry.coordinates.map((coord, index) => {
                  if (coord.length == 1) {
                    var newPoligon = turf.polygon(coord) as any
                    newPoligon["properties"]["area"] = turf.area(newPoligon)
                    const proportionArea = newPoligon["properties"]["area"] / Math.pow(squareSize, 2)

                    if (proportionArea > 0.01) {
                      squareGridIntersect2.push(newPoligon)
                    }
                  } else {
                    intersected2["properties"]["area"] = turf.area(turf.polygon([coord]))
                    const proportionArea = intersected2["properties"]["area"] / Math.pow(squareSize, 2)
                    if (proportionArea > 0.01) {
                      squareGridIntersect2.push(intersected2)
                    }
                  }
                })
              }
            })

            arrayPointsSubG.push(squareGridIntersect2)

            if (squareGridIntersect2.length <= configGrid.subsampleQuantity) {
              squareGridIntersect2.forEach(sqrt => {
                points = points.concat(turf.center(sqrt))
              })
            } else {
              const randonNum = getRandomUniqueNumbers(0, squareGridIntersect2.length - 1, configGrid.subsampleQuantity)
              randonNum.forEach(numPos => {
                points = points.concat(turf.center(squareGridIntersect2[numPos]))
              })
            }

            const pointsGoogle = points.map((pts, index2) => {
              return {
                identifier: arrayPoints.length + index2 + 1,
                coordinatesArray: {
                  latCollected: null,
                  lngCollected: null,
                  lat: pts.geometry.coordinates[1],
                  lng: pts.geometry.coordinates[0],
                  identifier: arrayPoints.length + index2 + 1,
                  utmX: 0,
                  utmY: 0,
                  wasCollected: false,
                },
              }
            })
            arrayPoints = arrayPoints.concat(pointsGoogle)
            arrayPointsTotal = arrayPointsTotal.concat(pointsGoogle)
          }
        } else if (configGrid.samplingType == "pointGrid") {
          const numPoints = configGrid.subsampleQuantity

          const center = turf.centerOfMass(pol)
          const centro_x = center.geometry.coordinates[0]
          const centro_y = center.geometry.coordinates[1]

          const raioMetros = configGrid.sampleRadius
          const grausPorMetroLatitude = 1 / 111000
          const raio = raioMetros * grausPorMetroLatitude

          const pontosNoPerimetro = [] as any

          for (let i = 0; i < numPoints; i++) {
            const angulo = (Math.PI * 2 * i) / numPoints
            const x = centro_x + raio * Math.cos(angulo)
            const y = centro_y + raio * Math.sin(angulo)
            pontosNoPerimetro.push([x, y])
          }

          const pointsGoogle = pontosNoPerimetro.map((pts, index2) => {
            return {
              identifier: arrayPoints.length + index2 + 1,
              coordinatesArray: {
                latCollected: null,
                lngCollected: null,
                lat: pts[1],
                lng: pts[0],
                identifier: arrayPoints.length + index2 + 1,
                utmX: 0,
                utmY: 0,
                wasCollected: false,
              },
            }
          })
          arrayPoints = arrayPoints.concat(pointsGoogle)
          arrayPointsTotal = arrayPointsTotal.concat(pointsGoogle)
        }

        const newZone = {
          zoneId: index + 1,
          name: index + 1,
          center: center,
          area: areaToLocaleString(Math.round(pol["properties"]["area"] / 10000), 1, 1),
          id: index + 1,
          pointsCollection: arrayPoints,
          coordinatesArray: pol.geometry.coordinates[0].map(coordinates => [coordinates[1], coordinates[0]]),
          technicalTeamId: pol.technicalTeamId ? pol.technicalTeam.uuid : "",
          coordinates: pol.geometry.coordinates[0].map(coordinates => {
            return {
              lat: coordinates[1],
              lng: coordinates[0],
            }
          }),
        }

        arrayPoints = []

        return newZone
      })

      var test = [] as any
      arrayPointsSubG.map(x => {
        return x.map(y => {
          const pp = y.geometry.coordinates[0].map(z => {
            return { lat: z[1], lng: z[0] }
          })

          test.push(pp)

          return pp
        })
      })

      if (subGridsCoodnates) subGridsCoodnates.setMap(null)

      const rectangle2 = new google.maps.Polygon({
        draggable: true,
        paths: test,
        strokeColor: "blue",
        strokeOpacity: 1,
        visible: true,
        zIndex: 5,
        strokeWeight: 1,
        fillColor: "white",
        fillOpacity: 0.02,
      })

      rectangle2.setMap(map)
      setSubGridsCoodnates(rectangle2)

      setSubGridsCoodnates(arrayPointsSubG)

      if (valueTechnique) {
        setArrayPoint(arrayPointsTotal)
        setPolygons(gridGoogleMap)
      }

      setConfigGrid({ ...configGrid, squareAreaUpdate: false, gridAngle: 0, proccess: false, resetGrid: false })
    }
  }, [configGrid, map, gridBbox])

  const handleChange = async (event: React.SyntheticEvent, newValue: number) => {
    setInfoOpen(false)
    setSelectedPoint(null)

    if (gridSquare) gridSquare.setMap(null)
    setValueTechnique(newValue)
    setSelectedPoint(null)
    setArrayPoint([])
    setPolygons([])

    if (newValue == 1) {
      setCenterMap(MapBounds)
      fildMapPlot(configGrid.polygonSelected)
      setConfigGrid({ ...initialConfig, polygonSelected: configGrid.polygonSelected })
    }
  }

  const onClickAddAnalysis = (values, setValues) => {
    const analysisDepth = [
      ...values.analysisDepth,
      { start: 0, final: 20, analysisProfileUUID: !values.analysisDepth.length ? labAnalysis[0].uuid : values.analysisDepth.slice(-1)[0].analysisProfileUUID },
    ]
    setValues({ ...values, analysisDepth })
  }

  const updateAnalysis = (values, setValues, uuid, index) => {
    let analysisDepth = [...values.analysisDepth]
    analysisDepth[index]["analysisProfileUUID"] = uuid
    setValues({ ...values, analysisDepth })
  }

  const updateAllFieldAnalysis = (values, setValues) => {
    let analysisDepth = [...values.analysisDepth]
    analysisDepth = analysisDepth.map(e => (e.analysisProfileUUID = ""))
    setValues({ ...values, analysisDepth })
  }

  const removeAnalysis = (values, setValues, index) => {
    let analysisDepth = [...values.analysisDepth]
    analysisDepth.splice(index, 1)
    setValues({ ...values, analysisDepth })
  }

  useEffect(() => {
    if (laboratory && laboratory.length && workTypes.length) {
      setInitialValuesNewWork({
        ...initialValuesNewWork,
        laboratoryUUID: laboratory[0].uuid,
        typeId: workTypes[0].id,
        typeName: workTypes[0].name,
      })
      setLabAnalysis(laboratory[0].analysis)
      setShowNewDepth(false)
    }
  }, [laboratory, workTypes])

  useEffect(() => {
    if (listPropertiesFields && listPropertiesFields.length) {
      // auxGridMAp(listPropertiesFields[0]['polygon'], true)
      fildMapPlot(listPropertiesFields[0]["polygon"])
      setConfigGrid({ ...configGrid, polygonSelected: listPropertiesFields[0]["polygon"], proccess: true })

      setInitialValuesNewWork({
        ...initialValuesNewWork,
        fildUUID: initialValuesNewWork["fildUUID"] ? initialValuesNewWork["fildUUID"] : listPropertiesFields[0].uuid,
      })
    }
  }, [listPropertiesFields])

  useEffect(() => {
    if (map && MapBounds) {
      map.fitBounds(MapBounds)
    }
  }, [map, MapBounds])

  useEffect(() => {
    try {
      if (dropzone.acceptedFiles[0]) {
        if (!ARCHIVER_COMPRESSION_SUPPORTED.find(e => e == dropzone.acceptedFiles[0]["type"])) {
          dispatch(fetchError("Extenção não suportada"))
          return
        }
        processZip(dropzone.acceptedFiles[0])
      }
    } catch {
      dispatch(fetchError("Erro ao Carregar arquivo"))
    }
  }, [dropzone.acceptedFiles])

  useEffect(() => {
    getWorkTypes()
  }, [])

  useEffect(() => {
    if (gridSquare) gridSquare.setMap(visibleSquare ? map : null)
  }, [visibleSquare, gridSquare])

  const getWorkTypes = async () => {
    try {
      let { data } = await jwtAxios.get(`/types/work-group`)
      setWorkTypes(data)
    } catch (e: any) {
      dispatch(fetchError(e.message))
    }
  }

  const processZip = async (file: any) => {
    var pointProccess = null
    var zoneProccess = null

    const reader = new FileReader()
    reader.onabort = () => dispatch(fetchError("Upload Abortado"))
    reader.onerror = () => dispatch(fetchError("Upload Error"))
    reader.onload = async () => {
      const binaryStr = reader.result
      try {
        const geojson = await shp(binaryStr)

        var polygon
        var point
        setFileName(geojson[0]["fileName"])
        setSelectedPlace(null)
        setSelectedPoint(null)
        setArrayPoint([])

        geojson.forEach(item => {
          item.features.every(FeatureCollection => {
            if (FeatureCollection.geometry.type === "Polygon") {
              polygon = item.features.map(pol => {
                zoneProccess = pol.properties.ZNM

                if (pol.properties["Área (ha)"]) {
                  pol.properties["Area"] = pol.properties["Área (ha)"]
                } else if (pol.properties["area"]) {
                  pol.properties["Area"] = pol.properties["area"]
                } else if (pol.properties["área"]) {
                  pol.properties["Area"] = pol.properties["área"]
                } else if (pol.properties["Área(ha)"]) {
                  pol.properties["Area"] = pol.properties["Área(ha)"]
                }
                if (pol.geometry.type != "Polygon") {
                  throw "INVALID_GEOMETRY_TYPE"
                }

                if (!pol.properties["Area"] || !pol.properties["ZNM"]) throw "AREA_ZONE_NOT_INFORMED"

                return {
                  ...pol,
                  center: turf.centerOfMass(turf.polygon(pol.geometry.coordinates)),
                }
              })
              return false
            } else if (FeatureCollection.geometry.type === "Point") {
              point = item.features
              return false
            } else {
              throw "ERROR"
            }
          })
        })

        if (!polygon || !point) {
          throw "INCOMPLETE_FILE"
        }

        polygon.map(poly => {
          zoneProccess = poly["properties"]["ZNM"]
          if (!poly["center"]) throw "POLYGON_NOT_CENTER"
          if (!poly["geometry"]) throw "POLYGON_NOT_GEOMETRY"
          if (!poly["geometry"]["coordinates"].length) throw "POLYGON_NOT_GEOMETRY_COODINATES"
        })

        point.map(pt => {
          if (!pt["properties"]) throw "POINT_NOT_PROPERTIES"
          if (!pt["properties"]["Pontos"]) throw "POINT_NOT_PROPERTIES"
          pointProccess = pt["properties"]["Pontos"]

          if (!pt["properties"]["UTM_X"]) throw "POINT_NOT_PROPERTIES"
          if (!pt["properties"]["UTM_Y"]) throw "POINT_NOT_PROPERTIES"
          if (!pt["geometry"]) throw "POINT_NOT_GEOMETRY"
          if (!pt["geometry"]["coordinates"].length) throw "POINT_NOT_GEOMETRY_COODINATES"
        })

        const data = await polygon.map((pol: any) => {
          return {
            name: pol.properties.ZNM,
            id: pol.properties.ZNM,
            area: pol.properties.Area,
            center: pol.center,
            technicalTeamId: "",
            pointsCollection: [],
            coordinatesArray: pol.geometry.coordinates[0].map(coordinates => [coordinates[1], coordinates[0]]),
            coordinates: pol.geometry.coordinates[0].map(coordinates => {
              return {
                lat: coordinates[1],
                lng: coordinates[0],
              }
            }),
          }
        })

        const pointObj = await point.map(element => {
          const getPointId = element.properties.Pontos ? element.properties.Pontos : element.properties.Ponto
          return {
            identifier: getPointId,
            coordinatesArray: {
              lat: element.geometry.coordinates[1],
              lng: element.geometry.coordinates[0],
              identifier: getPointId,
              utmX: element.properties.UTM_X,
              utmY: element.properties.UTM_Y,
              wasCollected: false,
            },
          }
        })

        const bounds = new google.maps.LatLngBounds()
        data.forEach(element => {
          element.coordinates.forEach(point => {
            bounds.extend(point)
          })
        })

        // @ts-ignore
        setCenterMap(bounds.getCenter().toJSON())
        setMapBounds(bounds)

        const allPoints = pointObj.map(element => Object.values(element.coordinatesArray))
        const pointTurf = turf.points(allPoints)

        data.forEach(poly => {
          const polygonTurf = turf.polygon([poly["coordinatesArray"]])
          const turfWithiIn = turf.pointsWithinPolygon(pointTurf, polygonTurf)

          poly["pointsCollection"] = turfWithiIn.features.map(point => {
            return {
              lat: point.geometry.coordinates[0],
              lng: point.geometry.coordinates[1],
              identifier: point.geometry.coordinates[2],
              utmX: point.geometry.coordinates[3],
              utmY: point.geometry.coordinates[4],
              wasCollected: false,
            }
          })
        })
        data.sort((a, b) => (a.name > b.name ? 1 : -1))
        setPolygons(data)
        setMapType("satellite")
        setArrayPoint(pointObj)
      } catch (e) {
        console.log(e)

        if ("INVALID_GEOMETRY_TYPE" == e) {
          dispatch(fetchError(`Arquivo invalido, zona não possue um tipo valido de Poligono. ${zoneProccess ? ` Ex: zone ${zoneProccess}` : ""}`))
        } else if ("POLYGON_NOT_CENTER" == e) {
          dispatch(fetchError(`Arquivo incompleto, zona não possue informações centro. ${zoneProccess ? ` Ex: zone ${zoneProccess}` : ""}`))
        } else if ("POLYGON_NOT_GEOMETRY" == e) {
          dispatch(fetchError(`Arquivo incompleto, informe junto a geometry da zona. ${zoneProccess ? ` Ex: zone ${zoneProccess}` : ""} `))
        } else if ("POLYGON_NOT_GEOMETRY_COODINATES" == e) {
          dispatch(fetchError(`Arquivo incompleto, informe junto as coodenadas da zonas. ${zoneProccess ? ` Ex: zone ${zoneProccess}` : ""}`))
        } else if ("POINT_NOT_GEOMETRY_COODINATES" == e) {
          dispatch(fetchError(`Arquivo incompleto, informe junto as coodenadas do ponto. ${pointProccess ? ` Ex: ponto ${pointProccess}` : ""}`))
        } else if ("POINT_NOT_GEOMETRY" == e) {
          dispatch(fetchError(`Arquivo incompleto, informe junto o campo geometry do ponto. ${pointProccess ? ` Ex: ponto ${pointProccess}` : ""}`))
        } else if ("POINT_NOT_PROPERTIES" == e) {
          dispatch(fetchError(`Arquivo incompleto, informe junto as propriedades pertencentes nos pontos: Pontos, UTM_X e UTM_Y. ${pointProccess ? ` Ex: ponto ${pointProccess}` : ""}`))
        } else if ("AREA_ZONE_NOT_INFORMED" == e) {
          dispatch(fetchError("Arquivo incompleto, informe junto a propriedade os campos ZNM e Area"))
        } else {
          dispatch(fetchError(e == "INCOMPLETE_FILE" ? "Aquivo Incompleto" : "Arquivos Inválidos"))
        }
      }
    }
    reader.readAsArrayBuffer(file)
  }

  const markerClickHandler = (event, poly) => {
    setSelectedPlace(poly)
    if (infoOpen) {
      setInfoOpen(false)
    }
    setInfoOpen(true)
  }

  const auxLabAnaslysis = LaboratoryUUID => {
    const lab = laboratory.find(element => element.uuid == LaboratoryUUID)
    setLabAnalysis(lab!["analysis"])
  }

  useEffect(() => {
    if (selectedWork && laboratory.length) {
      auxLabAnaslysis(selectedWork.analysisDepth[0].analysisProfile.Laboratory.uuid)
      processWork(selectedWork, orderService.status.key)
    } else {
      setLoadingWork(false)
    }
  }, [selectedWork, laboratory])

  const fildMapPlot = polygon => {
    if (polygon && newWorkPage) {
      const polyTurf = turf.polygon([polygon.coordinates.map(polyCoord => [polyCoord[1], polyCoord[0]])])

      const bounds = new google.maps.LatLngBounds()
      var arrayPoint = [] as any

      polygon.coordinates.forEach(element => {
        bounds.extend(new google.maps.LatLng(element[0], element[1]))
        arrayPoint.push({
          lat: element[0],
          lng: element[1],
        })
      })
      setGriBbox([])

      var bboxPolygon = turf.bbox(polyTurf)

      var width = bboxPolygon[2] - bboxPolygon[0]
      var height = bboxPolygon[3] - bboxPolygon[1]

      var expandFactor = 0.35
      var expandWidth = width * expandFactor
      var expandHeight = height * expandFactor
      var centerX = (bboxPolygon[2] + bboxPolygon[0]) / 2
      var centerY = (bboxPolygon[3] + bboxPolygon[1]) / 2

      var newWidth = width + expandWidth
      var newHeight = height + expandHeight

      if (newWidth > newHeight) {
        newHeight = newWidth
      } else {
        newWidth = newHeight
      }

      var minX = centerY - newHeight / 2
      var minY = centerX - newWidth / 2
      var maxX = centerY + newHeight / 2
      var maxY = centerX + newWidth / 2

      bboxPolygon = [maxY, maxX, minY, minX]

      setGriBbox(bboxPolygon)

      // setrectangleCoordinates([
      //   { lat: minX, lng: minY },
      //   { lat: maxX, lng: minY },
      //   { lat: maxX, lng: maxY },
      //   { lat: minX, lng: maxY },
      //   { lat: minX, lng: minY },
      // ])

      // @ts-ignore
      setCenterMap(bounds.getCenter().toJSON())
      setPolygonField(arrayPoint)
      setMapBounds(bounds)

      if (valueTechnique == 1) setConfigGrid({ ...initialConfig, polygonSelected: polygon })
    }
  }

  const processWork = async (data, osStatus) => {
    try {
      setOsStatus(osStatus)
      let arrayPoint = []
      data.field.zones.forEach(zone => {
        arrayPoint = arrayPoint.concat(zone.points)
      })

      auxSetMap(data.field.zones, arrayPoint)

      const analysisDepth = data.analysisDepth.map(analyse => {
        return {
          ...analyse,
          analysisProfileUUID: analyse.analysisProfile.uuid,
        }
      })

      setLabAnalysis(data.analysisDepth[0].analysisProfile.Laboratory.analysis)
      setFileName(data.field.name)

      setInitialValuesNewWork({
        laboratoryUUID: data.analysisDepth[0].analysisProfile.Laboratory.uuid,
        qtyControlSample: data.qtyControlSample,
        fildUUID: data.field.uuid,
        analysisDepth: analysisDepth,
        typeName: data.field.name,
        typeId: data.type.id,
        workUUID: data.uuid,
        name: data.name ?? "",
      })

      setShowNewDepth(false)
      setLoadingWork(false)
      setProcessWork(true)
    } catch (e: any) {
      console.log(e)
      dispatch(fetchError(e.message))
    } finally {
      setLoadingWork(false)
    }
  }

  const auxSetMap = (data, arrayPoints) => {
    data.sort((a, b) => (Number.parseInt(a.name) > Number.parseInt(b.name) ? 1 : -1))

    const polygon = data.map((pol: any) => {
      var newCenter = turf.centerOfMass(turf.polygon(pol.polygon.coordinates))
      newCenter["geometry"]["coordinates"] = [newCenter["geometry"]["coordinates"][1], newCenter["geometry"]["coordinates"][0]]
      return {
        zoneId: pol.id,
        name: pol.name,
        center: newCenter,
        area: pol.area,
        id: pol.fieldId,
        pointsCollection: pol.points,
        coordinatesArray: pol.polygon.coordinates[0],
        technicalTeamId: pol.technicalTeamId ? pol.technicalTeam.uuid : "",
        coordinates: pol.polygon.coordinates[0].map(coordinates => {
          return {
            lat: coordinates[0],
            lng: coordinates[1],
          }
        }),
      }
    })

    arrayPoints = arrayPoints.map(element => {
      return {
        identifier: element.identifier,
        coordinatesArray: {
          latCollected: element.latCollected,
          lngCollected: element.lngCollected,
          lat: element.lat,
          lng: element.lng,
          identifier: element.identifier,
          utmX: element.utmX,
          utmY: element.utmY,
          wasCollected: element.wasCollected,
        },
      }
    })

    try {
      // let realCenter = [polygon.map(z => [z.center.geometry.coordinates[0], z.center.geometry.coordinates[1]])]
      // realCenter[0].push(realCenter[0][0])
      // if (realCenter[0].length < 4) {
      //   while (realCenter[0].length < 4) {
      //     realCenter[0].push(realCenter[0][0])
      //   }
      // }
      // const polyTurf = turf.polygon(realCenter)
      // var centerPolygon = turf.center(polyTurf)

      const bounds = new google.maps.LatLngBounds()
      data.forEach(element => {
        element.polygon.coordinates.forEach(points => {
          points.forEach(point => {
            bounds.extend(new google.maps.LatLng(point[0], point[1]))
          })
        })
      })

      // setCenterMap([
      //   centerPolygon.geometry.coordinates[1],
      //   centerPolygon.geometry.coordinates[0],
      // ])
      setMapBounds(bounds)
    } catch (e) {
      console.log(e)
    }

    setArrayPoint(arrayPoints)
    setPolygons(polygon)
    setMapType("satellite")

    const colorGenerator = new ColorGenerator(data.length)
    setArrayColor(colorGenerator.generateHEX())
  }

  const submitUpdateWork = async formdata => {
    setLoading(true)
    try {
      await jwtAxios.put(`/v2/orderService/${orderService.uuid}/work/${selectedWork.uuid}`, formdata)
      updateWorks()
      dispatch(fetchSuccess())
      dispatch(showMessage(`${messages["newOS.updateWork"]}`))
    } catch (e: any) {
      console.log(e)
      dispatch(fetchError(e.message))
    } finally {
      setLoading(false)
    }
  }

  const submitWork = async formdata => {
    setLoading(true)
    try {
      const { data } = await jwtAxios.post(`/v2/orderService/${orderService.uuid}/work`, formdata)
      setModalNewWork(false)
      updateWorks()
      dispatch(fetchSuccess())
      dispatch(showMessage(`${messages["newOS.createWork"]}`))
      setModalNewWork(false)
    } catch (e: any) {
      console.log(e)
      dispatch(fetchError(e.message))
    } finally {
      setLoading(false)
    }
  }

  const handleCloseDeleteModal = () => {
    setModalDeleteWork(false)
  }

  const [modalDeleteZone, setModalDeleteZone] = useState(false)
  const [loadingDeleteZone, setLoadingDeleteZone] = useState(false)

  const handleCloseDeleteZoneModal = () => {
    setModalDeleteZone(false)
  }

  const deleteZone = async () => {
    try {
      setLoadingDeleteZone(true)
      let { data } = await jwtAxios.delete(`/zones/${selectedPlace.zoneId}`)
      setSelectedPlace(null)
      await updateWorks()
      dispatch(fetchSuccess())
      dispatch(showMessage(`Talhão Excluido`))
    } catch (error) {
      console.error(error)
      dispatch(fetchError('Erro ao excluir Zona'))
    } finally {
      setLoadingDeleteZone(false)
      handleCloseDeleteZoneModal()
    }
  }

  const deleteWork = async () => {
    try {
      setLoadingDeleteWork(true)
      let { data } = await jwtAxios.delete(`/v2/orderService/${orderService.uuid}/work/${selectedWork["uuid"]}`)
      await getProperties(false)
      dispatch(fetchSuccess())
      dispatch(showMessage(`Análise Excluída`))
      navigate("/ordem-servico/" + orderService.uuid)
      setSelectedTab(0)
    } catch (error) {
      console.error(error)
      dispatch(fetchError("Erro ao deletar análise"))
    } finally {
      setLoadingDeleteWork(false)
    }
  }

  return (
    <>
      <AlertDelete
        data={{
          open: modalDeleteWork,
          handleClose: handleCloseDeleteModal,
          textMessage: `Excluir a análise ${selectedWork["name"] ? selectedWork["name"] : ""} removerá permanentemente todos os seus dados. Gostaria de continuar?`,
          textTitle: "Exclusão da análise!",
          loading: loadingDeleteWork,
          submitForm: deleteWork,
        }}
      />

      <AlertDelete
        data={{
          open: modalDeleteZone,
          handleClose: handleCloseDeleteZoneModal,
          textMessage: `Excluir a Zona ${selectedPlace ? selectedPlace.name : ""} removerá permanentemente todos os seus dados. Gostaria de continuar?`,
          textTitle: `Excluir Zona ${selectedPlace ? selectedPlace.name : ""}?`,
          loading: loadingDeleteZone,
          submitForm: deleteZone,
        }}
      />

      <Formik
        validateOnChange={true}
        initialValues={initialValuesNewWork}
        enableReinitialize
        onSubmit={data => {
          data["polygons"] = polygons
          data["fileName"] = fileName

          if (!data.typeId) {
            dispatch(fetchError(messages["newOS.error.analyseType"] as string))
            return
          }

          if (!polygons.length) {
            dispatch(fetchError(messages["newOS.error.field"] as string))
            return
          }

          if (data.analysisDepth.length < 1) {
            dispatch(fetchError(messages["newOS.error.analyseDepth"] as string))
            return
          }

          if (data.workUUID) {
            submitUpdateWork(data)
          } else {
            submitWork(data)
          }
        }}
      >
        {({ values, setFieldValue, setValues }) => (
          <Form style={{ textAlign: "left" }} noValidate autoComplete='off'>
            {(laboratory.length && !workUUID) || (workUUID && labAnalysis.length) ? (
              <>
                <Grid container spacing={3} style={{ padding: "0px" }}>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: "100%" }}
                      required
                      onChange={e => {
                        setFieldValue("name", e.target.value)
                      }}
                      disabled={edtDisable}
                      autoComplete='false'
                      value={values.name}
                      placeholder={messages["common.name"] as string}
                      name='name'
                      label={<IntlMessages id='common.name' />}
                      variant='outlined'
                      type='text'
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      select
                      disabled={edtDisable}
                      sx={{ width: "100%" }}
                      variant='outlined'
                      InputLabelProps={{ shrink: true }}
                      placeholder={messages["work.workType"] as string}
                      label={<IntlMessages id='work.workType' />}
                      value={values.typeId}
                      name='typeId'
                    >
                      {workTypes.map((element: any) => (
                        <MenuItem
                          disabled={edtDisable}
                          key={element.id}
                          value={element.id}
                          onClick={() => {
                            setGriBbox([])
                            setFieldValue("typeId", element.id)
                            setFieldValue("typeName", element.name)
                          }}
                        >
                          {element.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      select
                      required
                      disabled={edtDisable}
                      sx={{ width: "100%" }}
                      variant='outlined'
                      InputLabelProps={{ shrink: true }}
                      placeholder={messages["common.laboratory"] as string}
                      value={values.laboratoryUUID}
                      label={<IntlMessages id='common.laboratory' />}
                      name='laboratoryUUID'
                    >
                      {laboratory.map((element: any) => (
                        <MenuItem
                          disabled={edtDisable}
                          key={element.uuid}
                          value={element.uuid}
                          onClick={() => {
                            auxLabAnaslysis(element.uuid)
                            setShowNewDepth(false)
                            updateAllFieldAnalysis(values, setFieldValue)
                          }}
                        >
                          {element.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      select
                      sx={{ width: "100%" }}
                      required
                      disabled={edtDisable}
                      variant='outlined'
                      InputLabelProps={{ shrink: true }}
                      placeholder={messages["common.field"] as string}
                      value={values.fildUUID}
                      label={<IntlMessages id='common.field' />}
                      name='fildUUID'
                    >
                      {listPropertiesFields.map((element: any) => (
                        <MenuItem
                          disabled={edtDisable}
                          onClick={() => {
                            setInfoOpen(false)
                            setSelectedPoint(null)
                            setFieldValue("fildUUID", element["uuid"])
                            fildMapPlot(element["polygon"])

                            if (gridSquare) {
                              gridSquare.setMap(null)
                              setGridSquare(null)
                            }
                          }}
                          key={element.uuid}
                          value={element.uuid}
                        >
                          {element.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: "100%" }}
                      required
                      disabled={edtDisable}
                      autoComplete='false'
                      placeholder={messages["work.qtyControlSample"] as string}
                      name='qtyControlSample'
                      value={values.qtyControlSample}
                      label={<IntlMessages id='work.qtyControlSample' />}
                      variant='outlined'
                      type='number'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider textAlign='left'>
                      {" "}
                      <h4>{"Profundidades"}</h4>{" "}
                    </Divider>
                    <FieldArray name='analysisDepth'>
                      {() =>
                        values.analysisDepth.map((analyse, index) => {
                          return (
                            <Grid key={index + "analysy"} sx={{ margin: "auto" }} spacing={4} container xs={12} md={8}>
                              <Grid item xs={3}>
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  required
                                  sx={{ width: "100%" }}
                                  size='small'
                                  disabled={edtDisable}
                                  autoComplete='false'
                                  placeholder={messages["common.start"] as string}
                                  name={`analysisDepth.${index}.start`}
                                  value={analyse.start}
                                  onChange={event => {
                                    setFieldValue(`analysisDepth.${index}.start`, event.target.value)
                                  }}
                                  label={<IntlMessages id='common.start' />}
                                  variant='outlined'
                                  type='number'
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  required
                                  sx={{ width: "100%" }}
                                  size='small'
                                  autoComplete='false'
                                  disabled={edtDisable}
                                  placeholder={messages["common.final"] as string}
                                  name={`analysisDepth.${index}.final`}
                                  onChange={event => {
                                    setFieldValue(`analysisDepth.${index}.final`, event.target.value)
                                  }}
                                  value={analyse.final}
                                  label={<IntlMessages id='common.final' />}
                                  variant='outlined'
                                  type='number'
                                />
                              </Grid>

                              <Grid item xs={5}>
                                <TextField
                                  defaultValue={values.analysisDepth[index]["analysisProfileUUID"]}
                                  select
                                  size='small'
                                  sx={{ width: "100%" }}
                                  disabled={edtDisable}
                                  label={<IntlMessages id='work.labAnalysis' />}
                                  placeholder={messages["work.labAnalysis"] as string}
                                  value={values.analysisDepth[index]["analysisProfileUUID"]}
                                  name={`analysisDepth.${index}.analysisProfileUUID`}
                                >
                                  {labAnalysis.map((element: any) => (
                                    <MenuItem key={element.uuid} id={element.uuid} disabled={edtDisable} value={element.uuid} onClick={() => updateAnalysis(values, setValues, element.uuid, index)}>
                                      {element.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>

                              <Grid item xs={1}>
                                {!edtDisable && !values.workUUID && (
                                  <>
                                    <IconButton color='warning' name={"del" + index} onClick={() => removeAnalysis(values, setValues, index)}>
                                      <RemoveCircleOutlineIcon />
                                    </IconButton>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          )
                        })
                      }
                    </FieldArray>

                    <Grid container spacing={6} justifyContent='center' sx={{ paddingTop: "30px", paddingBottom: "0px", alignItems: "center" }}>
                      <Grid item sx={{ padding: "0 !important" }}>
                        {!edtDisable && (
                          <>
                            <Button
                              disabled={showNewDepth}
                              onClick={() => {
                                onClickAddAnalysis(values, setValues)
                              }}
                              variant='outlined'
                            >
                              <h5> Nova Profundidade </h5>
                            </Button>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider textAlign='left'> </Divider>
                    {newWorkPage && (
                      <>
                        <Tabs
                          aria-label='basic tabs example'
                          onChange={(e, value) => {
                            handleChange(e, value)
                          }}
                          value={valueTechnique}
                          centered
                          style={{ marginBottom: "3px" }}
                        >
                          <Tab value={1} label='Grid' />

                          <Tab label='Zona de Manejo' value={2} />
                        </Tabs>
                      </>
                    )}

                    {isLoaded && (
                      <GoogleMap
                        mapContainerStyle={{ width: "auto", height: "50vh" }}
                        options={{
                          streetViewControl: false,
                          mapTypeId: google.maps.MapTypeId.SATELLITE,
                          fullscreenControl: false,
                          panControl: false,
                          isFractionalZoomEnabled: true,
                          mapTypeControl: false,
                          zoomControl: false,
                        }}
                        id='google-map-script'
                        onLoad={map => {
                          setMap(map)
                        }}
                        zoom={zoomMap}
                        mapTypeId={mapType}
                        onUnmount={onUnmount}
                      >
                        <>
                          {valueTechnique == 1 && newWorkPage && (
                            <>
                              <Box sx={{ position: "relative", float: "right" }}>
                                <Card sx={{ width: "15vw", maxHeight: "49vh", borderRadius: "5px", margin: ".5vh", backgroundColor: "rgba(255, 255, 255, .9)" }}>
                                  <CardContent style={{ backgroundColor: "rgba(21, 101, 192, .9)", padding: 5, marginBottom: "5px" }}>
                                    <Typography gutterBottom variant='h5' align='center' textAlign='center' component='div' color='white' fontWeight='bold' margin='auto'>
                                      Configurar Grid
                                    </Typography>
                                  </CardContent>

                                  <CardContent sx={{ padding: "5px !important", width: "100%" }}>
                                    <TextField
                                      InputProps={{ endAdornment: <InputAdornment position='end'>ha</InputAdornment> }}
                                      // error
                                      onChange={event => {
                                        var newArea = Number(event.target.value)
                                        newArea = newArea > 0 ? newArea : 1
                                        setConfigGrid({ ...configGrid, proccess: true, squareArea: newArea, squareAreaUpdate: configGrid.squareArea != newArea })
                                      }}
                                      id='outlined-error'
                                      type='number'
                                      sx={{ width: "100%" }}
                                      variant='outlined'
                                      label='Tamanho Grid'
                                      size='small'
                                      name='squareGrid'
                                      defaultValue={configGrid.squareArea}
                                      value={configGrid.squareArea}
                                    />
                                  </CardContent>

                                  <Grid container alignItems='center' justifyItems='center' justifyContent='center'>
                                    <Grid item xs={8} sx={{ paddingY: "8px", paddingLeft: "5px", paddingTop: "5px", mb: "0px" }}>
                                      <TextField
                                        // error
                                        type='number'
                                        defaultValue={0}
                                        size='small'
                                        id='outlined-error'
                                        variant='outlined'
                                        name='valueRotation'
                                        onChange={event => {
                                          setValueRotation(event.target.value ? Number(event.target.value) : 0)
                                        }}
                                        value={valueRotation}
                                        label='Rotação'
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <h6> graus </h6>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>

                                    <Grid item>
                                      <IconButton
                                        size='small'
                                        color='info'
                                        sx={{ padding: "1px" }}
                                        onClick={() => setConfigGrid({ ...configGrid, proccess: true, gridAngleTotal: configGrid.gridAngleTotal - valueRotation, gridAngle: -valueRotation })}
                                      >
                                        <RotateLeftIcon />
                                      </IconButton>
                                    </Grid>

                                    <Grid item>
                                      <IconButton
                                        size='small'
                                        color='info'
                                        sx={{ padding: "1px" }}
                                        onClick={() => setConfigGrid({ ...configGrid, proccess: true, gridAngleTotal: configGrid.gridAngleTotal + valueRotation, gridAngle: valueRotation })}
                                      >
                                        <RotateRightIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>

                                  <CardContent sx={{ padding: "0px !important", width: "100%" }}>
                                    <FormControlLabel
                                      label='Mostrar Grid'
                                      sx={{ marginLeft: "3px", marginTop: "-10px" }}
                                      control={<Switch color='info' checked={visibleSquare} onChange={() => setVisibleSquare(!visibleSquare)} />}
                                    />
                                  </CardContent>

                                  <CardContent sx={{ backgroundColor: "rgba(21, 101, 192, .9)", padding: 2, paddingInline: 0, marginBottom: "5px" }}>
                                    <Typography gutterBottom variant='h5' align='center' textAlign='center' component='div' color='white' fontWeight='bold' margin='auto'>
                                      Configurar Subamostras
                                    </Typography>
                                  </CardContent>

                                  <CardContent sx={{ padding: "5px !important" }}>
                                    <FormControl fullWidth>
                                      <InputLabel id='demo-simple-select-label'>Tipo de Amostragem</InputLabel>
                                      <Select
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        size='small'
                                        label='Tipo de Amostragem'
                                        defaultValue={configGrid.samplingType}
                                        value={configGrid.samplingType}
                                        onChange={event => setConfigGrid({ ...configGrid, proccess: true, samplingType: event.target.value })}
                                      >
                                        <MenuItem value={"gridPerCell"}>Grade por célula</MenuItem>
                                        <MenuItem value={"pointGrid"}>Grade por ponto</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </CardContent>

                                  <CardContent sx={{ padding: "5px !important" }}>
                                    <TextField
                                      // error
                                      id='outlined-error'
                                      size='small'
                                      sx={{ width: "100%" }}
                                      variant='outlined'
                                      type='number'
                                      label='Qtde subamostra'
                                      value={configGrid.subsampleQuantity}
                                      onChange={event => setConfigGrid({ ...configGrid, proccess: true, subsampleQuantity: Number(event.target.value) })}
                                      defaultValue={1}
                                    />
                                  </CardContent>

                                  {configGrid.samplingType == "pointGrid" && (
                                    <>
                                      <CardContent sx={{ padding: "5px !important" }}>
                                        <TextField
                                          // error
                                          sx={{ width: "100%" }}
                                          type='number'
                                          id='outlined-error'
                                          variant='outlined'
                                          label='Raio Subamostra'
                                          size='small'
                                          name='subsample'
                                          onChange={event => setConfigGrid({ ...configGrid, proccess: true, sampleRadius: Number(event.target.value) })}
                                          value={configGrid.sampleRadius}
                                          InputProps={{ endAdornment: <InputAdornment position='end'>m</InputAdornment> }}
                                          defaultValue={1}
                                        />
                                      </CardContent>
                                    </>
                                  )}
                                </Card>
                              </Box>
                            </>
                          )}

                          {
                            <Polygon
                              path={rectangleCoordinates}
                              key={"test22"}
                              options={{
                                strokeColor: "black",
                                strokeWeight: 2,
                                fillOpacity: 0,
                                zIndex: 0,
                              }}
                            />
                          }

                          {
                            <Polygon
                              path={polygonField}
                              key={"test"}
                              options={{
                                strokeColor: "black",
                                strokeWeight: 2,
                                fillOpacity: 0,
                                zIndex: 0,
                              }}
                            />
                          }

                          {polygons.map((poly: any) => {
                            return (
                              <Polygon
                                key={poly.name}
                                options={{
                                  fillColor: poly.technicalTeamId ? "black" : "#9d9d93",
                                  fillOpacity: poly.technicalTeamId ? 0.3 : 0.1,
                                  strokeWeight: 2,
                                  strokeColor: "black",
                                  zIndex: 1,
                                }}
                                onClick={event => markerClickHandler(event, poly)}
                                path={poly.coordinates}
                              />
                            )
                          })}
                          {infoOpen && selectedPlace && (
                            <>
                              <InfoWindow
                                position={{
                                  lat: selectedPlace.center.geometry.coordinates[1],
                                  lng: selectedPlace.center.geometry.coordinates[0],
                                }}
                                onCloseClick={() => setInfoOpen(false)}
                              >
                                <div style={{ minWidth: "200px" }}>
                                  <Grid container xs={12} spacing={1} sx={{ mt: 0, textAlign: "center" }}>
                                    <Grid item xs={12}>
                                      <h2 style={{ padding: "3px" }}>Zona: {selectedPlace.name}</h2>
                                    </Grid>

                                    <Grid item xs={6}>
                                      <h5
                                        style={{
                                          paddingLeft: "3px",
                                          paddingBottom: "2px",
                                        }}
                                      >
                                        Área: {areaToLocaleString(selectedPlace.area)} ha
                                      </h5>
                                    </Grid>

                                    <Grid item xs={6}>
                                      <h5 style={{ paddingLeft: "3px" }}>Pontos: {selectedPlace.pointsCollection.length}</h5>
                                    </Grid>
                                  </Grid>

                                  <TextField
                                    select
                                    disabled={edtDisable}
                                    size='small'
                                    variant='outlined'
                                    placeholder={messages["common.team"] as string}
                                    label={<IntlMessages id='common.team' />}
                                    InputLabelProps={{ shrink: true }}
                                    value={selectedPlace.technicalTeamId}
                                    defaultValue={selectedPlace.technicalTeamId}
                                    sx={{ width: "100%", padding: "3px", marginTop: "10px" }}
                                    name={"technicalTeamId" + selectedPlace.name}
                                  >
                                    {technicalTeam.map((element: any) => (
                                      <MenuItem
                                        disabled={edtDisable}
                                        key={element.uuid}
                                        value={element.uuid}
                                        onClick={() => {
                                          let arrayPoligons = polygons
                                          let posIndex = arrayPoligons.findIndex(e => e.name == selectedPlace.name)
                                          arrayPoligons[posIndex].technicalTeamId = element.uuid
                                          setPolygons(arrayPoligons)
                                          const centriodPoly = turf.centroid(turf.polygon([selectedPlace["coordinatesArray"]]))
                                          setCenterMap([centriodPoly.geometry.coordinates[0], centriodPoly.geometry.coordinates[1]])
                                        }}
                                      >
                                        {element.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  {
                                    <>
                                    
                                      <Grid container xs={12} spacing={1} sx={{ mt: 0 }}>
                                        <Grid item xs={6}>
                                          <LoadingButton size='small' fullWidth onClick={() => setInfoOpen(false)} type='button' color='info' variant='outlined'>
                                            Fechar
                                          </LoadingButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Tooltip title={"Excluir Zona"}>
                                            <LoadingButton disabled={!(osStatus === 'collection_process' || osStatus === 'create' )} onClick={() => setModalDeleteZone(true)} size='small' fullWidth type='button' color='error' variant='contained'>
                                              Excluir
                                            </LoadingButton>
                                          </Tooltip>
                                        </Grid>
                                      </Grid>
                                    
                                    </>
                                  }

                                </div>
                              </InfoWindow>
                            </>
                          )}

                          {arrayPoint.map(point => {
                            return (
                              <>
                                <Circle
                                  center={{ lat: point.coordinatesArray.lat, lng: point.coordinatesArray.lng }}
                                  onClick={() => {
                                    setSelectedPoint(point)
                                    markerClickHandler({}, null)
                                    setCenterMap([point.coordinatesArray.lat, point.coordinatesArray.lng])
                                  }}
                                  radius={5}
                                  options={{
                                    fillColor: osStatus ? (point.coordinatesArray.wasCollected ? "#00CC0A" : "#EA1414") : "#EA1414",
                                    strokeColor: osStatus ? (point.coordinatesArray.wasCollected ? "#00CC0A" : "#EA1414") : "#EA1414",
                                    strokeOpacity: 1,
                                    fillOpacity: 1,
                                    zIndex: 2,
                                  }}
                                />
                              </>
                            )
                          })}

                          {selectedPoint && (
                            <>
                              <InfoWindow
                                position={{
                                  lat: selectedPoint.coordinatesArray.lat,
                                  lng: selectedPoint.coordinatesArray.lng,
                                }}
                                onCloseClick={() => {
                                  setSelectedPoint(null)
                                  // setCenterMap([selectedPoint.coordinatesArray.lat, selectedPoint.coordinatesArray.lng])
                                }}
                              >
                                <div>
                                  <br />
                                  <h4>Ponto: {selectedPoint.identifier}</h4>
                                  <br />
                                  <h6>Lat: {selectedPoint.coordinatesArray.lat}</h6>
                                  <h6>Long: {selectedPoint.coordinatesArray.lng}</h6>
                                  <h6>Lat. Coletada: {selectedPoint.coordinatesArray.latCollected ? selectedPoint.coordinatesArray.latCollected : ""}</h6>
                                  <h6>Long. Coletada: {selectedPoint.coordinatesArray.lngCollected ? selectedPoint.coordinatesArray.lngCollected : ""}</h6>
                                  <h6>UTM_X: {selectedPoint.coordinatesArray.utmX}</h6>
                                  <h6>UTM_Y: {selectedPoint.coordinatesArray.utmX}</h6>
                                  <br />
                                </div>
                              </InfoWindow>
                            </>
                          )}
                        </>
                      </GoogleMap>
                    )}
                  </Grid>

                  {!edtDisable && (
                    <>
                      {!values.workUUID && valueTechnique == 2 && (
                        <>
                          <Grid item xs={12}>
                            <UploadModern uploadText='Envie aqui o zip do talhão' dropzone={dropzone} />
                            <AppList data={uploadedFiles} renderRow={(file, index) => <FileRow key={index + file.path} file={file} onDeleteUploadFile={() => {}} />} />
                          </Grid>
                        </>
                      )}

                      <Grid item style={{ marginTop: "-10px" }} container direction='row' alignItems='center' justifyContent={"space-between"}>
                        {values.workUUID ? (
                          <LoadingButton sx={{ width: "15vw" }} variant='contained' color='error' size='large' onClick={() => setModalDeleteWork(true)}>
                            <h4>
                              <IntlMessages id={"common.delete"} />
                            </h4>
                          </LoadingButton>
                        ) : (
                          <LoadingButton sx={{ width: "15vw" }} variant='outlined' color='error' size='large' onClick={() => setModalNewWork(false)}>
                            <h4>
                              <IntlMessages id={"common.close"} />
                            </h4>
                          </LoadingButton>
                        )}

                        <LoadingButton
                          loading={loading}
                          disabled={valueTechnique == 1 && newWorkPage}
                          sx={{ width: "15vw", float: "end" }}
                          variant='contained'
                          color='primary'
                          type='submit'
                          size='large'
                        >
                          <h4>
                            <IntlMessages id={"common.save"} />
                          </h4>
                        </LoadingButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid container direction='row' justifyContent='center' alignItems='center'>
                  <CircularProgress />
                </Grid>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}
export default NewWork
