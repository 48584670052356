import VerticalNavItem from '@crema/core/AppLayout/components/VerticalNav/VerticalItem/VerticalNavItem'
import { List, ListItemText } from '@mui/material'
import AppNavLink from '@crema/core/AppNavLink'


interface VerticalItemProps {
  url?: string;
  text?: string;
}

const VerticalItemCliente: React.FC<VerticalItemProps> = ({ url, text }) => {
  return (
    <VerticalNavItem
      level={1}
      button
      to={url}
      component={AppNavLink}
      activeClassName="active"
      exact={"true"}
    >
      <ListItemText
        className="nav-item-content"
        primary={text}
        classes={{ primary: "nav-item-text" }}
        primaryTypographyProps={{
          style: {
            whiteSpace: "pre-wrap",
            wordWrap: "break-word"
          }
        }}
      />
    </VerticalNavItem>
  )
}

const VerticalNav = (nameClient='Altamir dos Santos pereira', urlLink='/clientes') => {
  return (
    <List
      sx={{
        position: "relative",
        padding: 0,
      }}
      component="div">
      <VerticalItemCliente text={nameClient} url={urlLink} />
    </List>
  )
}

export default VerticalNav