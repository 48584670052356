import React from "react"
const LaboratoriesList = React.lazy(() => import("./list"));
const Laboratory = React.lazy(() => import("./register"));


export const laboratoriesPagesRoutes = [
  {
    path: "/laboratorios",
    element: <LaboratoriesList />,
  },

  {
    path: "/laboratorios/novo",
    element: <Laboratory />,
  },

  {
    path: "/laboratorio/:labUUID",
    element: <Laboratory />,
  },
  
]
