import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, List, ListItem, ListItemText, TextField, Tooltip, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LoadingButton from "@mui/lab/LoadingButton"
import Close from '@mui/icons-material/Close'
import { useState } from "react"
const moment = require('moment')


const AplicationNotes = ({ data }) => {
  const {
    applicationRecommendationNotes,
    formSetFieldValues,
    formValues,
    setNotes,
  } = data

  const [expandedAccordion, setExpandedAccordion] = useState(false)
  const [loadingDestroy, setLoadingDestroy] = useState('')

  const deleteNote = async (noteUUID: string) => {
    try {
      setLoadingDestroy(noteUUID)
      const newApplicationRecommendationNotes = applicationRecommendationNotes.filter(app => app.uuid != noteUUID)
      setNotes(newApplicationRecommendationNotes ? newApplicationRecommendationNotes : [])

    } catch (error) {
      console.log(error)

    } finally {
      setLoadingDestroy('')

    }
  }

  return (
    <Accordion style={{ margin: '10px 5px' }} expanded={expandedAccordion} onChange={() => setExpandedAccordion(!expandedAccordion)} >
      <AccordionSummary
        style={{ backgroundColor: '#00800D' }}
        expandIcon={<ExpandMoreIcon htmlColor="#FFF" />}
      >
        <Typography color='white' sx={{ width: '33%', flexShrink: 0 }}>
          <h4 style={{ fontWeight: '600', color: '#fff' }}>
            Anotações
          </h4>
        </Typography>
      </AccordionSummary>

      <AccordionDetails style={{ width: '100%' }}>
        <List sx={{ width: '100%', padding: '0px', maxHeight: '600px', overflow: 'auto' }}>
          {
            applicationRecommendationNotes.map(appNotes => (
              <>
                <ListItem key={appNotes.uuid} alignItems="flex-start" style={{ width: '100%' }}>
                  <ListItem secondaryAction={
                    <Tooltip title="Excluir" >
                      <LoadingButton loading={appNotes.uuid == loadingDestroy} style={{ float: 'right' }} aria-label="delete" color="error"
                        onClick={() => deleteNote(appNotes.uuid)}
                      >
                        <Close />
                      </LoadingButton>
                    </Tooltip>
                  }>

                    <ListItemText style={{ padding: '5px', width: '200px' }}>
                      <h4 style={{ fontWeight: '500', color: '#42454d' }}>
                        {moment(appNotes.createdAt).format('L')} às {moment(appNotes.createdAt).format('HH:mm')}
                      </h4>
                    </ListItemText>

                    <ListItemText style={{ padding: '5px', width: '100%' }} >
                      <h4 style={{ fontWeight: '500', color: '#42454d' }}>
                        {appNotes.note}
                      </h4>
                    </ListItemText>

                  </ListItem>
                </ListItem>
                <Divider light />
              </>
            ))
          }
        </List>
        {
          (!formValues.disable) && <>
            <Grid container justifyContent="space-between" alignItems="flex-end" direction="row" sx={{ width: '100%', marginTop: '10px', padding: '10px' }}>

              <Grid item xs={12} >
                <TextField
                  style={{ width: '100%' }}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: 16,
                      fontWeight: 500,
                    }
                  }}
                  onChange={(e) => { formSetFieldValues('note', e.target.value) }}
                  label="Descrição"
                  name='note'
                  value={formValues.note}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </>
        }

      </AccordionDetails>
    </Accordion >
  );
};

export default AplicationNotes