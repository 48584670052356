import { Box, Button, Divider, Grid, IconButton, MenuItem, MobileStepper, Modal, Step, StepLabel, Stepper, TextField, useTheme } from '@mui/material'
import { THEME_APP_TEXTDIELD, THEME_MODAL } from '../../../shared/themes/themeConst'
import AppTextField from '../../../@crema/core/AppFormComponents/AppTextField'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { fetchError, showMessage } from "../../../redux/actions"
import IntlMessages from '../../../@crema/utility/IntlMessages'
import CalagemSatBasesCTC from './preResult/calagemSatBasesCTC'
import jwtAxios from '../../../@crema/services/auth/jwt-auth'
import Plastering from './preResult/plasteringResult'
import CloseIcon from '@mui/icons-material/Close'
import Phosphating from './preResult/phosphating'
import { useDispatch } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import Potash from './preResult/potash'
import { Form, Formik } from 'formik'
import Boron from './preResult/boron'
import { useIntl } from 'react-intl'
import Zinc from './preResult/zinc'
import { useState } from 'react'
import * as yup from "yup"
import { onlyNumbers } from '../../../@crema/utility/Utils'


export default function ModalMui({ data }) {
  const {
    updateWorkRecomendations,
    addInWorkRecomendations,
    setFilteredMethodology,
    filteredMethodology,
    recommendations,
    preResultZones,
    initialValues,
    openModalView,
    setStepModal,
    methodology,
    modalView,
    workId,
    step,
  } = data


  const { messages } = useIntl()
  const dispatch = useDispatch()
  const theme = useTheme()

  const steps = [
    'Metodologia',
    'Pré-Resultado',
  ]

  const [loadingAppRecommendation, setLoadingAppRecommendation] = useState(false)

  const registerRecomendation = async (formData) => {
    setLoadingAppRecommendation(true)
    try {
      const { data } = await jwtAxios.post(`/application-recommendation`, formData)
      addInWorkRecomendations(data)
      dispatch(showMessage(`${messages["recommendations.message.saveRecommendation"]}`))
      modalView(false)

    } catch (error: any) {
      console.error(error)
      dispatch(fetchError(error.message))

    } finally {
      setLoadingAppRecommendation(false)
    }
  }

  const updateRecomendation = async (formData) => {
    setLoadingAppRecommendation(true)
    try {
      const { data } = await jwtAxios.put(`/application-recommendation/${formData['recId']}`, formData)
      updateWorkRecomendations(formData['recId'], data)
      dispatch(showMessage(`Recomendação Atualizada`))

    } catch (error: any) {
      console.error(error)
      dispatch(fetchError(error.message))

    } finally {
      setLoadingAppRecommendation(false)
    }
  }

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(String(messages["recommendations.validation.name"])),

    parameters: yup
      .object({
        'V2%': yup
          .number()
          .required(String(messages["recommendations.validation.V2"])),

        'PRNT%': yup
          .number()
          .required(String(messages["recommendations.validation.PRNT"])),

        efficiencyFactor: yup
          .number()
          .required(String(messages["recommendations.validation.efficiencyFactor"])),
      })
  })

  return (
    <Modal
      open={openModalView}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...THEME_MODAL, padding: '0' }}>

        <Grid container paddingTop='20px'>
          <Grid item xs={11} >
            <Stepper activeStep={step} alternativeLabel sx={{ width: '100%'}}>
              {
                steps.map((label) => (
                  <Step key={label}>
                    <StepLabel sx={{
                      fontSize: '20px !important',
                      '& .MuiStepLabel-label': {
                        color: 'black',
                        fontSize: '16px',
                        marginTop: '5px'
                      }
                      }}>{label}</StepLabel>
                  </Step>
                ))
              }
            </Stepper>
          </Grid>

          <Grid item xs={1} sx={{ mt: -3 }}>
            <IconButton size="large" style={{ float: 'right', marginRight: '7px' }} onClick={() => modalView(false)}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Grid>


          <Grid item xs={12} sx={{mt: 0}}>

            <Formik
              validateOnChange={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(data) => {
                if (data['recId']) {
                  updateRecomendation({
                    recId: data['recId'],
                    recommendationsId: data.recommendationId,
                    methodologyId: data.methodologyId,
                    settings: JSON.stringify({
                      analysisDepth: data.analysisDepth,
                      preResultZones: preResultZones,
                      parameters: data.parameters,
                    }),
                    name: data.name,
                    note: data.note,
                    workId: workId,
                  })

                } else {
                  registerRecomendation({
                    recommendationsId: data.recommendationId,
                    methodologyId: data.methodologyId,
                    settings: JSON.stringify({
                      analysisDepth: data.analysisDepth,
                      preResultZones: preResultZones,
                      parameters: data.parameters,
                    }),
                    name: data.name,
                    note: data.note,
                    workId: workId,
                  })
                }

              }}
            >
              {
                ({ values, setFieldValue, setValues }) => (
                  <>
                    <Form style={{ textAlign: "left" }} noValidate autoComplete="off" >
                      <Grid container xs={12} sx={{ paddingX: '5px', width: '90vw', overflow: 'auto', maxHeight: '80vh' }} >
                        {
                          step == 0 && (
                            <>
                              <Grid item xs={12} padding='8px'>
                                <AppTextField
                                  placeholder={messages["common.name"] as string}
                                  label={<IntlMessages id="common.name" />}
                                  InputLabelProps={{ shrink: true }}
                                  disabled={values.disable}
                                  sx={THEME_APP_TEXTDIELD}
                                  autoComplete="false"
                                  variant="outlined"
                                  required={true}
                                  name="name"
                                />
                              </Grid>

                              <Grid item xs={6} padding='8px'>
                                <AppTextField
                                  placeholder={messages["common.recommendations"] as string}
                                  label={<IntlMessages id="common.recommendations" />}
                                  defaultValue={values.recommendationId}
                                  InputLabelProps={{ shrink: true }}
                                  value={values.recommendationId}
                                  disabled={values.disable}
                                  name="recommendationId"
                                  sx={THEME_APP_TEXTDIELD}
                                  variant="outlined"
                                  required={true}
                                  select
                                >
                                  {
                                    recommendations.map((rec: any) => (
                                      <MenuItem
                                        key={rec.id}
                                        value={rec.id}
                                        onClick={() => {
                                          setFieldValue("recommendationId", rec.id)
                                          let methodologyArray = []

                                          if (rec.key == 'phosphating') {
                                            methodologyArray = methodology.filter(met => ['critical_level_phosphating'].includes(met.key))

                                          } else if (rec.key == 'liming') {
                                            setFieldValue('parameters.P', 15)
                                            methodologyArray = methodology.filter(met => ['satBaseCTC'].includes(met.key))

                                          } else if (rec.key == 'potash') {
                                            setFieldValue('parameters.P', 5)
                                            methodologyArray = methodology.filter(met => ['potash_critical_level', 'potash_%_k_ctc'].includes(met.key))

                                          } else if (rec.key == 'plastering') {
                                            methodologyArray = methodology.filter(met => ['critical_level_plastering'].includes(met.key))

                                          } else if (rec.key == 'boron') {
                                            methodologyArray = methodology.filter(met => ['critical_level_boron'].includes(met.key))

                                          } else if (rec.key == 'zinc') {
                                            methodologyArray = methodology.filter(met => ['critical_level_zinc'].includes(met.key))
                                          }

                                          setFieldValue("methodologyId", methodologyArray[0]['id'])
                                          setFilteredMethodology(methodologyArray)

                                        }}
                                      >
                                        {
                                          rec.name
                                        }
                                      </MenuItem>
                                    ))
                                  }
                                </AppTextField>
                              </Grid>

                              <Grid item xs={6} padding='8px'>
                                <AppTextField
                                  placeholder={messages["common.metodology"] as string}
                                  label={<IntlMessages id="common.metodology" />}
                                  defaultValue={values.methodologyId}
                                  InputLabelProps={{ shrink: true }}
                                  value={values.methodologyId}
                                  disabled={values.disable}
                                  sx={THEME_APP_TEXTDIELD}
                                  name="methodologyId"
                                  variant="outlined"
                                  required={true}
                                  select
                                >
                                  {
                                    filteredMethodology.map((rec: any) => {
                                      return (
                                        <MenuItem
                                          key={rec.id}
                                          value={rec.id}
                                          onClick={() => { setFieldValue("methodologyId", rec.id) }}
                                        >
                                          {rec.name}
                                        </MenuItem>
                                      )
                                    })
                                  }
                                </AppTextField>
                              </Grid>

                              <Grid item xs={12} padding='8px'>
                                <Divider textAlign="left">{messages["recommendations.parameters"] as string}</Divider>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid container xs={12} >

                                  {values.recommendationId == 73 && (
                                    <>
                                      <Grid item xs={6} padding='8px'>
                                        <AppTextField
                                          placeholder={messages["recommendations.V2"] as string}
                                          label={<IntlMessages id="recommendations.V2" />}
                                          defaultValue={values['parameters']["V2%"]}
                                          InputLabelProps={{ shrink: true }}
                                          disabled={values.disable}
                                          sx={THEME_APP_TEXTDIELD}
                                          name="parameters.V2%"
                                          autoComplete="false"
                                          variant="outlined"
                                          type="number"
                                          required
                                        />
                                      </Grid>

                                      <Grid item xs={6} padding='8px'>
                                        <AppTextField
                                          placeholder={messages["recommendations.PRNT"] as string}
                                          label={<IntlMessages id="recommendations.PRNT" />}
                                          defaultValue={values['parameters']['PRNT%']}
                                          InputLabelProps={{ shrink: true }}
                                          disabled={values.disable}
                                          sx={THEME_APP_TEXTDIELD}
                                          name="parameters.PRNT%"
                                          autoComplete="false"
                                          variant="outlined"
                                          type="number"
                                          required
                                        />
                                      </Grid>
                                    </>
                                  )
                                  }

                                  {/* {values.recommendationId == 80 && (
                                    <Grid item xs={6} padding='8px'>
                                      <AppTextField
                                        placeholder={messages["recommendations.productivity"] as string}
                                        label={<IntlMessages id="recommendations.productivity" />}
                                        defaultValue={values['parameters']["productivity"]}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={values.disable}
                                        sx={THEME_APP_TEXTDIELD}
                                        name="parameters.productivity"
                                        autoComplete="false"
                                        variant="outlined"
                                        type="number"
                                        required={true}
                                      >
                                      </AppTextField>
                                    </Grid>

                                  )} */}

                                  {(values.recommendationId == 74 || values.recommendationId == 80) && (
                                    <>
                                      <Grid item xs={6} padding='8px'>
                                        <AppTextField
                                          placeholder={messages["recommendations.P"] as string}
                                          label={
                                            <IntlMessages id={
                                              values.recommendationId == 74 ?
                                                "recommendations.P"
                                                :
                                                values.methodologyId == 81 ?
                                                  "recommendations.%_K_CTC"
                                                  :
                                                  "recommendations.K"
                                            } />}
                                          defaultValue={values['parameters']['P']}
                                          InputLabelProps={{ shrink: true }}
                                          disabled={values.disable}
                                          sx={THEME_APP_TEXTDIELD}
                                          name="parameters.P"
                                          autoComplete="false"
                                          variant="outlined"
                                          type="number"
                                          required
                                        />
                                      </Grid>
                                    </>
                                  )
                                  }


                                  <Grid item xs={6} padding='8px'>
                                    <TextField
                                      placeholder={messages["recommendations.efficiencyFactor"] as string}
                                      label={<IntlMessages id="recommendations.efficiencyFactor" />}
                                      defaultValue={values['parameters']['efficiencyFactor']}
                                      name="parameters.efficiencyFactor"
                                      InputLabelProps={{ shrink: true }}
                                      value={values.parameters.efficiencyFactor}
                                      onChange={(e) => {
                                        const valueNumber = Number.parseInt(e.target.value)
                                        if(Number.isNaN(valueNumber))setFieldValue( "parameters.efficiencyFactor", 0)
                                        else if(valueNumber <= 1) setFieldValue( "parameters.efficiencyFactor", 0)
                                        else if(valueNumber <= 100) setFieldValue( "parameters.efficiencyFactor", e.target.value)
                                      }}
                                      disabled={values.disable}
                                      sx={THEME_APP_TEXTDIELD}
                                      // autoComplete="false"
                                      variant="outlined"
                                      type="number"
                                      required
                                    />
                                  </Grid>

                                  {values.recommendationId == 73 && (
                                    <>
                                      <Grid item xs={6} padding='8px'>
                                        <AppTextField
                                          placeholder={messages["recommendations.minimumLimit"] as string}
                                          label={<IntlMessages id="recommendations.minimumLimit" />}
                                          InputLabelProps={{ shrink: true }}
                                          defaultValue={values['parameters']['minimumLimit']}
                                          disabled={values.disable}
                                          sx={THEME_APP_TEXTDIELD}
                                          autoComplete="false"
                                          name="minimumLimit"
                                          variant="outlined"
                                          type="number"
                                        />
                                      </Grid>

                                      <Grid item xs={6} padding='8px'>
                                        <AppTextField
                                          placeholder={messages["recommendations.maximumLimit"] as string}
                                          label={<IntlMessages id="recommendations.maximumLimit" />}
                                          defaultValue={values['parameters']['maximumLimit']}
                                          InputLabelProps={{ shrink: true }}
                                          name="parameters.maximumLimit"
                                          disabled={values.disable}
                                          sx={THEME_APP_TEXTDIELD}
                                          autoComplete="false"
                                          variant="outlined"
                                          type="number"
                                        />
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </Grid>

                              {values.recommendationId == 73 && (
                                <>
                                  <Grid item xs={12} padding='8px'>
                                    <Divider textAlign="left">{messages["recommendations.DepthCorrectionFactor"] as string}</Divider>

                                  </Grid>
                                  <Grid item xs={12} >
                                    <Grid container xs={12} >
                                      {
                                        values.analysisDepth.map((analysisDep, index) => {
                                          return (
                                            <Grid container xs={12}>
                                              <Grid item xs={3} padding='8px'>
                                                <AppTextField
                                                  placeholder={messages["common.start"] as string}
                                                  label={<IntlMessages id="common.start" />}
                                                  name={`analysisDepth.${index}.start`}
                                                  InputLabelProps={{ shrink: true }}
                                                  sx={THEME_APP_TEXTDIELD}
                                                  autoComplete="false"
                                                  variant="outlined"
                                                  disabled={true}
                                                  type="number"
                                                  required
                                                />
                                              </Grid>

                                              <Grid item xs={3} padding='8px'>
                                                <AppTextField
                                                  InputLabelProps={{ shrink: true }}
                                                  placeholder={messages["common.final"] as string}
                                                  name={`analysisDepth.${index}.final`}
                                                  label={<IntlMessages id="common.final" />}
                                                  sx={THEME_APP_TEXTDIELD}
                                                  disabled={true}
                                                  autoComplete="false"
                                                  variant="outlined"
                                                  type="number"
                                                  required
                                                />
                                              </Grid>

                                              <Grid item xs={6} padding='8px'>
                                                <AppTextField
                                                  placeholder={messages["recommendations.corrections"] as string}
                                                  defaultValue={values.analysisDepth[index]['correction']}
                                                  label={<IntlMessages id="recommendations.corrections" />}
                                                  value={values.analysisDepth[index].correction}
                                                  name={`analysisDepth.${index}.correction`}
                                                  InputLabelProps={{ shrink: true }}
                                                  disabled={values.disable}
                                                  sx={THEME_APP_TEXTDIELD}
                                                  variant="outlined"
                                                  select
                                                >
                                                  {
                                                    ['0', '25', '50', '75', '100'].map((percentage: string) => (
                                                      <MenuItem
                                                        key={percentage}
                                                        value={percentage}
                                                        onClick={() => {
                                                          setFieldValue(`analysisDepth.${index}.correction`, percentage)
                                                        }}
                                                      >
                                                        {percentage}%
                                                      </MenuItem>
                                                    ))
                                                  }
                                                </AppTextField>
                                              </Grid>
                                            </Grid>
                                          )
                                        })
                                      }
                                    </Grid>
                                  </Grid>
                                </>
                              )}

                              <>
                                <Grid item xs={12} padding='15px'>
                                  <Divider textAlign="left"></Divider>
                                </Grid>

                                <Grid item xs={12} padding='8px' paddingBottom='20px'>
                                  <AppTextField
                                    autoComplete="false"
                                    multiline
                                    rows={6}
                                    disabled={values.disable}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder={'Justifique os parâmetros utilizados'}
                                    name="note"
                                    label={'Nota'}
                                    variant="outlined"
                                    sx={THEME_APP_TEXTDIELD}
                                  />
                                </Grid>
                              </>

                            </>

                          )
                        }

                        <Box sx={{ px: '20px', width: '100%' }}>

                          {
                            step == 1 && values.recommendationId == 73 && (
                              <CalagemSatBasesCTC data={{ ...data, formValues: values }} />
                            )

                          }

                          {
                            step == 1 && values.recommendationId == 74 && (
                              <Phosphating data={{ ...data, formValues: values }} />
                            )
                          }

                          {
                            step == 1 && values.recommendationId == 80 && (
                              <Potash data={{ ...data, formValues: values }} />
                            )
                          }

                          {
                            step == 1 && values.recommendationId == 85 && (
                              <Plastering data={{ ...data, formValues: values }} />
                            )
                          }

                          {
                            step == 1 && values.recommendationId == 88 && (
                              <Boron data={{ ...data, formValues: values }} />
                            )
                          }

                          {
                            step == 1 && values.recommendationId == 91 && (
                              <Zinc data={{ ...data, formValues: values }} />
                            )
                          }
                        </Box>
                      </Grid>

                      <Grid item xs={12} >
                        <MobileStepper
                          position="static"
                          variant="dots"
                          activeStep={step}
                          steps={steps.length}
                          nextButton={
                            step == (steps.length - 1) ?
                              <>
                                {
                                  values.disable ? <>
                                    <Button size="small" color='error' disabled={step > (steps.length - 1)} onClick={() => modalView(false)}>
                                      <IntlMessages id="common.close" />
                                      <KeyboardArrowRight />
                                    </Button>
                                  </> : <>
                                    <LoadingButton
                                      loading={loadingAppRecommendation}
                                      disabled={values.disable}
                                      variant="text"
                                      color="primary"
                                      type="submit"
                                      size="small"
                                    >
                                      <IntlMessages id="common.save" />
                                    </LoadingButton>
                                  </>
                                }
                              </>
                              :
                              <>
                                <Button size="small" disabled={step > (steps.length - 1)} onClick={() => setStepModal(step + 1)}>
                                  <IntlMessages id="common.next" />
                                  <KeyboardArrowRight />
                                </Button>
                              </>
                          }
                          backButton={
                            step <= 0 ? <>
                              <Button size="small" color='error' onClick={() => modalView(false)}>
                                <KeyboardArrowLeft />
                                <IntlMessages id="common.close" />
                              </Button>

                            </> : <>
                              <Button size="small" onClick={() => setStepModal(step - 1)}>
                                <KeyboardArrowLeft />
                                <IntlMessages id="common.back" />
                              </Button>

                            </>
                          }
                        />
                      </Grid>
                    </Form>
                  </>
                )
              }

            </Formik>

          </Grid>


        </Grid>
      </Box>
    </Modal>
  )
}
