import axios from "axios";
import { useNavigate } from "react-router-dom";
const axiosApiInstance = axios.create()

const getApi = () => {

  if (process.env.REACT_APP_STAGE == 'production') {
    return "https://api.production.fertisoil.com.br/api"
  }
  else if (process.env.REACT_APP_STAGE == 'stage') {
    return "https://api.staging.fertisoil.com.br/api"
  }
  else {
    return "http://localhost:3000/api"
  }
}

const jwtAxios = axios.create({
  baseURL: getApi(), // YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
})

export const refreshAccessToken = async () => {
  return new Promise((resolve, reject) =>{
    try {

      const api = getApi()
      const refreshToken = localStorage.getItem("refreshToken")
      
      axios.post(api + "/auth/refresh-token", { refreshToken } )
      .then(async (res) => {
        setAuthToken(res.data.token, res.data.refreshToken )
        return resolve(res)
      })
      .catch((err) => {
        return reject(err)
      })
  
    } catch(err) {
      return reject(err)
  
    }
  })
}


jwtAxios.interceptors.response.use(
  (res) => res,
  async (error) => {
    const originalRequest = error.config
    
    if(error.response.status === 403 && !originalRequest._retry) {
      try{
        console.log(error.response)
        originalRequest._retry = true
        const accessToken = await refreshAccessToken() as any
        originalRequest.headers['Authorization'] =  `Bearer  ${accessToken.data.token}`;
        const resp = await axiosApiInstance(originalRequest)
        if(resp.status == 200){
          return resp
        } else {
          localStorage.removeItem("token");
          throw resp.data
        }

      } catch (e:any) {
        localStorage.removeItem("token");
        return Promise.reject(e.response.data)
      }
      
    } else {
      console.log(error)
      return Promise.reject(error.response.data)

    }

  }
)

export const setAuthToken = (token?: string, refreshToken?: string) => {
  if (token && refreshToken) {
    jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem("token", token)
    localStorage.setItem("refreshToken", refreshToken)

  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
    localStorage.removeItem("token");
    // localStorage.removeItem("refreshToken");
  }
}

export default jwtAxios;