import { RoutePermittedRole } from "../shared/constants/AppConst"
import { VscSettingsGear,  } from "react-icons/vsc"
import { MdPeopleOutline } from "react-icons/md"
import { RiProfileLine } from "react-icons/ri"
import { FiUsers } from "react-icons/fi"
import { ReactNode } from "react"
import { ImLab } from "react-icons/im"
import { GiWheat } from "react-icons/gi"


export interface RouterConfigData {
  id: string
  title: string
  messageId: string
  icon?: string | ReactNode
  type: "item" | "group" | "collapse" | "divider"
  children?: RouterConfigData[]
  permittedRole?: RoutePermittedRole
  color?: string
  url?: string
  exact?: boolean
  count?: number
}

const routesConfig: RouterConfigData[] = [
  {
    id: "app",
    title: "Teste",
    messageId: "sidebar.sample",
    type: "group",
    children: [
      {
        id: "page-1",
        title: "Clientes",
        messageId: "sidebar.sample.page1",
        type: "item",
        icon: <MdPeopleOutline />,
        url: "/clientes",
      },
      {
        id: "laboratory",
        title: "Laboratory",
        messageId: "sidebar.sample.laboratoryPage",
        type: "item",
        icon: <ImLab />,
        url: "/laboratorios",
      },
      // {
      //   id: "os",
      //   title: "OS",
      //   messageId: "sidebar.sample.osListPage",
      //   type: "item",
      //   icon: <VscFileSymlinkFile />,
      //   url: "/os",
      // },

      {
        id: "users",
        title: "Users",
        messageId: "sidebar.sample.usersPage",
        type: "item",
        icon: <FiUsers />,
        url: "/usuarios",
      },

      {
        id: "harvest",
        title: "harvest",
        messageId: "sidebar.sample.harvest",
        type: "item",
        icon: <GiWheat />,
        url: "/safras",
      },

      {
        id: "profile",
        title: "profile",
        messageId: "sidebar.sample.company",
        type: "item",
        icon: <RiProfileLine />,
        url: "/perfil",
      },

      {
        id: "config",
        title: "config",
        messageId: "sidebar.sample.configValuesResult",
        type: "item",
        icon: <VscSettingsGear />,
        url: "/configuracao",
      },
    ],
  },
]
export default routesConfig