import { ptBR } from "@mui/material/locale";
import saMessages from "../locales/pt_BR.json";

const saLang = {
  messages: {
    ...saMessages,
  },
  muiLocale: ptBR,
  locale: "pt-BR",
};
export default saLang;
