import ApplicationFosfatagem from "./application/applicationFosfatagem"
import ApplicationPlastering from "./application/applicationPlastering"
import { fetchError, showMessage } from "../../../redux/actions"
import { THEME_MODAL } from "../../../shared/themes/themeConst"
import IntlMessages from "../../../@crema/utility/IntlMessages"
import ApplicationPotash from "./application/applicationPotash"
import jwtAxios from "../../../@crema/services/auth/jwt-auth"
import ApplicationBoron from "./application/applicationBoron"
import { Box, Grid, IconButton, Modal } from "@mui/material"
import ApplicationZinc from "./application/applicationZinc"
import ApplicationCTC from "./application/application"
import { useEffect, useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch } from "react-redux"
import { LoadingButton } from "@mui/lab"
import { Form, Formik } from "formik"
import { useIntl } from "react-intl"
import AlertDialogDataLoss from "../../../shared/components/alerts/alertDataLoss"

export default function NewAplication({ data }) {
  const { updateWorkRecomendations, setViewNewAplication, newApplicationTypes, viewNewAplication, settings, workId } = data

  const formikRef = useRef(null) as any

  const initialValuesSetUp = {
    additionalRecommendation: 0,
    typeRecomendationId: null,
    zeroBelowMinimum: false,
    considerSurplus: false,
    typePhosphateId: null,
    limestoneTypeId: null,
    efficiencyFactor: 100,
    maximumLimit: null,
    minimumLimit: null,
    disposalLimit: 0,
    maintenance: 0,
    disable: false,
    average: false,
    round: 4,
    note: "",
  }

  const [loadindNewApplication, setLoadindNewApplication] = useState(false) as any
  const [newAplicationZones, setNewAplicationZones] = useState([]) as any
  const [initialValues, setInitialValues] = useState(initialValuesSetUp)
  const [initialValuesUpdate, setInitialValuesUpdate] = useState(initialValuesSetUp)
  const [preResultZones, setPreResultZones] = useState([]) as any
  const [notes, setNotes] = useState([]) as any

  const [open, setOpen] = useState(false)

  const closeModalApp = () => {
    const isEquals = JSON.stringify(initialValuesUpdate) == JSON.stringify(formikRef["current"]["values"])
    if(!isEquals) handleClickOpen()
    else setViewNewAplication(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (advance: boolean = false) => {
    if(advance) setViewNewAplication(false)
    setOpen(false)
  }

  useEffect(() => {
    setInitialValuesUpdate(initialValues)
  }, [initialValues])

  useEffect(() => {
    if (settings) {
      setInitialValues({
        limestoneTypeId: null,
        typePhosphateId: null,
        typeRecomendationId: settings.typeRecomendationId,
        maximumLimit: settings.maximumLimit ? settings.maximumLimit : settings.parameters.maximumLimit,
        minimumLimit: settings.minimumLimit ? settings.minimumLimit : settings.parameters.minimumLimit,
        disposalLimit: settings.disposalLimit ? settings.disposalLimit : 0,
        additionalRecommendation: settings.additionalRecommendation,
        efficiencyFactor: settings.parameters.efficiencyFactor,
        zeroBelowMinimum: settings.zeroBelowMinimum,
        considerSurplus: settings.considerSurplus,
        maintenance: settings.maintenance,
        disable: settings.disable,
        average: settings.average,
        round: settings.round,
        note: "",
      })
      setNotes(settings.notes)
      setPreResultZones(settings.preResultZones)
    }
  }, [settings])

  const { messages } = useIntl()
  const dispatch = useDispatch()

  const registerRecomendation = async formData => {
    setLoadindNewApplication(true)
    const { current } = formikRef
    try {
      if (settings["id"]) {
        let deleteNotesId = [] as any
        settings.notes.map(noteSet => {
          const find = notes.find(note => noteSet.uuid == note.uuid)
          if (!find) {
            deleteNotesId.push(noteSet["uuid"])
          }
          return
        })

        formData["excludeNotes"] = deleteNotesId
        const { data } = await jwtAxios.put(`/application-recommendation/${settings.applicationRecommendationId}/aplication/${settings.id}/work/${workId}`, formData)
        updateWorkRecomendations(data.id, data, settings.id)

        dispatch(showMessage(`Aplicação Atualizada`))
        if (current) current.setFieldValue("note", "")
      } else {
        formData["excludeNotes"] = []
        const { data } = await jwtAxios.post(`/application-recommendation/${settings.applicationRecommendationId}/work/${workId}`, formData)
        const numberApplications = data.application.length
        updateWorkRecomendations(data.id, data, numberApplications ? data.application[numberApplications - 1].id : null)
        dispatch(showMessage(`${messages["recommendations.message.saveApplication"]}`))
        // setViewNewAplication(false)
        if (current) current.setFieldValue("note", "")
      }
    } catch (error: any) {
      console.error(error)
      dispatch(fetchError(error.message))
    } finally {
      setLoadindNewApplication(false)
    }
  }

  return (
    <Modal aria-describedby='parent-modal-description' aria-labelledby='parent-modal-title' open={viewNewAplication}>
      <Box sx={{ ...THEME_MODAL, width: "90vw", maxHeight: "96vh", padding: "0" }}>
        <AlertDialogDataLoss data={{
          handleClose,
          open
        }} />
        <Grid item xs={12}>
          <IconButton
            style={{ float: "right", position: "relative", marginRight: "10px", marginTop: "5px" }}
            onClick={() => closeModalApp()}
            size='large'
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>

          <Formik
            innerRef={formikRef}
            validateOnChange={true}
            initialValues={initialValues}
            onSubmit={data => {
              registerRecomendation({
                ...data,
                maintenance: data.maintenance ? data.maintenance : 0,
                additionalRecommendation: data.additionalRecommendation ? data.additionalRecommendation : 0,
                disposalLimit: data.disposalLimit ? data.disposalLimit : 0,
                zones: JSON.stringify(newAplicationZones),
              })
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form noValidate autoComplete='off' style={{ float: "right", position: "relative", marginRight: "10px", marginTop: "-15px" }}>
                  <Grid container xs={12}>
                    <Grid item xs={12}>
                      {settings.recommendations.key == "liming" && (
                        <ApplicationCTC
                          data={{
                            agriculturalCorrectives: newApplicationTypes.find(app => app.key == settings.recommendations.key)["applicationProducts"],
                            applicationRecommendationNotes: notes,
                            formSetFieldValues: setFieldValue,
                            preResultZones: preResultZones,
                            setNewAplicationZones,
                            newAplicationZones,
                            formValues: values,
                            setNotes,
                          }}
                        />
                      )}

                      {settings.recommendations.key == "phosphating" && (
                        <ApplicationFosfatagem
                          data={{
                            recomendationTypes: newApplicationTypes.find(app => app.key == settings.recommendations.key)["applicationProducts"],
                            applicationRecommendationNotes: notes,
                            setNotes,
                            formSetFieldValues: setFieldValue,
                            preResultZones: preResultZones,
                            setNewAplicationZones,
                            newAplicationZones,
                            formValues: values,
                          }}
                        />
                      )}

                      {settings.recommendations.key == "potash" && (
                        <ApplicationPotash
                          data={{
                            recomendationTypes: newApplicationTypes.find(app => app.key == settings.recommendations.key)["applicationProducts"],
                            applicationRecommendationNotes: notes,
                            setNotes,
                            formSetFieldValues: setFieldValue,
                            preResultZones: preResultZones,
                            setNewAplicationZones,
                            newAplicationZones,
                            formValues: values,
                          }}
                        />
                      )}

                      {settings.recommendations.key == "plastering" && (
                        <ApplicationPlastering
                          data={{
                            recomendationTypes: newApplicationTypes.find(app => app.key == settings.recommendations.key)["applicationProducts"],
                            applicationRecommendationNotes: notes,
                            setNotes,
                            formSetFieldValues: setFieldValue,
                            preResultZones: preResultZones,
                            setNewAplicationZones,
                            newAplicationZones,
                            formValues: values,
                          }}
                        />
                      )}

                      {settings.recommendations.key == "boron" && (
                        <ApplicationBoron
                          data={{
                            recomendationTypes: newApplicationTypes.find(app => app.key == settings.recommendations.key)["applicationProducts"],
                            applicationRecommendationNotes: notes,
                            setNotes,
                            formSetFieldValues: setFieldValue,
                            preResultZones: preResultZones,
                            setNewAplicationZones,
                            newAplicationZones,
                            formValues: values,
                          }}
                        />
                      )}

                      {settings.recommendations.key == "zinc" && (
                        <ApplicationZinc
                          data={{
                            recomendationTypes: newApplicationTypes.find(app => app.key == settings.recommendations.key)["applicationProducts"],
                            applicationRecommendationNotes: notes,
                            setNotes,
                            formSetFieldValues: setFieldValue,
                            preResultZones: preResultZones,
                            setNewAplicationZones,
                            newAplicationZones,
                            formValues: values,
                          }}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sx={{ padding: "20px" }}>
                      <Grid justifyContent='space-between' spacing={"5px"} container xs={12}>
                        <Grid item>
                          <LoadingButton style={{ float: "right", width: "15vw" }}  onClick={() => closeModalApp()} variant='outlined' color='error'>
                            <IntlMessages id='common.close' />
                          </LoadingButton>
                        </Grid>

                        <Grid item>
                          <LoadingButton style={{ float: "right", width: "15vw" }} disabled={values.disable} loading={loadindNewApplication} variant='contained' color='primary' type='submit'>
                            {settings["id"] ? "Atualizar" : "Salvar"}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Box>
    </Modal>
  )
}
