import CircularProg from "../../../../shared/components/CircularProgrees/Circular"
import AppTextField from "../../../../@crema/core/AppFormComponents/AppTextField"
import { Breadcrumbs, Grid, Autocomplete, Paper } from "@mui/material"
import { fetchSuccess, showMessage } from "../../../../redux/actions"
import IntlMessages from "../../../../@crema/utility/IntlMessages"
import AppsContainer from "../../../../@crema/core/AppsContainer"
import GoogleMap from "../../../../shared/components/googleMap"
import LayoutPadrao from '@crema/core/AppLayout/LayoutPadrao'
import jwtAxios from "@crema/services/auth/jwt-auth"
import { Link, useParams } from "react-router-dom"
import LoadingButton from "@mui/lab/LoadingButton"
import VerticalNav from "../../VerticalNavItem"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Form, Formik } from "formik"
import { useIntl } from "react-intl"
import * as yup from "yup"


const EdtProperties = () => {
  const navigate = useNavigate()
  let { clientId, farmId } = useParams()
  
  const { messages } = useIntl()
  const dispatch = useDispatch()
  
  const [propertiesCoodinates, setPropertiesCoodinates] = useState([]) as any
  const [loadingCustomer, setLoadingCustomer] = useState(false)
  const [propertie, setProperties] = useState([]) as any
  const [initialValues, setInitialValues] = useState({
    name: '',
    address: '',
    city: '',
    note: '',
  })
  const [cities, setCities] = useState([]) as any
  const [customer, setCustomer] = useState() as any
  const [loading, setLoading] = useState(false)
  const [loadingCity, setLoadingCity] = useState(false)

  useEffect(() => {
    if(!farmId) searchCity('')
    else getProperties(farmId)

    getClient(clientId)
  }, [])

  const getClient = async (clientId) => {
    try {
      setLoadingCustomer(true)
      let { data } = await jwtAxios.get(`clients/${clientId}`)
      setCustomer(data)

    } catch (e) {
      console.error(e)

    } finally {
      setLoadingCustomer(false)
    }
  }

  const getProperties = async (farmId) => {
    try {
      setLoading(true)
      let { data } = await jwtAxios.get(`/properties/${farmId}`)

      setCities([data.city])
      if(data.polygon) setPropertiesCoodinates(data.polygon.coordinates)

      setProperties(data)
      setInitialValues({
        address: data.address,
        city: data.city,
        name: data.name,
        note: data.note,
      })

    } catch (e) {
      console.error(e)

    } finally {
      setLoading(false)
    }
  }

  const searchCity = async (search) => {
    try {
      setLoadingCity(true)
      let { data } = await jwtAxios.get(`/cities?city=${search}`)
      setCities(data)

    } catch (e) {
      console.error(e)

    } finally {
      setLoadingCity(false)
    }
  }

  const _renderVerticalNav = () => VerticalNav(customer.name, `/clientes/${clientId}/fazendas`)
  const validationSchema = yup.object({
    address: yup
      .string()
      .min(5, String(messages["validation.enderecoFormat"]))
      .required(String(messages["validation.enderecoRequired"])),
    name: yup
      .string()
      .min(3, String(messages["validation.nameFormat"]))
      .required(String(messages["validation.nameRequired"])),
    city: yup
      .object()
      .typeError(String(messages["validation.cidadeRequired"]))
      .required(String(messages["validation.cidadeRequired"])),
  })

  return (

    <>
      {
        !loadingCustomer && customer? <>
          <LayoutPadrao verticalNav={_renderVerticalNav()}>
            <Breadcrumbs maxItems={2} aria-label="breadcrumb" sx={{marginBottom: '5%'}}>
              <Link style={{ textDecoration: 'none', color: "black" }} to="/clientes">
                Clientes
              </Link>
              <Link to={`/clientes/${clientId}/fazendas`} style={{ textDecoration: 'none', color: "black" }}>
                { customer.name }
              </Link>
            </Breadcrumbs>
            <AppsContainer title={"Nova Propiedade"} fullView>
              <Paper elevation={0} sx={{padding: '4%'}}>
                {
                  !loading && propertie ? <>
                    <Formik
                      validateOnChange={true}
                      initialValues={initialValues}
                      enableReinitialize
                      validationSchema={validationSchema}
                      onSubmit={async (dataForm: any, { setSubmitting }) => {
                        try {
                          dataForm = {
                            ...dataForm,
                            coordinatesArray: propertiesCoodinates,
                            cityId: dataForm.city.id,
                            clientUUID: clientId,
                          }
                          
                          setSubmitting(true)
                          if (!farmId) {
                            const { data } = await jwtAxios.post("/v2/properties", dataForm)
                            navigate("/fazenda/" + data.uuid, {
                              replace: true,
                            })

                          } else {
                            await jwtAxios.put(`/v2/properties/${farmId}`, dataForm)
                          }
                          setSubmitting(false)
                          dispatch(fetchSuccess())
                          dispatch(
                            showMessage(
                              (farmId
                                ? String(messages["common.editSuccess"])
                                : String(messages["common.registerSuccess"]))
                            )
                          )
                        } catch (e) {
                          console.error(e);
                          
                        }
                      }}
                    >
                      {({ isSubmitting, values, setFieldValue }) => (
                        <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                          <Grid container spacing={6}>
                            <Grid item xs={12}>
                              <AppTextField
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                autoComplete="false"
                                placeholder={messages["common.name"] as string}
                                name="name"
                                label={<IntlMessages id="common.name" />}
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                  "& .MuiInputBase-input": {
                                    fontSize: 14,
                                  },
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <AppTextField
                                autoComplete="false"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder={messages["common.endereco"] as string}
                                name="address"
                                label={<IntlMessages id="common.endereco"/>}
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                  "& .MuiInputBase-input": {
                                    fontSize: 14,
                                  },
                                }}
                              />
                            </Grid>
                            
                            <Grid item xs={12}>
                            <Autocomplete
                                getOptionLabel={(option: any) => {
                                  if(option) {
                                    return `${option!.name} - ${option!.state.uf}`
                                  }
                                  return ''
                                }}
                                onChange={(_, value) => { if (value) setFieldValue('city', value) }}
                                onInputChange={(event, newInputValue) => searchCity(newInputValue) }
                                filterOptions={(x) => x}
                                options={cities}
                                defaultValue={values.city}
                                sx={{ width: '100%' }}
                                renderInput={(params) => {
                                  return (
                                    <AppTextField
                                      {...params}
                                      placeholder={messages["common.cidade"] as string}
                                      label={<IntlMessages id="common.cidade" />}
                                      InputLabelProps={{ shrink: true }}
                                      variant="outlined"
                                      name="city"
                                      required
                                      sx={{
                                        width: "100%",
                                        "& .MuiInputBase-input": {
                                          fontSize: 14,
                                        },
                                      }}
                                    />
                                  )
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <AppTextField
                                autoComplete="false"
                                multiline
                                rows={3}
                                InputLabelProps={{ shrink: true }}
                                placeholder={messages["common.observation"] as string}
                                name="note"
                                label={<IntlMessages id="common.observation" />}
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                  "& .MuiInputBase-input": {
                                    fontSize: 14,
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {
                                <GoogleMap data={{
                                  mapContainerStyle: { height: '500px' },
                                  setPropertiesCoodinates,
                                  propertiesCoodinates,
                                  importFile: true,
                                  Poligon: null, 
                                }} />
                              }
                            </Grid>
                            <Grid
                              item
                              container
                              direction="row"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                color="primary"
                                type="submit"
                                size="large"
                              >
                                <IntlMessages id="common.save" />
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </>
                  :
                  <>
                    <CircularProg />
                  </>
                }
              </Paper>
            </AppsContainer>
          </LayoutPadrao>
        </> 
      :
        <>
          <CircularProg />
        </>
      }
    </>
  )
}

export default EdtProperties