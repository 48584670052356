import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"
import { allPreResultVariables, areaToLocaleString, fosfatagem, fosfatagemExedente, localeString, plastering, plasteringV2 } from "../../../../shared/utils/utils"
import { Fonts } from "../../../../shared/constants/AppEnums"
import { useEffect, useState } from "react"
import { THEME_H4_APLICATION, THEME_TABLE_ROW } from "../../../../shared/themes/themeConst"


export default function Plastering({ data }) {
  const {
    setPreResultZones,
    preResultZones,
    setKeys,
    labKeys,
    labResult,
    formValues
  } = data

  const [totalArea, setTotalArea] = useState(0)
 
  useEffect(() => {

    const arrayKeys = Object.keys(labResult).sort((a:any, b:any) => a.split('-')[0] - b.split('-')[0])
    setKeys(arrayKeys)

    let auxZones = {}
    var auxTotalNC = 0
    var auxTotalArea = 0

    const key = arrayKeys[0]
    labResult[key].forEach((result, index) => {
      if(!(result.zone.name in auxZones)){
        auxZones[result.zone.name] = {
          totalNC: 0
        }
      }

      let resultS = 0
      let resultSatArg = 0
      let resultSSum = 0

      arrayKeys.map( k => {
        resultS += labResult[k][index]['S'] ? labResult[k][index]['S'] : 0
        resultSatArg += labResult[k][index]['SatArg'] ? labResult[k][index]['SatArg'] : 0
      })
      resultSSum = resultS
      resultS = resultS ? resultS / arrayKeys.length : 0
      resultSatArg = resultSatArg ? resultSatArg / arrayKeys.length : 0

      const resPlastering = formValues['formulaVersion'] == 'v1'
        ? 
      plastering(resultS, resultSatArg) 
        : 
      plasteringV2(resultSSum, resultSatArg) 

      if(resPlastering > 0){
        auxTotalNC = auxTotalNC + (result.zone.area * resPlastering)
      }

      auxZones[result.zone.name]['name'] = result.zone.name
      auxZones[result.zone.name]['area'] = result.zone.area
      auxZones[result.zone.name]['totalNC'] = resPlastering
      auxZones[result.zone.name]['S'] =  resultS
      auxZones[result.zone.name][key] = {
        ...allPreResultVariables,
        plastering: resPlastering > 0 ? resPlastering : 0,
        exedente: 0,
        PRes: result['PRes'],
        S: resultS,
        SatArg: resultSatArg,
      }
      auxTotalArea += result.zone.area ? result.zone.area : 0
      
    })
    setPreResultZones({
      totalNC: auxTotalNC,
      zones: Object.values(auxZones)
    })
    setTotalArea(auxTotalArea)
  
  }, [])

  return (
    <Grid container sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ overflow: 'hidden' }}>
        <Table size="small" stickyHeader >
        <caption><h4 style={THEME_H4_APLICATION}>Total NC: {localeString(preResultZones.totalNC)} (Kg)</h4></caption>
        <caption><h4 style={THEME_H4_APLICATION}>Área Total: {localeString(totalArea)} (ha)</h4></caption>
          <TableHead>
            <TableRow sx={THEME_TABLE_ROW}> 
              <TableCell style={{minWidth: 75}} align="center">Zona</TableCell>
              <TableCell style={{minWidth: 75}} align="center">Área (ha)</TableCell>
              {/* <TableCell style={{minWidth: 75}} align="center">Profundidade</TableCell> */}
              <TableCell style={{minWidth: 75}} align="center">S (mg/dm³)</TableCell>
              <TableCell style={{minWidth: 75}} align="center">Argila (g/Kg)</TableCell>
              <TableCell style={{minWidth: 75}} align="center">NG (Kg/ha)</TableCell>
              <TableCell style={{minWidth: 75}} align="center">Total (kg)</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              preResultZones.zones.map(((zone, index) => {
                const key = 0
                return (
                  <TableRow hover key={index} sx={THEME_TABLE_ROW}>
                    <TableCell align="center"> {zone.name} </TableCell>
                    <TableCell align="center"> {areaToLocaleString(zone.area)} </TableCell>
                    {/* <TableCell align="center"> {labKeys[key]} </TableCell> */}
                    <TableCell align="center">{areaToLocaleString(zone['S'])}</TableCell>
                    <TableCell align="center">{!zone[labKeys[key]]['SatArg'] ? 0 : localeString(zone[labKeys[key]]['SatArg'])}</TableCell>
                    <TableCell align="center">{!zone[labKeys[key]]['plastering'] ? 0 : localeString(zone[labKeys[key]]['plastering'])}</TableCell>
                    <TableCell align="center">{localeString(zone.area * zone['totalNC'])}</TableCell>
                  </TableRow>
                )
              }))
            }
          </TableBody>
        </Table>
        
      </TableContainer>
    </Grid>
  )
}