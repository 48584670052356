import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"
import { preResultPotashExcedente, preResultPotash_K_CTC, preResultPotashCriticalLevel, preResultPotashCriticalLevelExcedente, allPreResultVariables, preResultPotashCriticalLevelV2, localeString, areaToLocaleString } from "../../../../shared/utils/utils"
import { Fonts } from "../../../../shared/constants/AppEnums"
import { useEffect, useState } from "react"
import { THEME_H4_APLICATION, THEME_TABLE_ROW } from "../../../../shared/themes/themeConst"


export default function Potash({ data }) {
  const {
    setPreResultZones,
    preResultZones,
    setKeys,
    labKeys,
    labResult,
    formValues
  } = data

  const [totalArea, setTotalArea] = useState(0)

  useEffect(() => {
    const critical_level = formValues.methodologyId == 81 ? false : true

    const arrayKeys = Object.keys(labResult).sort((a:any, b:any) => a.split('-')[0] - b.split('-')[0])
    setKeys(arrayKeys)

    let auxZones = {}
    var auxTotalNC = 0
    var auxTotalArea = 0

    const key = arrayKeys[0]
    labResult[key].forEach((result) => {
      if(!(result.zone.name in auxZones)){
        auxZones[result.zone.name] = {
          totalNC: 0
        }
      }

      var resultPotash = 0
      var resultPotashExcedente = 0

      if(critical_level){
        resultPotash = preResultPotashCriticalLevelV2(formValues['parameters']['P'], result['Kmg'], formValues['parameters']['efficiencyFactor'] )
        resultPotashExcedente = preResultPotashCriticalLevelExcedente(formValues['parameters']['P'], result['Kmg'])
      } else {
        resultPotash = preResultPotash_K_CTC(result['CTC(T)'], formValues['parameters']['P'], result['K'], formValues['parameters']['efficiencyFactor'] )
        resultPotashExcedente = preResultPotashExcedente( result['CTC(T)'], formValues['parameters']['P'], result['K'])
      }
      

      if(resultPotash > 0){
        auxTotalNC = auxTotalNC + (result.zone.area * resultPotash)
      }

      auxTotalArea += result.zone.area ? result.zone.area : 0

      auxZones[result.zone.name]['name'] = result.zone.name
      auxZones[result.zone.name]['area'] = result.zone.area
      auxZones[result.zone.name]['totalNC'] = resultPotash > 0 ? resultPotash : 0
      auxZones[result.zone.name][key] = {
        ...allPreResultVariables,
        potash: resultPotash > 0 ? resultPotash : 0,
        exedente: resultPotashExcedente > 0 ? resultPotashExcedente : 0,
        k:  result['Kmg'],
      }
    })

    setPreResultZones({
      totalNC: auxTotalNC,
      zones: Object.values(auxZones)
    })
    setTotalArea(auxTotalArea)
  
  }, [])

  return (
    <Grid container sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ overflow: 'hidden' }}>
        <Table size="small" stickyHeader >
        <caption style={{padding: 0}}><h4 style={THEME_H4_APLICATION}>Total NC: {localeString(preResultZones.totalNC)} (Kg)</h4></caption>
        <caption style={{padding: 0}}><h4 style={THEME_H4_APLICATION}>Área Total: {localeString(totalArea)} (ha)</h4></caption>
          <TableHead>
            <TableRow sx={THEME_TABLE_ROW}>
              <TableCell style={{minWidth: 75}} align="center">Zona</TableCell>
              <TableCell style={{minWidth: 75}} align="center">Área</TableCell>
              <TableCell style={{minWidth: 75}} align="center">Profundidade</TableCell>
              <TableCell style={{minWidth: 75}} align="center">K (mg/dm³)</TableCell>      
              <TableCell style={{minWidth: 75}} align="center">Potassagem (K₂O)</TableCell>
              <TableCell style={{minWidth: 75}} align="center">Exedente (K₂O)</TableCell>
              <TableCell style={{minWidth: 75}} align="center">Total (Kg)</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              preResultZones.zones.map(((zone, index) => {
                if(!zone[labKeys[0]]) return <></>
                return (
                  <TableRow hover key={index} sx={THEME_TABLE_ROW}>
                    <TableCell align="center"> {zone.name} </TableCell>
                    <TableCell align="center"> {areaToLocaleString(zone.area)} </TableCell>
                    <TableCell align="center"> {labKeys[0]} </TableCell>
                    <TableCell align="center">{localeString(zone[labKeys[0]]['k'])}</TableCell>
                    <TableCell align="center">{localeString(zone[labKeys[0]]['potash'])}</TableCell>
                    <TableCell align="center">{localeString(zone[labKeys[0]]['exedente'])}</TableCell>
                    <TableCell align="center">{localeString(zone.area * zone['totalNC'])}</TableCell>
                  </TableRow>
                )
              }))
            }
          </TableBody>
        </Table>
        
      </TableContainer>
    </Grid>
  )
}