import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import React, { useState } from "react";

interface KeyModel {
  id: number,
  numColun: number
}

interface Props {
  data: KeyModel;
}

const TableSke: React.FC<Props> = ({data}) => {
  const skeletonArray = Array(data.numColun).fill('')

  return (
    <TableRow
      className="item-hover"
      hover={true}
      key={ data.id }
      sx={{
        "& .tableCell": {
          fontSize: 13,

          padding: "12px 8px",
          "&:first-of-type": {
            pl: 5,
          },
          "&:last-of-type": {
            pr: 5,
          },
        },
      }}
    >
      {
        skeletonArray.map((v, index) => (
          <TableCell component="th" scope="row" className="tableCell">
            <Skeleton animation="wave" />
          </TableCell>
        ))
      }
      
    </TableRow>
  );
};

export default TableSke;
