import { Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material"
import { GoogleMap, InfoWindow, Marker, Polygon, useJsApiLoader } from "@react-google-maps/api"
import { RESULT_SUPPORTED, areaToLocaleString, bboxAdjustingSquare, groupByCategory } from "shared/utils/utils"
import UploadClassic from "../../../@crema/core/AppUpload/UploadClassic"
import UploadModern from "@crema/core/AppUpload/UploadModern"
import logo from "../../../assets/logos/cooperplanGestao.png"
import FileRow from "../../../@crema/core/AppUpload/FileRow"
import jwtAxios from "@crema/services/auth/jwt-auth"
import LoadingButton from "@mui/lab/LoadingButton"
import { FaSquareFull } from "react-icons/fa"
import { useDropzone } from "react-dropzone"
import React, { useEffect, useState } from "react"
import { fetchError } from "redux/actions"
import { AppList } from "../../../@crema"
import { useDispatch } from "react-redux"
import ReactDOM from "react-dom/server"
import html2canvas from "html2canvas"
import { useIntl } from "react-intl"
import PlotGraph from "./plotGraph"
import * as turf from "@turf/turf"
import tokml from "geojson-to-kml"
import chroma from "chroma-js"
import { jsPDF } from "jspdf"
import "./result.css"
import { useAuthUser } from "../../../@crema/utility/AuthHooks"

var FileSaver = require("file-saver")

const WorkResults = ({ data }) => {
  var { listAllWorksResult, setLoadingResult, loadingResult, listElement, worksResult, orderInfo, workId, work, osId } = data

  const { user } = useAuthUser()
  const { messages } = useIntl()
  const dispatch = useDispatch()
  const dropzone = useDropzone()
  const [map, setMap] = useState(null) as any
  const [MapBounds, setMapBounds] = useState(null) as any
  const [modalImprimir, setModalImprimir] = useState(false) as any
  const [tipoImpressao, setTipoImpressao] = useState("l")

  const [resultByDepth, setResultByDepth] = useState([]) as any
  const [listShowInfo, setListShowInfo] = useState([]) as any
  const [rangeLabels, setRangeLabels] = useState([]) as any
  const [elementPosition, setElementPosition] = useState(0)
  const [viewLabel, setViewLabel] = useState("valueResult")
  const [listDepth, setListDepth] = useState([]) as any
  const [listZone, setListZone] = useState([]) as any
  const [showResult, setShowResult] = useState(false)

  const [element, setElement] = useState("")
  const [depth, setDepth] = useState("")
  const [uploadedFile] = useState([])
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC-5csGO5FhX4qZkTcSgj2rlJc7T4rV1B0",
  })
  const [mapRenderKey, setMapRenderKey] = useState(0)
  const [mapType, setMapType] = useState("satellite")
  const [zoomMap, setZoomMap] = useState(5)

  const optionsGoogleMaps = {
    disableDefaultUI: true,
    panControl: false,
    isFractionalZoomEnabled: true,
    mapTypeControl: false,
    zoomControl: false,
    scaleControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeId: google.maps.MapTypeId.SATELLITE,
  }

  //Modal Graph
  const [modalPlot, setModalPlot] = useState(false)
  const [loadingCsv, setLoadingCsv] = useState(false)

  const [interpolation, setInterpolation] = useState([]) as any
  const [googleMapsSquares, setGoogleMapsSquares] = useState([]) as any

  const [allowsInterpolation, setAllowsInterpolation] = useState("")
  const [interpolationMaps, setInterpolationMaps] = useState({
    min: 0,
    max: 0,
  })

  const [newListElements, setNewListElements] = useState([]) as any

  const [visualization, setVisualization] = useState("zones")

  useEffect(() => {
    try {
      if (dropzone.acceptedFiles[0]) {
        if (!RESULT_SUPPORTED.find(type => type == dropzone.acceptedFiles[0]["type"])) {
          dispatch(fetchError(messages["common.unsupportedExtension"] as string))
        } else {
          proccessFile(dropzone.acceptedFiles[0])
        }
      }
    } catch (e) {
      dispatch(fetchError(messages["common.importFileError"] as string))
    }
  }, [dropzone.acceptedFiles])

  useEffect(() => {
    if (element && depth && resultByDepth[depth]) {
      proccessZone()
    }
  }, [element, resultByDepth, depth, visualization])

  useEffect(() => {
    if (!element) setElement(listElement.length ? listElement[0].key : null)

  }, [listElement])

  useEffect(() => {
    if(element) {
      const listArraySubGroup = groupByCategory(listElement, "subGroup")
      const elementsSubGroup = Object.keys(listArraySubGroup)
      const newListElement = [] as any
  
      elementsSubGroup.forEach(key => {
        var positionElement = newListElement.length
        newListElement.push({
          id: positionElement,
          disable: true,
          label: key,
          sx: {
            margin: "0px",
            minHeight: 0,
            padding: "4px",
            color: "black !important",
            backgroundColor: "rgba(0,0,0, .05)",
          }
        })

        listArraySubGroup[key].forEach(elementSubG => {
          positionElement = newListElement.length  
          newListElement.push({
            id: positionElement,
            disable: false,
            label: elementSubG.prettyName,
            key: elementSubG.key,
            element: elementSubG,
            sx: { 
              backgroundColor: elementSubG.key == element ? "#e5f2e6" : "transparent", 
              margin: "0px", 
              padding: "8px 5%" 
            }
          })
        })
      })
      setNewListElements(newListElement)
    }


  }, [element])

  const _renderModalImprimir = () => {
    if (map && MapBounds != null) map.fitBounds(MapBounds)
    return (
      <Dialog
        aria-describedby='alert-dialog-slide-description'
        onClose={() => {
          setModalImprimir(false)
        }}
        open={modalImprimir}
        keepMounted
      >
        <DialogTitle>{"Configurações da impressão"}</DialogTitle>
        <FormControl fullWidth sx={{ p: 5 }}>
          <InputLabel id='demo-simple-select-label'>{"Tipo de Impressão"}</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={tipoImpressao}
            label='Age'
            onChange={e => {
              setTipoImpressao(e.target.value)
            }}
          >
            <MenuItem value={"l"}> {"Paisagem"} </MenuItem>
            <MenuItem value={"p"}> {"Normal"} </MenuItem>
          </Select>
        </FormControl>

        <DialogActions>
          <Button
            onClick={() => {
              imprimir(tipoImpressao)
            }}
          >
            {"Imprimir"}
          </Button>
          <Button
            onClick={() => {
              setModalImprimir(false)
            }}
          >
            {"Cancelar"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const imprimir = (tipoImpressao: any) => {
    // @ts-ignore: Unreachable code error
    html2canvas(document.querySelector("#imprime"), {
      backgroundColor: null,
      useCORS: true,
    }).then(canvas => {
      var imgLogo = new Image()
      imgLogo.src = logo
      var imgData = canvas.toDataURL("image/jpeg", 1.0)

      var doc = new jsPDF(tipoImpressao, "mm", [297, 210])
      doc.rect(5, 5, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10, "S")
      var elemento = listElement.find(lEle => lEle.key == element)

      //var elementoMedida = elemento.name.split("(")[1].replace('dm-3', 'dm³')
      var elementoMedida = ""
      var title = `Distribuição dos Componentes de Solo \n${elemento.prettyName} ${elementoMedida ? "(" + elementoMedida : ""} ${depth}cm\n \n${orderInfo.properties.name} `
      doc.setFontSize(12)

      if (user.enterpriseBanner) {
        var img = new Image()
        img.src = user.enterpriseBanner

        img.onload = function () {
          var widthProportion = 25 * (img.naturalWidth / img.naturalHeight)

          if (tipoImpressao === "l") {
            doc.addImage(user.enterpriseBanner, 10, 15, widthProportion, 25)
            doc.text(title, 287, 20, { align: "right" })
            doc.addImage(imgData, 10, 42, 277, 155, "FAST")
            doc.save("resultado.pdf")
          } else {
            doc.addImage(user.enterpriseBanner, 15, 10, widthProportion, 25)
            doc.text(title, 195, 20, { align: "right" })
            doc.addImage(imgData, 15, 40, 180, 120)
            doc.save("resultado.pdf")
          }
        }
      } else {
        if (tipoImpressao === "l") {
          doc.text(title, 287, 20, { align: "right" })
          doc.addImage(imgData, 10, 42, 277, 155, "FAST")
          doc.save("resultado.pdf")
        } else {
          doc.text(title, 195, 20, { align: "right" })
          doc.addImage(imgData, 15, 40, 180, 120)
          doc.save("resultado.pdf")
        }
      }
    })
    setModalImprimir(false)
  }

  useEffect(() => {
    if (element && listElement.length) {
      const rangeLabels = listElement.find(e => e.key == element).rangeLabels
      setRangeLabels(rangeLabels)
    }
  }, [element, listElement])

  const handleChangeDepth = (event: any) => {
    setDepth(event.target.value)
  }

  const handleChangeLabelView = (event: any) => {
    setViewLabel(event.target.value)
  }

  const findElementByKey = (key: string) => {
    return listElement.find(listE => listE.key == key)
  }

  useEffect(() => {
    if (MapBounds && map) map.fitBounds(MapBounds)
  }, [MapBounds, map])

  const proccessZone = async () => {
    if (!resultByDepth[depth].length) {
      return
    }

    const zones = resultByDepth[depth].map(e => {
      return {
        zoneResult: listElement.map(ele => {
          const elementAnalyze = findElementByKey(ele.key)
          return {
            key: ele.key,
            name: ele.name,
            value: e[ele.key] == null ? "-" : e[ele.key].toLocaleString("pt-br", { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            // valueNumber: e[ele.key] == null ? 0 : e[ele.key],
            color: getZoneColor(e[ele.key], elementAnalyze.rangeLabels),
          }
        }),
        value: e[element] == null ? -1 : e[element].toLocaleString("pt-br", { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        valueNumber: e[element] == null ? 0 : e[element],
        color: getZoneColor(e[element], findElementByKey(element).rangeLabels),
        area: e.zone.area,
        name: e.zone.name,
        id: e.zone.id,
        center: turf.centerOfMass(turf.polygon(e.zone.polygon.coordinates)),
        coordinatesRaw: e.zone.polygon.coordinates[0].map(coord => {
          return [coord[1], coord[0]]
        }),
        coordinates: e.zone.polygon.coordinates[0].map(coord => {
          return {
            lat: coord[0],
            lng: coord[1],
          }
        }),
      }
    })

    const bounds = new google.maps.LatLngBounds()
    var pointsInter = turf.featureCollection([]) as any

    zones.forEach(element => {
      pointsInter.features.push(turf.point([element.center.geometry.coordinates[1], element.center.geometry.coordinates[0]], { id: element.name, value: element.valueNumber }))
      element.coordinates.forEach(point => {
        bounds.extend(point)
      })
    })

    if (work.field.polygon && (visualization == "interpolation" || visualization == "interpolationHex")) {
      const fieldPolygon = turf.polygon([work.field.polygon.coordinates.map(cc => [cc[1], cc[0]])])
      var ptsWithin = turf.pointsWithinPolygon(pointsInter, fieldPolygon)

      if (pointsInter.features.length != ptsWithin.features.length) {
        setAllowsInterpolation("Talhão não compativel com os pontos informados.")
      } else {
        setAllowsInterpolation("")
        interpolateProccess(fieldPolygon, ptsWithin, Math.sqrt(turf.area(fieldPolygon)) * 0.015, visualization == "interpolation" ? "square" : "hex")
      }
    } else {
      generateGoogleInterpolation(googleMapsSquares)
      setMapBounds(bounds)
      setListZone(zones)
    }
  }

  const interpolateProccess = async (polygonField, pointsInter, gridSize, shape) => {
    const newBbox = bboxAdjustingSquare(turf.bbox(polygonField), 0, false)
    if (newBbox) {
      const [maxY, maxX, minY, minX] = newBbox
      pointsInter.features.push(turf.point([maxY, maxX], { id: "-1", value: 0 }))
      pointsInter.features.push(turf.point([minY, minX], { id: "-2", value: 0 }))
    }

    var options = { gridType: shape, property: "value", units: "meters", weight: 3 } as any
    var grid = turf.interpolate(pointsInter, gridSize, options)

    var squareGridIntersect = [] as any
    var minValue = Number.POSITIVE_INFINITY
    var maxValue = 0

    await Promise.all(
      grid.features.map(async (currentFeature: any) => {
        var intersected = turf.intersect(polygonField, currentFeature) as any
        if (intersected) {
          intersected.properties["value"] = currentFeature.properties["value"]
          squareGridIntersect.push(intersected)

          const propertieValue = currentFeature["properties"]["value"]
          if (propertieValue > maxValue) {
            maxValue = propertieValue
          } else if (propertieValue < minValue) {
            minValue = propertieValue
          }
        }
      })
    )

    setInterpolationMaps({
      min: minValue,
      max: maxValue,
    })

    const newRangelabels = findElementByKey(element).rangeLabels
    let newSquareGridIntersect = squareGridIntersect.map(sqt => {
      const value = sqt["properties"]?.value
      return {
        ...sqt,
        properties: {
          value: value,
          // valueScale: valueScale,
          // color: scaleChroma(valueScale).hex(),
          // color: getZoneColor(value, newRangelabels),
          // color: getZoneColorInterpolation(value, newRangelabels),
          color: getZoneColorInterpolationv1(value, newRangelabels),
          // color: getZoneColorInterpolationV2(value, newRangelabels),
        },
      }
    }) as any
    setInterpolation(newSquareGridIntersect)
  }

  const updateBounds = async coordinates => {
    const bounds = new google.maps.LatLngBounds()

    coordinates.forEach(point => {
      bounds.extend({
        lat: point[0],
        lng: point[1],
      })
    })

    setMapBounds(bounds)
  }

  useEffect(() => {
    if (work["field"]["polygon"]) {
      updateBounds(work["field"]["polygon"]["coordinates"])
    }

    if (!workId || !worksResult.length) {
      return
    }
    const { result } = worksResult.find(wR => wR.workUUID == workId)

    if (!result) {
      return
    }

    const keys = Object.keys(result).sort()
    const findResult = keys.find(e => result[e].length)

    if (findResult) {
      setShowResult(true)
    } else {
      setShowResult(false)
      return
    }

    setListDepth(keys)
    setDepth(keys[0])
    setListShowInfo(Array(result[keys[0]].length).fill(false))
    setResultByDepth(result)
  }, [worksResult, workId])

  const getZoneColor = (value, rangeLabels) => {
    let color = "#000000"
    if (value != null) {
      for (let i = 0; i < rangeLabels.length; i++) {
        if (rangeLabels[i].start <= value) {
          color = rangeLabels[i].color
        }
      }
    }

    return color
  }

  const getZoneColorInterpolation = (value, rangeLabels) => {
    let colorFinal = "#000000" as any
    let interval = 0

    if (value != null) {
      for (let i = 0; i < rangeLabels.length; i++) {
        if (rangeLabels[i].start <= value) {
          interval = i
        }
      }
    }
    let minColor
    let maxColor

    minColor = chroma(rangeLabels[interval].color).luminance(0.2).hex()
    maxColor = chroma(rangeLabels[interval].color).saturate(0.8).hex()

    var scaleChroma = chroma.scale([minColor, maxColor])
    colorFinal = scaleChroma((value - rangeLabels[interval].start) / (rangeLabels[interval].final - rangeLabels[interval].start)).hex()

    return colorFinal
  }

  const getZoneColorInterpolationv1 = (value, rangeLabels) => {
    let colorFinal = "#000000" as any
    let interval = 0

    if (value != null) {
      for (let i = 0; i < rangeLabels.length; i++) {
        if (rangeLabels[i].start <= value) {
          interval = i
        }
      }
    }

    if (interval == rangeLabels.length - 1) {
      var scaleChroma = chroma.scale([rangeLabels[interval].color, chroma(rangeLabels[interval].color).saturate(0.3).hex()])
      colorFinal = scaleChroma((value - rangeLabels[interval].start) / (rangeLabels[interval].final - rangeLabels[interval].start)).hex()
    } else if (interval == 0) {
      var scaleChroma = chroma.scale([rangeLabels[interval].color, rangeLabels[interval + 1].color])
      const minMaxValue = (value - rangeLabels[interval].start) / (rangeLabels[interval].final - rangeLabels[interval].start)
      colorFinal = scaleChroma(minMaxValue).hex()
    } else {
      var scaleChroma = chroma.scale([rangeLabels[interval].color, rangeLabels[interval + 1].color])
      const minMaxValue = (value - rangeLabels[interval].start) / (rangeLabels[interval].final - rangeLabels[interval].start)
      colorFinal = scaleChroma(minMaxValue).hex()
    }

    return colorFinal
  }

  const getZoneColorInterpolationV2 = (value, rangeLabels) => {
    let colorFinal = "#000000" as any
    let interval = 0

    if (value != null) {
      for (let i = 0; i < rangeLabels.length; i++) {
        if (rangeLabels[i].start <= value) {
          interval = i
        }
      }
    }

    if (interval == rangeLabels.length - 1) {
      var scaleChroma = chroma.scale([rangeLabels[interval - 1].color, rangeLabels[interval].color, chroma(rangeLabels[interval].color).saturate(0.3).hex()])
      colorFinal = scaleChroma((value - rangeLabels[interval].start) / (rangeLabels[interval].final - rangeLabels[interval].start)).hex()
    } else if (interval == 0) {
      var scaleChroma = chroma.scale([chroma(rangeLabels[interval].color).luminance(0.3).hex(), rangeLabels[interval].color, rangeLabels[interval + 1].color])
      const minMaxValue = (value - rangeLabels[interval].start) / (rangeLabels[interval].final - rangeLabels[interval].start)
      colorFinal = scaleChroma(minMaxValue).hex()
    } else {
      var scaleChroma = chroma.scale([rangeLabels[interval - 1].color, rangeLabels[interval].color, rangeLabels[interval + 1].color])
      const minMaxValue = (value - rangeLabels[interval].start) / (rangeLabels[interval].final - rangeLabels[interval].start)
      colorFinal = scaleChroma(minMaxValue).hex()
    }

    return colorFinal
  }

  const sendResultFileCsv = async dataCsv => {
    try {
      const sendObj = {
        workUUID: workId,
        csv: dataCsv,
        osUUID: osId,
      }
      setLoadingResult(true)
      await jwtAxios.post("/labResult", sendObj)
      listAllWorksResult()
    } catch (e: any) {
      console.error(e["message"] ? e["message"] : e)
      dispatch(fetchError(e.message))
    } finally {
      setLoadingResult(false)
    }
  }

  const proccessFile = async file => {
    const reader = new FileReader()
    reader.onabort = () => dispatch(fetchError("Upload Abortado"))
    reader.onerror = () => dispatch(fetchError("Upload Error"))
    reader.onload = () => {
      let data = reader.result as string

      try {
        data = data.replaceAll('"', "").replaceAll(" ", "").replaceAll("\r", "").replaceAll(",", ".").toLocaleUpperCase()

        let dataValidate = data
        let keys = dataValidate.split("\n")[0].split("")
        if (keys.length < 2) {
          throw 'A separação dos valores deve ser feita por ""'
        }
        keys.shift()
        keys.shift()

        sendResultFileCsv(data)
      } catch (e) {
        dispatch(fetchError("Erro ao Exportar Arquivo"))
      }
    }
    reader.readAsText(file, "")
  }

  const convertToGeojson = (coordinates, type, properties) => {
    return {
      type: "Feature",
      geometry: {
        type: type,
        coordinates: coordinates,
      },
      properties: properties,
    }
  }

  const exportKML = () => {
    try {
      const geoPolygon = listZone.map(zone =>
        convertToGeojson([zone.coordinatesRaw], "Polygon", {
          fill: zone.color,
          "fill-opacity": 0.6,
          "stroke-width": 2,
          stroke: "#000",
          name: zone.name,
          id: zone.id,
          value: zone.value,
        })
      )

      const featCollection = {
        type: "FeatureCollection",
        features: geoPolygon,
      }

      FileSaver.saveAs(
        new Blob([tokml(featCollection, { documentName: findElementByKey(element).name, simplestyle: true })], {
          type: "application/vnd.google-earth.kml+xml",
        }),
        `resultadoAnalise.kml`
      )
    } catch (e) {
      console.error(element)
    }
  }

  const exportCSV = async () => {
    try {
      setLoadingCsv(true)
      const response = await jwtAxios.get(`/labResult/work/${workId}/csv`, {
        responseType: "blob",
      })

      FileSaver.saveAs(
        new Blob([response.data, { documentName: "result.csv" }], {
          type: "text/csv",
        }),
        `result.csv`
      )
    } catch (e) {
      console.error(element)
    } finally {
      setLoadingCsv(false)
    }
  }

  const arrowInit = map => {
    var churchControlDiv = document.createElement("div") as any
    churchControlDiv.innerHTML = ReactDOM.renderToString(_crapArrow())
    churchControlDiv.style.width = "20%"
    churchControlDiv.style.width = "20%"
    churchControlDiv.style.paddingTop = "10px"

    map.controls[google.maps.ControlPosition.RIGHT_TOP].push(churchControlDiv)
  }

  const _crapArrow = () => (
    <Box width={"40px"} height={"40px"} paddingRight={"5px"} paddingTop={"10px"} style={{ float: "right" }}>
      <img width='100%' src={"/compass.png"}></img>
    </Box>
  )

  const interpolateLabel = () => {
    let colorsLength = rangeLabels.length
    let colorsArray = [] as any
    var scaleChroma = chroma.scale(findElementByKey(element).rangeLabels.map(ranL => ranL.color))

    for (let i = 0; i < colorsLength; i++) {
      colorsArray.push(scaleChroma((1 / (colorsLength - 1)) * i).hex())
    }

    return (
      <Grid item>
        <Grid container direction='column' sx={{ maxWidth: "100px", minWidth: "80px", textAlign: "center" }}>
          <Grid xs={12} item sx={{ padding: 0, paddingBottom: "5px" }}>
            <h6 style={{ fontWeight: "bold" }}>{findElementByKey(element).prettyName}</h6>
          </Grid>

          <Grid item xs={12} sx={{ padding: "0px" }}>
            <Grid container spacing={1} justifyContent='center'>
              <Grid item sx={{ margin: "0px" }}>
                <Box sx={{ width: 30, height: 50, margin: "0px", padding: "0px", background: `linear-gradient(${colorsArray.toReversed().toLocaleString()})` }} />
              </Grid>
              <Grid item>
                <Grid container direction='column' justifyContent='space-between' alignItems='center' sx={{ height: "100%" }}>
                  <Grid item>
                    <h5 style={{ fontWeight: "bold" }}>{areaToLocaleString(interpolationMaps.max, 2, 2)}</h5>
                  </Grid>

                  <Grid item>
                    <h5 style={{ fontWeight: "bold" }}>{areaToLocaleString(interpolationMaps.min, 2, 2)}</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const generateGoogleInterpolation = async removeSquare => {
    removeSquare.forEach(element => element.setMap(null))
  }

  const proccessVisualization = async () => {
    generateGoogleInterpolation(googleMapsSquares)
    let allSquaresInterpolation = [] as any
    interpolation.map((grid, index) => {
      const {
        geometry: { coordinates },
        properties: { value, color },
      } = grid as any

      const positionCoord = coordinates[0].map(coor => {
        return {
          lat: coor[1],
          lng: coor[0],
        }
      })

      let square = new google.maps.Polygon({
        paths: positionCoord,
        fillColor: color,
        fillOpacity: 0.9,
        strokeWeight: 0,
        strokeColor: "black",
        zIndex: 1,
      })

      allSquaresInterpolation.push(square)
      square.setMap(map)
    })

    setGoogleMapsSquares(allSquaresInterpolation)
  }

  useEffect(() => {
    if ((visualization == "interpolation" || visualization == "interpolationHex") && interpolation.length) {
      proccessVisualization()
    }
  }, [visualization, interpolation])

  return (
    <Box sx={{ padding: "0px" }}>
      {_renderModalImprimir()}

      <PlotGraph data={{ modalPlot, setModalPlot, listElement, worksResult }} />

      <div>
        {!loadingResult ? (
          <>
            {showResult ? (
              <>
                <Grid item xs={12} sx={{ padding: "0px !important" }} className='imprimeSize'>
                  {isLoaded && (
                    <GoogleMap
                      key={mapRenderKey}
                      mapContainerStyle={{ width: "100%", height: "93vh" }}
                      options={optionsGoogleMaps}
                      mapTypeId={mapType}
                      zoom={zoomMap}
                      onLoad={map => {
                        arrowInit(map)
                        setMap(map)
                      }}
                      id='imprime'
                    >
                      <>
                        <Box sx={{ position: "relative", width: "70%", float: "right", borderRadius: "5px", mr: "5%", display: !modalImprimir ? "block" : "none" }}>
                          <Grid id='NotPrint' container xs={12} justifyContent='end' alignItems='center' sx={{ padding: "3% 0% 1% 20px" }}>
                            <Grid item sx={{ paddingX: "3px" }}>
                              <FormControl variant='outlined' sx={{ width: "150px" }}>
                                <InputLabel sx={{ backgroundColor: "rgba(255, 255, 255, 0.93)", borderRadius: "3px", padding: "5px" }} shrink={true} id='visualization'>
                                  Visualização
                                </InputLabel>
                                <Select
                                  label={"Visualização"}
                                  sx={{ backgroundColor: "rgba(255, 255, 255, 0.93)" }}
                                  onChange={e => {
                                    setListZone([])
                                    setVisualization(e.target.value)
                                  }}
                                  labelId='select-label'
                                  id='visualization'
                                  value={visualization}
                                  size='small'
                                >
                                  <MenuItem key={"zones"} value={"zones"}>
                                    {"Padrão"}
                                  </MenuItem>
                                  <MenuItem key={"interpolationHex"} value={"interpolationHex"}>
                                    {"Interpolação (hexágono)"}
                                  </MenuItem>
                                  <MenuItem key={"interpolation"} value={"interpolation"}>
                                    {"Interpolação (Grid)"}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item sx={{ paddingX: "3px", paddingY: "4px", minWidth: "100px" }}>
                              <FormControl variant='outlined'>
                                <InputLabel sx={{ backgroundColor: "rgba(255, 255, 255, 0.93)", borderRadius: "3px", padding: "5px" }} shrink={true} id='select-depth'>
                                  {messages["common.depth"] as string}
                                </InputLabel>
                                <Select
                                  style={{ backgroundColor: "rgba(255, 255, 255, 0.93)", minWidth: "100px" }}
                                  label={messages["common.depth"] as string}
                                  onChange={handleChangeDepth}
                                  labelId='select-label'
                                  id='select-depth'
                                  value={depth}
                                  size='small'
                                >
                                  {listDepth.map(e => (
                                    <MenuItem key={e} value={e}>
                                      {e}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item sx={{ paddingX: "3px" }}>
                              <FormControl variant='outlined' sx={{ width: "100%" }}>
                                <InputLabel sx={{ backgroundColor: "rgba(255, 255, 255, 0.93)", borderRadius: "3px", padding: "5px" }} shrink={true} id='select-depth'>
                                  {"Label"}
                                </InputLabel>
                                <Select
                                  label={"Labels"}
                                  sx={{ backgroundColor: "rgba(255, 255, 255, 0.93)" }}
                                  onChange={handleChangeLabelView}
                                  labelId='select-label'
                                  id='select-depth'
                                  value={viewLabel}
                                  size='small'
                                >
                                  <MenuItem key={"valueResult"} value={"valueResult"}>
                                    {"Valor"}
                                  </MenuItem>
                                  <MenuItem key={"zoneId"} value={"zoneId"}>
                                    {"Zona"}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item sx={{ paddingX: "3px" }}>
                              <Button onClick={() => setModalPlot(true)} size='small' variant='contained'>
                                {"Gráfico"}
                              </Button>
                            </Grid>

                            <Grid item sx={{ paddingX: "3px" }}>
                              <Button onClick={() => exportKML()} size='small' variant='contained'>
                                {"Exportar KML"}
                              </Button>
                            </Grid>

                            <Grid item sx={{ paddingX: "3px" }}>
                              <LoadingButton onClick={() => exportCSV()} loading={loadingCsv} size='small' variant='contained'>
                                {"Exportar CSV"}
                              </LoadingButton>
                            </Grid>

                            <Grid item sx={{ paddingX: "3px" }}>
                              <UploadClassic uploadText={messages["result.uploadText"] as string} dropzone={dropzone} />
                              <AppList data={uploadedFile} renderRow={(file, index) => <FileRow key={index + file.path} file={file} onDeleteUploadFile={() => {}} />} />
                            </Grid>

                            <Grid item sx={{ paddingX: "3px" }}>
                              <Button
                                color='primary'
                                onClick={() => {
                                  setModalImprimir(true)
                                }}
                                size='small'
                                variant='contained'
                              >
                                {"Imprimir"}
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          sx={{
                            backgroundColor: "rgba(255, 255, 255, 0.93)",
                            display: !modalImprimir ? "block" : "none",
                            position: "absolute",
                            borderRadius: "10px",
                            marginLeft: "10px",
                            marginTop: "20px",
                            height: "89vh",
                          }}
                        >
                          <Tabs
                            sx={{ borderColor: "divider", maxWidth: "200px", paddingRight: 2, maxHeight: "100%", padding: "0px" }}
                            value={elementPosition}
                            orientation='vertical'
                            variant='scrollable'
                            textColor='primary'
                            centered={false}
                            scrollButtons
                            onChange={(element, idValue: number) => {
                              setElement(newListElements[idValue]['key'])
                              setElementPosition(idValue)
                            }}
                          >
                            { 
                              newListElements.map( el => {
                                return <Tab id={el.key} label={el.label} sx={el.sx} />
                              })
                            }
                          </Tabs>
                        </Box>

                        <Grid container alignItems='end' style={{ position: "fixed", width: "fit-content", bottom: 10, right: 15 }}>
                          <Grid item>
                            <Card style={{ borderRadius: "10px" }}>
                              <CardContent style={{ backgroundColor: "rgba(255, 255, 255, 0.93)", padding: 5 }}>
                                <Grid container xs={12} direction='column' rowSpacing={1}>
                                  {
                                    <>
                                      {rangeLabels.map(rl => {
                                        return (
                                          <Grid item>
                                            <Grid container spacing={2}>
                                              <Grid item>
                                                <FaSquareFull color={rl.color} />
                                              </Grid>

                                              <Grid item>
                                                <Typography fontSize='12px' fontWeight={1000}>
                                                  {rl.start.toLocaleString()}-{rl.final.toLocaleString()} {rl.label.name}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        )
                                      })}
                                    </>
                                  }
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </>
                      {/* 
                            <Polygon
                              key={1}
                              path={rectangleCoordinates}
                              options={{
                                fillColor: 'red',
                                fillOpacity: 0,
                                strokeWeight: 1,
                                strokeColor: 'black',
                                zIndex: 1
                              }}
                            /> */}

                      {visualization != "interpolation" && (
                        <>
                          {listZone.map((poly, index) => {
                            return (
                              <>
                                <Polygon
                                  key={poly.id}
                                  path={poly.coordinates}
                                  onClick={() => {
                                    let newArray = [...listShowInfo]
                                    newArray[index] = true
                                    setListShowInfo(newArray)
                                  }}
                                  options={{
                                    fillColor: poly.color,
                                    fillOpacity: 1,
                                    strokeWeight: 2,
                                    strokeColor: "black",
                                    zIndex: 1,
                                  }}
                                />

                                {listShowInfo[index] && (
                                  <>
                                    <InfoWindow
                                      onCloseClick={() => {
                                        let newArray = [...listShowInfo]
                                        newArray[index] = false
                                        setListShowInfo(newArray)
                                      }}
                                      position={{
                                        lat: poly.center.geometry.coordinates[0],
                                        lng: poly.center.geometry.coordinates[1],
                                      }}
                                    >
                                      <div style={{ width: "400px", fontFamily: "sans-serif" }}>
                                        <div style={{ width: "50%", display: "inline-table" }}>
                                          <h5 style={{ fontWeight: 800, width: "90%", margin: "auto" }}>
                                            {messages["common.zona"]}: {poly.name} ({depth})
                                          </h5>
                                        </div>
                                        {poly.area && (
                                          <>
                                            <div style={{ width: "50%", display: "inline-table" }}>
                                              <h5 style={{ fontWeight: 800, width: "90%", margin: "auto" }}>
                                                {messages["common.area"]}: {poly.area.toLocaleString()} ha
                                              </h5>
                                            </div>
                                          </>
                                        )}
                                        <br />
                                        <br />
                                        {poly.zoneResult.map(res => {
                                          const partialName = res.name.replace("dm-3", "dm³").replaceAll("Res", "").replaceAll("V%", "%")
                                          let textArray = partialName.split("(")
                                          return (
                                            <div
                                              style={{
                                                width: "50%",
                                                display: "inline-table",
                                                padding: "1%",
                                                backgroundColor: res.key == element ? "rgb(0, 128, 13, 0.2)" : "transparent",
                                              }}
                                            >
                                              <div style={{ display: "inline-table", padding: "1%" }}>
                                                <FaSquareFull color={res.color} />
                                              </div>
                                              <div style={{ display: "inline-table", padding: "1%" }}>
                                                <h6 style={{ margin: "auto", fontWeight: res.key == element ? "800" : "300" }}>{textArray[0]}: </h6>
                                              </div>
                                              <div style={{ display: "inline-table", padding: "1%" }}>
                                                <h6 style={{ margin: "auto", fontWeight: res.key == element ? "800" : "300" }}>
                                                  {" "}
                                                  {res.value == "-" ? "-" : `${res.value} ${textArray[1] ? textArray[1].replace(")", "") : ""}`}{" "}
                                                </h6>
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </InfoWindow>
                                  </>
                                )}

                                <Marker
                                  options={{ collisionBehavior: google.maps.CollisionBehavior.REQUIRED }}
                                  position={{
                                    lat: poly.center.geometry.coordinates[0],
                                    lng: poly.center.geometry.coordinates[1],
                                  }}
                                  label={{
                                    color: "rgb(255, 255, 255)",
                                    fontWeight: "1000px",
                                    fontSize: "15px",
                                    className: "textStroke",
                                    text: viewLabel == "zoneId" ? poly.name : poly.value < 0 ? "0" : `${poly.value}`,
                                  }}
                                  draggable={true}
                                  zIndex={0}
                                  icon={{
                                    path: google.maps.SymbolPath.CIRCLE,
                                    fillOpacity: 0,
                                    strokeOpacity: 0,
                                  }}
                                />
                              </>
                            )
                          })}
                        </>
                      )}
                    </GoogleMap>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} id='NotPrint'>
                  <UploadModern uploadText={messages["result.uploadText"] as string} dropzone={dropzone} />
                  <AppList data={uploadedFile} renderRow={(file, index) => <FileRow key={index + file.path} file={file} onDeleteUploadFile={() => {}} />} />
                </Grid>
              </>
            )}
          </>
        ) : (
          <>
            <Grid id='NotPrint' justifyContent='center' sx={{ padding: "10%" }} alignItems='center' direction='row' container>
              <CircularProgress />
            </Grid>
          </>
        )}
      </div>
    </Box>
  )
}

export default WorkResults
