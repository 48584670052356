import AppContentViewWrapper from "@crema/core/AppContentView/AppContentViewWrapper"
import BitBucketContainer from "../BitBucket/BitBucketContainer"
import BitBucketWrapper from "../BitBucket/BitBucketWrapper"
import AppThemeSetting from "../../AppThemeSetting"
import AppInfoView from "@crema/core/AppInfoView"
import AppSidebar from "../BitBucket/AppSidebar"
import AppFooter from "../components/AppFooter"
import React, { useState } from "react"
import Box from "@mui/material/Box"
import clsx from "clsx"


const LayoutPadrao: React.FC<any> = ({ children, verticalNav, sxBox }) => {
  const [isCollapsed, setCollapsed] = useState(false)
  return (
    <BitBucketContainer
      className={clsx({
        boxedLayout: false,
        framedLayout: false,
      })}
    >
      <BitBucketWrapper
        className={clsx("bitBucketWrapper", {
          bitBucketCollapsed: isCollapsed,
        })}
      >
        <AppSidebar verticalNav={verticalNav} isCollapsed={isCollapsed} setCollapsed={setCollapsed} />
        <Box className="mainContent">
          <AppContentViewWrapper>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                p: { xs: 5, md: 5, xl: 5 },
                ...sxBox
              }}
              className="app-content"
            >
              {children}
            </Box>
            <AppFooter />
          </AppContentViewWrapper>
        </Box>
        <AppThemeSetting />
      </BitBucketWrapper>
      <AppInfoView/>
    </BitBucketContainer>
  )
}

export default LayoutPadrao