import React from "react"
import { samplePagesConfigsOrderServiceFull } from "./orderService"
import { samplePagesConfigsPropety } from "./PropertyFIldOs"
import { laboratoriesPagesRoutes } from "./laboratories"
import { initialUrl } from "shared/constants/AppConst"
import { errorPagesConfigs } from "./errorPages"
import { samplePagesConfigs } from "./Clients"
import { samplePagesHarvest } from "./safras"
import Error403 from "./errorPages/Error403"
import { UsersPagesRoutes } from "./users"
import { authRouteConfig } from "./auth"
import { profilePage } from "./profile"
import { configPage } from "./config"
import { osPagesRoutes } from "./os"

const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...samplePagesConfigsOrderServiceFull,
    ...samplePagesConfigsPropety,
    ...laboratoriesPagesRoutes,
    ...samplePagesConfigs,
    ...samplePagesHarvest,
    ...UsersPagesRoutes, 
    ...osPagesRoutes,    
    ...profilePage,
    ...configPage,
  ],
}

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
}

const anonymousStructure = {
  routes: errorPagesConfigs,
}

export { authorizedStructure, unAuthorizedStructure, anonymousStructure }
