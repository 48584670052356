import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import React from "react"


interface UploadClassicProps {
  uploadText: string;
  dropzone: any;
  color?: any;
}

const UploadClassic: React.FC<UploadClassicProps> = ({
  uploadText,
  dropzone,
  color= 'primary'
}) => {
  return (
    <Box sx={{ width: 'fit-content' }} {...dropzone.getRootProps({ className: "dropzone" })} >
      <input {...dropzone.getInputProps()} />
      <Button size="small" variant="contained" color={color} >{uploadText}</Button>
    </Box>
  )
}

export default UploadClassic
