import { Box, List, ListItemText, Grid, Button, Typography, ListItemButton, Tabs, Tab, CircularProgress, Skeleton } from "@mui/material"
import VerticalNavItem from '@crema/core/AppLayout/components/VerticalNav/VerticalItem/VerticalNavItem'
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import { useQuery } from "../../../@crema/utility/helper/Utils"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import LayoutPadrao from '@crema/core/AppLayout/LayoutPadrao'
import jwtAxios from "../../../@crema/services/auth/jwt-auth"
import GoogleMap from "../../../shared/components/googleMap"
import RecommendationsPage from "../../os/recommendations"
import { useNavigate, useParams } from "react-router-dom"
import DialogNewWork from "../modalNewWork/modalNewWork"
import { fetchError } from "../../../redux/actions"
import { Fonts } from "shared/constants/AppEnums"
import DetailsOrderServiceFull from "../details"
import { AppNavLink } from "../../../@crema"
import { useEffect, useState } from "react"
import WorkResults from "../../os/results"
import { useDispatch } from "react-redux"
import { LoadingButton } from "@mui/lab"
import { useIntl } from "react-intl"


interface VerticalItemProps {
  url?: string
  text?: string
  clientName?: string
}

const VerticalItemCliente: React.FC<VerticalItemProps> = ({ url, text }) => {
  return (
    <VerticalNavItem
      level={1}
      button
      to={url}
      component={AppNavLink}
      activeClassName="active"
      exact={"true"}
    >
      <ListItemText
        className="nav-item-content"
        primary={text}
        classes={{ primary: "nav-item-text" }}
        primaryTypographyProps={{
          style: {
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }
        }}
      />
    </VerticalNavItem>
  )
}

const VerticalNav = (osData) => {
  return (
    <List
      id='NotPrint'
      sx={{ position: "relative", padding: 0 }}
      component="div">
      {
        osData && osData.client && <>
          <VerticalItemCliente text={`${osData.client.name}`} url={`/clientes/${osData.client.uuid}/fazendas`} />
        </>
      }

      {
        osData && osData.properties && <>
          <VerticalItemCliente text={osData.properties.name} url={`/fazenda/${osData.properties.uuid}`} />
        </>
      }
      <VerticalItemCliente text={`Ordem de Serviço #${osData.id}`} url={`/ordem-servico/${osData.uuid}`} />

    </List>
  )
}

const _renderVerticalNav = (osData) => VerticalNav(osData)

const OrderServiceFull = (props) => {

  let { orderId } = useParams()
  const { messages } = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  var query = useQuery()

  const [orderServiceStatusKey, setOrderServiceStatusKey] = useState(null) as any
  const [propertiesCoodinates, setPropertiesCoodinates] = useState([]) as any
  const [selectedFildID, setSelectedFildID] = useState(null) as any
  const [selectedWork, setSelectedWork] = useState(null) as any
  const [monitorsTypes, setMonitorsTypes] = useState([]) as any
  const [orderService, setOrderService] = useState({}) as any
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0) as any
  const [subMenuOpened, setSubMenuOpened] = useState(true)
  const [loadingWorks, setLoadingWorks] = useState(false)
  const [modalNewWork, setModalNewWork] = useState(false)
  const [edtDisable, setEdtDisable] = useState(false)
  const [fields, setFields] = useState([]) as any
  const [works, setWorks] = useState([]) as any
  const [loading, setLoading] = useState(false)


  //result
  const [loadingResult, setLoadingResult] = useState(false)
  const [listElement, setListElement] = useState([]) as any
  const [worksResult, setWorksResult] = useState([]) as any

  // New Work
  const initValuesEmpty = {
    name: '' ,
    workUUID: '',
    qtyControlSample: 1,
    laboratoryUUID: "",
    analysisDepth: [],
    fildUUID: "",
    typeName: "",
    typeId: ""
  }
  const [technicalTeam, setTechnicalTeam] = useState([])
  const [laboratory, setLaboratory] = useState([]) as any
  const [osStatus, setOsStatus] = useState([]) as any
  const [listPropertiesFields, setListPropertiesFields] = useState([]) as any
  const [initialValuesNewWork, setInitialValuesNewWork] = useState(initValuesEmpty)

  const [loadingRecommendations, setLoadingRecommendations] = useState(false)
  const [recommendations, setRecommendations] = useState([]) as any

  const [loadingMethodology, setLoadingMethodology] = useState(false)
  const [methodology, setMethodology] = useState([]) as any

  const [loadingNewApplication, setLoadingNewApplication] = useState(false)
  const [newApplicationTypes, setNewApplicationTypes] = useState([]) as any

  useEffect(() => {
    getMonitors()
    if (orderId) {
      getProperties()
      listAllWorksResult()
    }
    
    getTypesRecommendations()
    getTypesMethodology()
    getNewApplication()
    getTechnicalTeam()
    getLaboratories()
    getElements()
    getOsStatus()
  }, [])

  const getNewApplication = async () => {
    try {
      setLoadingNewApplication(true)
      let { data } = await jwtAxios.get(`/types/new-application`)
      setNewApplicationTypes(data)

    } catch (error) {
      console.error(error)

    } finally {
      setLoadingNewApplication(false)
    }
  }

  const getTypesMethodology = async () => {
    try {
      setLoadingMethodology(true)
      let { data } = await jwtAxios.get(`/types/methodology`)
      setMethodology(data)

    } catch (error) {
      console.error(error)

    } finally {
      setLoadingMethodology(false)

    }
  }

  const getTypesRecommendations = async () => {
    try {
      setLoadingRecommendations(true)
      let { data } = await jwtAxios.get(`/types/recommendations`)
      setRecommendations(data)

    } catch (error) {

    } finally {
      setLoadingRecommendations(false)
    }
  }

  const getOsStatus = async () => {
    try {
      const { data } = await jwtAxios.get(`/types/os-edtable`)
      setOsStatus(data)

    } catch (e: any) {
      console.error(e)
    }
  }

  const getElements = async () => {
    try {
      const { data } = await jwtAxios.get(`/types/analyzed-elements`)
      setListElement(data)

    } catch (e: any) {
      console.error(e)
    }
  }

  const getMonitors = async () => {
    try {
      const { data } = await jwtAxios.get(`/types/monitors`)
      setMonitorsTypes(data)

    } catch (e: any) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (query.get('page')) navigateSelTab()
  }, [query])

  const navigateSelTab = () => {
    if (query.get('page') == 'fazenda') setSelectedTab(0)
    if (query.get('page') == 'resultados') setSelectedTab(1)
    if (query.get('page') == 'recomendacoes') setSelectedTab(2)
    if (query.get('page') == 'detalhes') setSelectedTab(3)
  }

  const getProperties = async (loadingControl = true) => {
    try {
      setLoading(loadingControl)
      let { data } = await jwtAxios.get(`/v2/orderService/${orderId}`)
      getFieldsByProperties(data.properties.uuid)
      setFields(data.properties.fields)
      setOrderService(data)
      setWorks(data.work)
      setOrderServiceStatusKey(data.status.key)
      // setEdtDisable(data.status.key != 'create')

      if (data.work.length) {
        setSelectedWork(data.work[0])
        setSelectedFildID(data.work[0].field.id)
      }

      if (data.properties.polygon) setPropertiesCoodinates(data.properties.polygon.coordinates)

    } catch (e) {
      console.log(e)

    } finally {
      setLoading(false)
    }
  }

  const listAllWorksResult = async () => {
    setLoadingResult(true)
    try {
      const { data } = await jwtAxios.get(`/labResult/os/${orderId}`)
      setWorksResult(data)
    } catch (e: any) {
      console.log(e)

    } finally {
      setLoadingResult(false)
    }
  }

  const updateWorks = async () => {
    try {
      setLoadingWorks(true)
      listAllWorksResult()
      let { data } = await jwtAxios.get(`/v2/orderService/${orderId}`)
      setWorks(data.work)

      const updWorkSelect = data.work.find(w => w.uuid == selectedWork.uuid)
      setSelectedWork(updWorkSelect)
      setSelectedFildID(updWorkSelect.field.id)

    } catch (error) {
      console.log(error)

    } finally {
      setLoadingWorks(false)

    }
  }

  const sendOSToCollectionProccess = async () => {
    try {
      setLoadingStatus(true)
      let { data } = await jwtAxios.patch(`/orderService/${orderId}/to-collection`)
      setOrderServiceStatusKey(data.key)
      // setEdtDisable(true)
    } catch (error) {
      console.log(error)
      setLoadingStatus(false)
    }
  }

  const getLaboratories = async () => {
    try {
      let { data } = await jwtAxios.get(`/laboratories?limit=0`)
      setLaboratory(data)
    } catch (e: any) {
      dispatch(fetchError(e.message))
    }
  }

  const getFieldsByProperties = async (propertyUUID) => {
    try {
      let { data } = await jwtAxios.get(`/properties/${propertyUUID}`)
      setListPropertiesFields(data.fields)
    } catch (e: any) {
      dispatch(fetchError(e.message))
    }
  }

  const getTechnicalTeam = async () => {
    try {
      let { data } = await jwtAxios.get(`/users/technical-team`)
      setTechnicalTeam(data)

    } catch (e: any) {
      dispatch(fetchError(e.message))
    }
  }

  return <>
    {
      !loading && orderService ? <>
        <LayoutPadrao sxBox={{
          "& .bit-bucket-btn": {
            position: "absolute",
            top: 20,
            marginLeft: "-12px",
            borderRadius: "50%",
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
            cursor: "pointer",
            zIndex: 99,
            display: { xs: "none", lg: "block" },
            "& svg": { display: "block" },
          },
          background: "white",
          p: { xs: 0, md: 0, xl: 0 },
        }} verticalNav={_renderVerticalNav(orderService)}>

          <DialogNewWork
            data={{
              orderService: orderService,
              propertiesCoodinates,
              setInitialValuesNewWork,
              setOrderService,
              setLoadingWorks,
              setModalNewWork,
              updateWorks,
              initialValuesNewWork,
              listPropertiesFields,
              setSelectedWork,
              technicalTeam,
              modalNewWork,
              edtDisable,
              laboratory,
              orderId,
            }}
          />
          <Grid direction="row"
            justifyContent="start"
            sx={{ height: "100%" }}
            alignItems="stretch"
            container>

            <Box sx={{ width: '100%' }}>
              <Grid direction="row"
                justifyContent="end"
                sx={{ height: "100%" }}
                alignItems="stretch"
                container
              >
                <Grid id='NotPrint' container direction="column" justifyContent="start" md={3} xs={4} className={subMenuOpened ? "" : "subCollapsed"} sx={{ height: "100%", display: subMenuOpened ? "flex" : "none", mt: 0, displayPrint: 'none' }}>
                  <Grid id='NotPrint' item style={{ height: '7vh' }}>
                    <Typography variant="h2" component="h2" align={"center"} sx={{
                      color: "#00800D",
                      fontSize: 18,
                      fontWeight: Fonts.MEDIUM,
                      pt: 3,
                    }}>
                      {`Ordem de Serviço #${orderService.id}`}
                    </Typography>
                  </Grid>

                  <Grid id='NotPrint' item sx={{ height: '80vh', maxHeight: '80vh', overflow: 'auto' }}>
                    
                    <Typography variant="h5" component="h5" align={"left"} padding={'5%'}>
                      Análises:
                    </Typography>

                    <Grid item md={12} xs={1}>
                      {
                        !loadingWorks ? <>
                          {
                            works.map((item) => {
                              return (
                                <>
                                  <ListItemButton
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                    selected={selectedWork && selectedWork.uuid === item.uuid}
                                  >
                                    <ListItemText
                                      style={{ paddingTop: '3%', paddingBottom: '3%' }}
                                      key={item.uuid}
                                      primary={`${item.id} - ${item['name'] ? item['name'] : item.field.name}`}
                                      onClick={() => {
                                        setSelectedWork(item)
                                        setSelectedFildID(item.field.id)
                                      }}
                                    />
                                  </ListItemButton>
                                </>
                              )
                            })
                          }
                        </> : <>
                          <Box width={'90%'}>
                            <Skeleton animation="wave" height={50} />
                            {
                              works.map(w => {
                                return <Skeleton animation="wave" height={50} />
                              })
                            }
                          </Box>
                        </>
                      }
                    </Grid>
                  </Grid>

                  <Grid item sx={{ height: '13vh' }}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      {
                        orderServiceStatusKey == 'create' && <>
                          <Grid item md={12} xs={24}>
                            <LoadingButton
                              loading={loadingStatus}
                              onClick={() => { sendOSToCollectionProccess() }}
                              sx={{ width: "100%" }}
                            >
                              Enviar para Coleta
                            </LoadingButton>
                          </Grid>
                        </>
                      }

                      <Grid item md={12} xs={24}>
                        <Button
                          disabled={edtDisable}
                          onClick={() => {
                            let newEmptyInitValue = initValuesEmpty
                            if (laboratory.length) {
                              newEmptyInitValue['laboratoryUUID'] = laboratory[0].uuid
                            }
                            if (works.length) {
                              newEmptyInitValue['fildUUID'] = works[0].uuid

                            }
                            setInitialValuesNewWork(newEmptyInitValue)
                            setModalNewWork(true)
                          }}
                          sx={{ width: "100%" }}
                        >
                          Nova Análise
                        </Button>
                      </Grid>

                    </Grid>
                  </Grid>

                </Grid>
                <Grid md={subMenuOpened ? 9 : 11.3} xs={4} sx={{ borderLeft: "1px solid #c6c6c6", height: "auto", pl: 0, mt: 0 }}>
                  <Box
                    sx={{ displayPrint: 'none' }}
                    className="bit-bucket-btn"
                    onClick={() => { setSubMenuOpened(!subMenuOpened) }}
                  >
                    { subMenuOpened ? <NavigateBeforeIcon /> : <NavigateNextIcon /> }
                  </Box>

                  <Box
                    id='NotPrint'
                    display="flex"
                    justifyContent="center"
                    alignItems="start"
                    sx={{ height: '7vh', }}
                  >
                    <Tabs value={selectedTab} >
                      <Tab label="Fazenda" onClick={() => navigate(`/ordem-servico/${orderId}?page=fazenda`)} />
                      <Tab label="Resultados" disabled={orderServiceStatusKey == 'create'} onClick={() => navigate(`/ordem-servico/${orderId}?page=resultados`)} />
                      <Tab label="Recomendações" disabled={orderServiceStatusKey == 'create'} onClick={() => navigate(`/ordem-servico/${orderId}?page=recomendacoes`)} />
                      <Tab label="Detalhes" disabled={works.length > 0 ? false : true} onClick={() => navigate(`/ordem-servico/${orderId}?page=detalhes`)} />
                    </Tabs>
                  </Box>

                  <Box
                    justifyContent="center"
                    alignItems="start"
                    sx={{ height: '93vh', overflow: "auto" }}
                  >
                    {
                      selectedTab === 0 && <>
                        <GoogleMap data={{
                          mapContainerStyle: {
                            width: "98%",
                            height: "100%",
                            overflow: 'visible !important',
                            position: 'static !important'
                          },
                          setPropertiesCoodinates,
                          propertiesCoodinates,
                          importFile: false,
                          selectedFildID,
                          edtDisable,
                          fields,
                          works,
                        }} />
                      </>
                    }

                    {
                      selectedTab === 1 && <>
                        <WorkResults data={{
                          listAllWorksResult,
                          setLoadingResult,
                          workId: selectedWork ? selectedWork['uuid'] : '',
                          work: selectedWork,
                          orderInfo: orderService,
                          osId: orderId,
                          loadingResult,
                          worksResult,
                          listElement
                        }} />
                      </>
                    }

                    {
                      selectedTab === 2 && <>
                        <RecommendationsPage data={{
                          workId: selectedWork ? selectedWork['uuid'] : '',
                          work: selectedWork,
                          workResult: worksResult,
                          propertyName: orderService.properties.name,
                          monitorsTypes: monitorsTypes,
                          loadingRecommendations,
                          loadingNewApplication,
                          newApplicationTypes,
                          loadingMethodology,
                          recommendations,
                          osId: orderId,
                          methodology,
                        }} />
                      </>
                    }

                    {
                      selectedTab === 3 && <>
                        <DetailsOrderServiceFull data={{
                          orderService: orderService,
                          setInitialValuesNewWork,
                          setSelectedTab,
                          getProperties,
                          setOrderService,
                          setLoadingWorks,
                          setModalNewWork,
                          setSelectedWork,
                          updateWorks,
                          initialValuesNewWork,
                          listPropertiesFields,
                          technicalTeam,
                          selectedWork,
                          modalNewWork,
                          laboratory,
                          edtDisable,
                          osStatus,
                          orderId,
                        }} />
                      </>
                    }
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </LayoutPadrao>

      </>
        :
        <>
          <Grid
            justifyContent="center"
            alignItems="center"
            direction="row"
            paddingTop={'25%'}
            container
          >
            <CircularProgress />
          </Grid>
        </>
    }
  </>
}


export default OrderServiceFull