import { ClientsModel } from "../../types/models/Clients";
import {
  ClientsActionTypes,
  GET_CLIENTS,
} from "../../types/actions/Clients.action";

const initialState: ClientsModel = {
  clients: [],
  loading: false,
  count: 0
};

const Clients = (state = initialState, action: ClientsActionTypes) => {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload.rows,
        count: action.payload.count

      };

    default:
      return state;
  }
};

export default Clients;
