import {  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@mui/material"
import {
  AUX_APLICATION_NAME,
  areaToLocaleString,
  assemblePolygonsGoogleMaps,
  convertToGeojson,
  idwInterpolation,
  krigingInterpolation,
  krigingInterpolationSimplify,
  localeString,
  simpleAssemblePolygonsGoogleMaps,
} from "../../../shared/utils/utils"
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api"
import { fetchError, fetchSuccess, showMessage } from "../../../redux/actions"
import { useAuthUser } from "../../../@crema/utility/AuthHooks"
import logo from "../../../assets/logos/cooperplanGestao.png"
import jwtAxios from "../../../@crema/services/auth/jwt-auth"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import NewAplication from "./newApplicationmodal"
import Accordion from "@mui/material/Accordion"
import AddIcon from "@mui/icons-material/Add"
import { FaSquareFull } from "react-icons/fa"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { LoadingButton } from "@mui/lab"
import ReactDOM from "react-dom/server"
import html2canvas from "html2canvas"
import { saveAs } from "file-saver"
import * as turf from "@turf/turf"
import ModalMui from "./modalMui"
import jsPDF from "jspdf"
import "./App.css"

const moment = require('moment')

const RecommendationsPage = ({ data }) => {
  const { user } = useAuthUser()
  const dispatch = useDispatch()

  const { loadingRecommendations, loadingNewApplication, newApplicationTypes, loadingMethodology, recommendations, monitorsTypes, propertyName, methodology, workResult, workId, work } = data

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC-5csGO5FhX4qZkTcSgj2rlJc7T4rV1B0",
  })

  const initialValuesSetUp = {
    typeRecomendationId: null,
    formulaVersion: 'v2',
    recommendationId: null,
    typePhosphateId: null,
    methodologyId: null,
    analysisDepth: [],
    disable: false,
    recId: null,
    note: null,
    parameters: {
      efficiencyFactor: 100,
      productivity: 100,
      maintenance: 0,
      considerSurplus: false,
      P: 15,
      "V2%": 70,
      "PRNT%": 77.5,
      round: 3,
      maximumLimit: "",
      disposalLimit: 0,
      minimumLimit: "",
    },
    name: "",
  }
  const preResultZonesSetUp = {
    totalNC: 0,
    zones: [],
  }

  const [zoomMap, setZoomMap] = useState(5)
  const [MapBounds, setMapBounds] = useState(null) as any
  const [map, setMap] = useState(null) as any

  const [workRecomendations, setWorkRecomendations] = useState(false)

  const [filteredMethodology, setFilteredMethodology] = useState([]) as any
  const [viewNewAplication, setViewNewAplication] = useState(false)
  const [openModalView, setModalView] = useState(false)

  const [applicationSelected, setApplicationSelected] = useState() as any

  const [preResultZones, setPreResultZones] = useState(preResultZonesSetUp)
  const [appRecommendations, setAppRecommendations] = useState([]) as any
  const [initialValues, setInitialValues] = useState(initialValuesSetUp)
  const [initialValuesChangeControl, setInitialValuesChangeControl] = useState(initialValuesSetUp)

  const [analysisDepth, setAnalysisDepth] = useState([]) as any
  const [rangeLabels, setRangeLabels] = useState([]) as any

  const [viewLabel, setViewLabel] = useState("valueResult")
  const [labResult, setLabResult] = useState([]) as any
  const [polygonsGoogle, setPolygonsGoogle] = useState([]) as any
  const [labKeys, setKeys] = useState([]) as any
  const [settings, setSettings] = useState(null)

  const [step, setStep] = useState(0)

  const [interpolationResult, setInterpolationResult] = useState([]) as any
  const [modalImprimir, setModalImprimir] = useState(false) as any
  const [modalMonitors, setModalMonitors] = useState(false) as any
  const [monitorSelects, setMonitorSelects] = useState([]) as any
  const [visualization, setVisualization] = useState("standard")
  const [zonesResults, setZonesResult] = useState([]) as any
  const [tipoImpressao, setTipoImpressao] = useState("l")

  const [expandedPanel, setExpandedPanel ] = useState("")

  const handleChange = event => {
    const {
      target: { value },
    } = event
    setMonitorSelects(typeof value === "string" ? value.split(",") : value)
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 400,
      },
    },
  }

  useEffect(() => {
    setInitialValuesChangeControl(initialValues)
  }, [initialValues])

  useEffect(() => {
    getWorkRecomendations()
  }, [work])

  useEffect(() => {
    if (MapBounds && map) map.fitBounds(MapBounds)
  }, [MapBounds, map])

  const updateBounds = async polygons => {
    const bounds = new google.maps.LatLngBounds()
    polygons.forEach(element => {
      element.coordinates.forEach(point => {
        bounds.extend(point)
      })
    })
    setMapBounds(bounds)
  }

  useEffect(() => {
    try {
      generateGoogleInterpolation(polygonsGoogle)
      setZonesResult([])

      if (map) {
        data = work
        const polygons = assemblePolygonsGoogleMaps(data.field.zones, turf)
        updateBounds(polygons)

        if (analysisDepth.length == 0) {
          setAnalysisDepth(
            data.analysisDepth.map(analyse => {
              return {
                key: `${analyse.start}-${analyse.final}`,
                final: analyse.final,
                start: analyse.start,
                id: analyse.id,
                correction: 100,
              }
            })
          )
        }
      }

      if (workResult && workResult.length) {
        const { result } = workResult.find(wR => wR.workUUID == work["uuid"])
        setLabResult(result ? result : [])
      }
    } catch (e: any) {
      console.error(e)
    }
  }, [work, map, workResult])

  // useEffect(() => {
  //   try {
  //     const { result } = workResult.find(wR => wR.workUUID == work["uuid"])
  //     setLabResult(result ? result : [])
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }, [workResult, work])

  useEffect(() => {
    if (monitorsTypes.length) {
      setMonitorSelects(
        monitorsTypes.map(ele => {
          return ele.name
        })
      )
    }
  }, [monitorsTypes])

  const resetModal = async () => {
    setInitialValues({
      ...initialValuesSetUp,
      typePhosphateId: null,
      typeRecomendationId: null,
      recommendationId: null,
      methodologyId: null,
      note: null,
      analysisDepth: analysisDepth,
    })
    setStep(0)
  }

  const _crapArrow = () => (
    <Box width={"40px"} height={"40px"} paddingRight={"5px"} paddingTop={"10px"} style={{ float: "right" }}>
      <img width="100%" src={"/compass.png"}></img>
    </Box>
  )

  useEffect(() => {
    if (recommendations.length && methodology.length && analysisDepth.length) {
      setInitialValues({
        ...initialValues,
        recommendationId: null,
        typePhosphateId: null,
        typeRecomendationId: null,
        methodologyId: null,
        analysisDepth: analysisDepth,
      })
    }
  }, [recommendations, methodology, analysisDepth])

  const handleChangeLabelView = (event: any) => {
    setViewLabel(event.target.value)
  }

  const addInWorkRecomendations = async data => {
    const settings = JSON.parse(JSON.parse(data.settings))
    data["settings"] = settings
    appRecommendations.push(data)
    setAppRecommendations(appRecommendations)
  }

  const updateWorkRecomendations = async (appUpdatedId, data, aplicationId) => {
    try {
      const newAppRecommendations = appRecommendations.map(appRec => {
        var newAppRec = appRec

        if (newAppRec.id == appUpdatedId) {
          newAppRec = data
          const zonesGoogle = simpleAssemblePolygonsGoogleMaps(data.work.field.zones, turf)
          const settings = JSON.parse(JSON.parse(data.settings))
          newAppRec["settings"] = settings
          newAppRec["application"] = data["application"].map((app, index) => {
            const zones = JSON.parse(JSON.parse(app["zones"]))
            const application = {
              ...app,
              zones: zones,
              zonesResults: zonesGoogle.map(zone => {
                const zoneValue = zones.find(pZone => pZone.name == zone.name)
                const value = zoneValue ? (app.average ? zoneValue.recAVG : zoneValue.recTotalNC) : null
                return {
                  ...zone,
                  color: value ? getZoneColor(value, data.recommendations.rangeLabels) : null,
                  value: value,
                }
              }),
            }

            if (app.id == aplicationId) {
              detailsAplicationAux(application, newAppRec, index)
            } else if (index + 1 == data["application"].length) {
              detailsAplicationAux(application, newAppRec, index)
            }

            return application
          })
        }

        return newAppRec
      })
      setAppRecommendations(newAppRecommendations)

      const application = data.application.find(item => item.id == aplicationId)
      let applicationName = AUX_APLICATION_NAME(data, application)
      auxZonesResults(application, data, applicationName)
      setExpandedPanel(data.id)
      setRangeLabels(data.recommendations.rangeLabels)
      
    } catch (e: any) {
      console.log(e)
    }
  }

  const getWorkRecomendations = async () => {
    try {
      setWorkRecomendations(true)
      const { data } = await jwtAxios.get(`/application-recommendation/work/${workId}`)
      const fullData = data.map(rec => {
        const zonesGoogle = simpleAssemblePolygonsGoogleMaps(rec.work.field.zones, turf)
        const settings = JSON.parse(JSON.parse(rec.settings))
        return {
          ...rec,
          settings: settings,
          application: rec["application"].map(app => {
            const zones = JSON.parse(JSON.parse(app["zones"]))
            return {
              ...app,
              zones: zones,
              zonesResults: zonesGoogle.map(zone => {
                const zoneValue = zones.find(pZone => pZone.name == zone.name)
                const value = zoneValue ? (app.average ? zoneValue.recAVG : zoneValue.recTotalNC) : null

                return {
                  ...zone,
                  color: value ? getZoneColor(value, rec.recommendations.rangeLabels) : null,
                  value: value,
                }
              }),
            }
          }),
        }
      })

      setAppRecommendations(fullData)
    } catch (e: any) {
      console.log(e)
    } finally {
      setWorkRecomendations(false)
    }
  }

  const getZoneColor = (value, rangeLabels) => {
    let color = "#000000"

    if (value != "-") {
      for (let i = 0; i < rangeLabels.length; i++) {
        if (rangeLabels[i].start <= value) {
          color = rangeLabels[i].color
        }
      }
    }
    return color
  }

  const modalView = async (view: boolean) => {
    setModalView(view)
  }

  const setStepModal = async (step: number) => {
    setStep(step)
  }

  const arrowInit = map => {
    var churchControlDiv = document.createElement("div") as any
    churchControlDiv.innerHTML = ReactDOM.renderToString(_crapArrow())
    churchControlDiv.style.width = "20%"
    churchControlDiv.style.paddingRight = "1%"

    map.controls[google.maps.ControlPosition.RIGHT_TOP].push(churchControlDiv)
  }
  const _renderModalImprimir = () => {
    // if (map) map.fitBounds(MapBounds)

    return (
      <Dialog
        aria-describedby="alert-dialog-slide-description"
        onClose={() => {
          setModalImprimir(false)
        }}
        open={modalImprimir}
        keepMounted>
        <DialogTitle>{"Configurações da impressão"}</DialogTitle>
        <FormControl fullWidth sx={{ p: 5 }}>
          <InputLabel id="demo-simple-select-label">Tipo de Impressão</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tipoImpressao}
            label="Age"
            onChange={e => {
              setTipoImpressao(e.target.value)
            }}>
            <MenuItem value={"l"}> Paisagem </MenuItem>
            <MenuItem value={"p"}> Normal </MenuItem>
          </Select>
        </FormControl>

        <DialogActions>
          <Button
            onClick={() => {
              imprimir(tipoImpressao)
            }}>
            Imprimir
          </Button>
          <Button
            onClick={() => {
              setModalImprimir(false)
            }}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const _renderModalMonitors = () => {
    return (
      <Dialog
        aria-describedby="alert-dialog-slide-description"
        // onClose={() => { setModalMonitors(false) }}
        open={modalMonitors}
        keepMounted>
        <DialogTitle style={{ textAlign: "center" }}>{"Configuração exportação Monitor"}</DialogTitle>
        <DialogContent style={{ padding: "15px", width: "500px" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-checkbox-label" style={{ backgroundColor: "white" }}>
              Monitores
            </InputLabel>
            <Select
              input={<OutlinedInput label="Chip" />}
              id="demo-multiple-checkbox-label"
              value={monitorSelects}
              multiple
              label="Monitores"
              onChange={handleChange}
              renderValue={selected => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map(value => (
                    <Chip style={{ backgroundColor: "rgba(0, 128, 13, .7)", color: "white" }} label={value} key={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}>
              {monitorsTypes.map((mon: any) => {
                return (
                  <MenuItem key={mon.key} value={mon.name} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <Checkbox checked={monitorSelects.indexOf(mon.name) > -1} />
                    <ListItemText primary={mon.name} />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setModalMonitors(false)
              setMonitorSelects(
                monitorsTypes.map(ele => {
                  return ele.name
                })
              )
            }}>
            Fechar
          </Button>
          <Button
            disabled={monitorSelects.length == 0}
            onClick={() => {
              exportMaquinary()
            }}>
            Baixar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const exportMaquinary = async () => {
    try {
      if (!applicationSelected) throw "NOT_APPLICATION_SELECTED"
      var exportMaquinary = []

      if (visualization == "standard") {
        exportMaquinary = applicationSelected["zonesResults"].map(zone => {
          const coordinatesArray = zone["coordinatesArray"].map(coo => {
            return [coo[1], coo[0]]
          })

          return convertToGeojson([coordinatesArray], "Polygon", {
            value: zone.value,
            name: zone.name,
          })
        })
      } else {
        exportMaquinary = interpolationResult
      }

      let response = await jwtAxios.post(`/application-recommendation/export-machinery`, exportMaquinary, {
        responseType: "blob",
        params: {
          monitors: monitorSelects.join(",").replaceAll(" ", "_").toLocaleUpperCase(),
        },
      })

      saveAs(new Blob([response.data]), "MONITORES.zip")
    } catch (error) {
      console.log(error)

      if (error == "NOT_APPLICATION_SELECTED") {
        dispatch(fetchError("Selecione uma Aplicação"))
      } else {
        dispatch(fetchError("Não foi possivel fazer a exportação"))
      }
    }
  }

  const imprimir = (tipoImpressao: any) => {
    var totalNC = 0
    const auxArea = {}
    applicationSelected["zonesResults"].forEach(zone => {
      auxArea[zone.name] = zone.area
    })

    applicationSelected["zones"].forEach(element => {
      totalNC += element["recTotalNCArea"] ? element["recTotalNCArea"] : auxArea[element["name"]] * element["recTotalNC"]
    })

    // @ts-ignore: Unreachable code error
    html2canvas(document.querySelector("#imprime"), {
      backgroundColor: null,
      useCORS: true,
    }).then(canvas => {
      var imgData = canvas.toDataURL("image/jpeg", 1.0)
      var doc = new jsPDF(tipoImpressao, "mm", [297, 210])
      doc.rect(5, 5, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10, "S")

      var title = `Distribuição dos Componentes de Solo`
      doc.setFontSize(12)

      if (user.enterpriseBanner) {
        var img = new Image()
        img.src = user.enterpriseBanner
        img.onload = function () {
          var widthProportion = 25 * (img.naturalWidth / img.naturalHeight)
          if (tipoImpressao === "l") {
            doc.addImage(user.enterpriseBanner, 10, 15, widthProportion, 25)
            doc.text(title, 287, 20, { align: "right" })
            if (applicationSelected) {
              doc.text(`Propriedade: ${propertyName}`, 287, 25, { align: "right" })
              doc.text(`Aplicação: ${applicationSelected["applicationName"]}`, 287, 30, { align: "right" })
              doc.text(`Quantidade: ${localeString(totalNC)} Kg`, 287, 35, { align: "right" })
            }
            doc.addImage(imgData, 10, 42, 277, 155, "FIT")
            doc.save(`aplicação.pdf`)
          } else {
            doc.addImage(user.enterpriseBanner, 15, 10, widthProportion, 25)
            doc.text(title, 195, 20, { align: "right" })
            if (applicationSelected) {
              doc.text(`Propriedade: ${propertyName}`, 195, 25, { align: "right" })
              doc.text(`Aplicação: ${applicationSelected["applicationName"]}`, 195, 30, { align: "right" })
              doc.text(`Quantidade: ${localeString(totalNC)} Kg`, 195, 35, { align: "right" })
            }
            doc.addImage(imgData, 15, 40, 180, 120, "FIT")
            doc.save("resultado.pdf")
          }
        }
      } else {
        if (tipoImpressao === "l") {
          doc.text(title, 287, 20, { align: "right" })
          if (applicationSelected) {
            doc.text(`Propriedade: ${propertyName}`, 287, 25, { align: "right" })
            doc.text(`Aplicação: ${applicationSelected["applicationName"]}`, 287, 30, { align: "right" })
            doc.text(`Quantidade: ${localeString(totalNC)} Kg`, 287, 35, { align: "right" })
          }
          doc.addImage(imgData, 10, 42, 277, 155, "FIT")
          doc.save(`aplicação.pdf`)
        } else {
          doc.text(title, 195, 20, { align: "right" })
          if (applicationSelected) {
            doc.text(`Propriedade: ${propertyName}`, 195, 25, { align: "right" })
            doc.text(`Aplicação: ${applicationSelected["applicationName"]}`, 195, 30, { align: "right" })
            doc.text(`Quantidade: ${localeString(totalNC)} Kg`, 195, 35, { align: "right" })
          }
          doc.addImage(imgData, 15, 40, 180, 120, "FIT")
          doc.save("resultado.pdf")
        }
      }
    })
    setModalImprimir(false)
  }

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false)
  const [loadingDestroy, setLoadingDestroy] = useState(false)
  const [confirmData, setconfirmData] = useState({
    recomendationId: null,
    applicationId: null,
  })

  const handleClose = () => {
    setconfirmData({
      recomendationId: null,
      applicationId: null,
    })
    setOpenDialogConfirm(false)
  }

  const deleteApplication = async (recApplicationId, applicationId) => {
    try {
      setLoadingDestroy(true)
      await jwtAxios.delete(`/application-recommendation/${recApplicationId}/aplication/${applicationId}`)
      const newAppRecommendations = appRecommendations.map(appRec => {
        if (appRec.id == recApplicationId) {
          const applicationIndex = appRec["application"].findIndex(app => app.id == applicationId)
          appRec["application"].splice(applicationIndex, 1)
        }
        return appRec
      })

      setAppRecommendations(newAppRecommendations)
      dispatch(fetchSuccess())
      dispatch(showMessage("Aplicação Removida"))
      setZonesResult([])
      handleClose()
    } catch (error) {
      console.log(error)
      dispatch(fetchError("Não foi possivel remover a aplicação"))
    } finally {
      setLoadingDestroy(false)
    }
  }

  const deleteRecApplication = async recApplicationId => {
    try {
      setLoadingDestroy(true)
      await jwtAxios.delete(`/application-recommendation/${recApplicationId}`)

      const index = appRecommendations.findIndex(app => app.id == recApplicationId)
      appRecommendations.splice(index, 1)

      setAppRecommendations(appRecommendations)
      dispatch(fetchSuccess())
      dispatch(showMessage("Recomendação Removida"))



      handleClose()
    } catch (error) {
      console.log(error)
      dispatch(fetchError("Não foi possivel remover a recomendação"))
    } finally {
      setLoadingDestroy(false)
    }
  }

  const destroyComponent = async () => {
    if (confirmData.applicationId) {
      await deleteApplication(confirmData.recomendationId, confirmData.applicationId)
    } else if (confirmData.recomendationId) {
      await deleteRecApplication(confirmData.recomendationId)
    }
  }

  const _renderDialogsConfirm = () => {
    return (
      <Dialog open={openDialogConfirm} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>
          <h3>Aviso Importante: Exclusão Irreversível de Dados</h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">Ao prosseguir, todos os dados serão permanentemente excluídos. Clique em continuar para confirmar a ação.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
          <LoadingButton color="warning" loading={loadingDestroy} onClick={() => destroyComponent()}>
            Continuar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    )
  }

  const detailsRecomendationAux = async appRec => {
    const findMethodology = methodology.find(met => met.id == appRec.methodologyId)
    setFilteredMethodology([findMethodology])

    setInitialValues({
      recId: appRec.id,
      formulaVersion: appRec.methodologyId == 86 && moment('2024-03-21T08:26:56-03:00').isAfter(appRec.createdAt) ? 'v1' : 'v2',
      recommendationId: appRec.recommendationsId,
      analysisDepth: appRec.settings.analysisDepth,
      typeRecomendationId: appRec.typeRecomendationId,
      typePhosphateId: appRec.typePhosphateId,
      methodologyId: appRec.methodologyId,
      note: appRec.note,
      disable: appRec["application"].length > 0 ? true : false,
      parameters: {
        efficiencyFactor: appRec.settings.parameters.efficiencyFactor,
        "V2%": appRec.settings.parameters["V2%"],
        P: appRec.settings.parameters["P"],
        productivity: appRec.settings.parameters["productivity"],
        "PRNT%": appRec.settings.parameters["PRNT%"],
        disposalLimit: appRec.settings.parameters.disposalLimit ? appRec.settings.parameters.disposalLimit : 0,
        round: appRec.settings.parameters.round ? appRec.settings.parameters.round : 3,
        maximumLimit: appRec.settings.parameters.maximumLimit,
        minimumLimit: appRec.settings.parameters.minimumLimit,
        considerSurplus: appRec.settings.parameters.considerSurplus,
        maintenance: appRec.settings.parameters.maintenance,
      },
      name: appRec.name,
    })
    setStep(0)
    modalView(true)
  }

  const detailsAplicationAux = (application, appRec, index) => {
    const totalNC = application["zones"].reduce((accumulator, object) => {
      return accumulator + object.totalNC
    }, 0)
    var typeRecomendationId = application.typeRecomendationId
    var typeRecomendation = application.typeRecomendation

    if (application.typePhosphateId || application.typePhosphateId) {
      if (appRec.recommendationsId == 75) {
        typeRecomendationId = application.typePhosphateId
        typeRecomendation = application.typePhosphate
      } else if (appRec.recommendationsId == 73) {
        typeRecomendationId = application.limestoneTypeId
        typeRecomendation = application.limestoneType
      }
    }

    var settings = {
      recommendations: appRec.recommendations,
      ...appRec["settings"],
      ...application,
      typeRecomendationId: typeRecomendationId,
      typeRecomendation: typeRecomendation,
      disable: appRec["application"].length != index + 1,
      preResultZones: {
        totalNC: totalNC,
        zones: application["zones"],
      },
    }
    setSettings(settings)
    setViewNewAplication(true)
  }

  const generateGoogleInterpolation = async removePoligon => {
    removePoligon.forEach(element => element.setMap(null))
  }

  const auxZonesResults = async (application, appRec, applicationName) => {
    setRangeLabels(appRec.recommendations.rangeLabels)
    setApplicationSelected({
      ...application,
      methodology: appRec.methodology.name,
      applicationName: applicationName,
    })

    const zonesResults = application.zonesResults.map(zone => {
      const isInPolygon = turf.booleanPointInPolygon(turf.point(zone.center.geometry.coordinates), turf.polygon([zone.coordinatesArray]))
      return {
        ...zone,
        center: isInPolygon ? zone.center : turf.pointOnFeature(turf.polygon([zone.coordinatesArray])),
        color: zone.color && zone.color != "#000000" ? zone.color : getZoneColor(zone.value, rangeLabels),
      }
    })
    setZonesResult(zonesResults)
    proccessVisualization(zonesResults, appRec.recommendations.rangeLabels)
  }

  useEffect(() => {
    if (visualization && zonesResults.length && rangeLabels.length) proccessVisualization(zonesResults, rangeLabels)
  }, [visualization, zonesResults])

  const proccessVisualization = async (arrayPolygon, newrangeLabels) => {
    let allPolygonsGoogle = [] as any

    generateGoogleInterpolation([...polygonsGoogle])

    if (visualization == "standard") {
      arrayPolygon.map((poly, index) => {
        let newPol = new google.maps.Polygon({
          paths: poly.coordinates,
          fillColor: poly.color,
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "black",
          zIndex: 1,
        })
        allPolygonsGoogle.push(newPol)
        newPol.setMap(map)
      })
    } else {
      var squareGridIntersect = [] as any
      if (visualization == "interpolationKriging") {
        squareGridIntersect = await krigingInterpolation(arrayPolygon, newrangeLabels, work.field.polygon)
      } else if (visualization == "interpolationHex") {
        squareGridIntersect = await idwInterpolation(arrayPolygon, newrangeLabels, work.field.polygon, "square")
      } else {
        squareGridIntersect = await krigingInterpolationSimplify(arrayPolygon, newrangeLabels, work.field.polygon)
      }

      squareGridIntersect.map((poly: any, index) => {
        const coordinates = poly.geometry.coordinates[0].map(coor => {
          return { lat: coor[1], lng: coor[0] }
        })
        let newPol = new google.maps.Polygon({
          paths: coordinates,
          fillColor: poly.properties.color,
          fillOpacity: 1,
          strokeWeight: 0,
          strokeColor: "black",
          zIndex: 1,
        })
        allPolygonsGoogle.push(newPol)
        newPol.setMap(map)
      })
    }

    setInterpolationResult(squareGridIntersect)
    setPolygonsGoogle(allPolygonsGoogle)
  }

  return (
    <Box style={{ height: "100%", margin: "0px", padding: "0px" }}>
      {!loadingNewApplication && !loadingRecommendations && !loadingMethodology && (
        <ModalMui
          data={{
            initialValuesChangeControl,
            updateWorkRecomendations,
            addInWorkRecomendations,
            setFilteredMethodology,
            getWorkRecomendations,
            filteredMethodology,
            setPreResultZones,
            setStepModal,
            modalView,
            labKeys,
            setKeys,
            recommendations,
            preResultZones,
            initialValues,
            openModalView,
            methodology,
            labResult,
            workId,
            step,
          }}
        />
      )}

      {
        <NewAplication
          data={{
            updateWorkRecomendations,
            getWorkRecomendations,
            setViewNewAplication,
            newApplicationTypes,
            viewNewAplication,
            settings,
            workId,
          }}
        />
      }

      <Box style={{ height: "100%", margin: "0px", padding: "0px" }}>
        {_renderModalImprimir()}

        {_renderModalMonitors()}

        {_renderDialogsConfirm()}

        <Grid container style={{ height: "100%" }}>
          {!workRecomendations ? (
            <>
              <Grid item xs={12}>
                {isLoaded && (
                  <GoogleMap
                    id="imprime"
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    onLoad={map => {
                      setMap(map)
                      arrowInit(map)
                    }}
                    zoom={zoomMap}
                    options={{
                      mapTypeId: google.maps.MapTypeId.SATELLITE,
                      zoomControl: false,
                      isFractionalZoomEnabled: true,
                      streetViewControl: false,
                      fullscreenControl: false,
                      mapTypeControl: false,
                      panControl: false,
                    }}>
                    <>
                      <div style={{ display: !modalImprimir ? "block" : "none" }}>
                        <Grid container spacing={0} justifyContent="end" columnSpacing={1} xs={9} paddingTop="15px" paddingRight="55px" style={{ float: "right" }}>
                          <Grid item sx={{ paddingX: "3px" }}>
                            <FormControl variant="outlined" sx={{ width: "150px" }}>
                              <InputLabel
                                id="visualization"
                                shrink={true}
                                sx={{
                                  backgroundColor: "rgba(255, 255, 255, 0.93)",
                                  borderRadius: "3px",
                                  padding: "5px",
                                }}>
                                Visualização
                              </InputLabel>
                              <Select
                                label={"Visualização"}
                                sx={{ backgroundColor: "rgba(255, 255, 255, 0.93)" }}
                                onChange={e => {
                                  // setListZone([])
                                  setVisualization(e.target.value)
                                }}
                                labelId="select-label"
                                id="visualization"
                                value={visualization}
                                size="small">
                                <MenuItem key={"standard"} value={"standard"}>
                                  Padrão
                                </MenuItem>
                                <MenuItem key={"interpolationHex"} value={"interpolationHex"}>
                                  Interpolação IDW
                                </MenuItem>
                                <MenuItem key={"interpolationKriging"} value={"interpolationKriging"}>
                                  Interpolação krigagem
                                </MenuItem>
                                <MenuItem key={"fit"} value={"fit"}>
                                  Interpolação krigagem - Simplify
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item sx={{ paddingX: "3px" }}>
                            <FormControl variant="outlined" sx={{ width: "150px" }}>
                              <InputLabel sx={{ backgroundColor: "rgba(255, 255, 255, 0.93)", padding: "5px 5px 0px 5px", borderRadius: "4px" }} shrink={true} id="select-depth">
                                Label
                              </InputLabel>
                              <Select
                                sx={{ backgroundColor: "rgba(255, 255, 255, 0.93)" }}
                                label={"Labels"}
                                onChange={handleChangeLabelView}
                                labelId="select-label"
                                id="select-depth"
                                value={viewLabel}
                                size="small">
                                <MenuItem key={"zoneId"} value={"zoneId"}>
                                  Zona
                                </MenuItem>
                                <MenuItem key={"valueResult"} value={"valueResult"}>
                                  Valor
                                </MenuItem>
                                <MenuItem key={"none"} value={"none"}>
                                  Nenhum
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <Button
                              size="small"
                              color="primary"
                              onClick={() => {
                                setModalImprimir(true)
                              }}
                              variant="contained">
                              Imprimir
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              size="small"
                              color="primary"
                              onClick={() => {
                                setModalMonitors(true)
                              }}
                              variant="contained">
                              Exportar Monitor
                            </Button>
                          </Grid>
                        </Grid>

                        <div style={{ display: !modalImprimir ? "block" : "none" }}>
                          <Grid
                            item
                            xs={3}
                            overflow="hidden"
                            style={{
                              position: "relative",
                              borderRadius: "5px",
                              maxHeight: "89vh",
                              maxWidth: "28%",
                              paddingLeft: "15px",
                              paddingTop: "10px",
                              paddingBottom: "15px",
                              marginLeft: "10px",
                              marginTop: "10px",
                              backgroundColor: "rgba(255, 255, 255, 0.9)",
                            }}>
                            <Grid container spacing={2} columnSpacing={2} xs={12} padding="1%" maxHeight={"89vh"} overflow="auto">
                              <Grid item xs={12} sx={{ border: "10px" }}>
                                <Fab
                                  aria-label="add"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    resetModal()
                                    modalView(true)
                                  }}>
                                  <AddIcon />
                                </Fab>
                              </Grid>

                              <Grid container spacing={1} xs={12} columnSpacing={1}>
                                <Grid item xs={12} marginTop="5%">
                                  {appRecommendations.map(appRec => {
                                    return (
                                      <>
                                        <Accordion
                                          square={true}
                                          sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                                          expanded={expandedPanel === appRec.id}
                                          onChange={(_, isExpanded: boolean) => { 
                                            setExpandedPanel(isExpanded ? appRec.id : false )
                                            setRangeLabels(appRec.recommendations.rangeLabels)
                                          }}>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", zIndex: 0 }}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header">
                                            <>
                                              <Grid container alignItems="center">
                                                <Grid item xs={10}>
                                                  <Typography>{`${appRec.name} - ${appRec.recommendations.name}`}</Typography>
                                                  <Link component="button" style={{ marginRight: "5px", zIndex: 1 }} variant="body2" onClick={() => detailsRecomendationAux(appRec)}>
                                                    {`Detalhes`}
                                                  </Link>

                                                  <Link
                                                    style={{
                                                      zIndex: 1,
                                                      color: appRec["application"].length == 0 ? "red" : "gray",
                                                      textDecorationColor: appRec["application"].length == 0 ? "red" : "gray",
                                                    }}
                                                    component="button"
                                                    disabled={appRec["application"].length != 0}
                                                    variant="body2"
                                                    onClick={() => {
                                                      setconfirmData({ recomendationId: appRec["id"], applicationId: null })
                                                      setOpenDialogConfirm(true)
                                                    }}>
                                                    {`Excluir`}
                                                  </Link>
                                                </Grid>
                                                <Grid item xs={2} alignItems="center">
                                                  <Tooltip title="Adicionar Aplicação">
                                                    <IconButton
                                                      component="label"
                                                      color="primary"
                                                      size="small"
                                                      onClick={() => {
                                                        const typeRecomendationId = newApplicationTypes.find(appType => appType.id == appRec.recommendationsId)
                                                        var settings = {
                                                          ...appRec["settings"],
                                                          recommendations: appRec.recommendations,
                                                          notes: [],
                                                          disable: false,
                                                          average: false,
                                                          considerSurplus: false,
                                                          zeroBelowMinimum: false,
                                                          additionalRecommendation: 0,
                                                          disposalLimit: 0,
                                                          round: typeRecomendationId["key"] === 'liming' ? 1 : typeRecomendationId["key"] === 'phosphating' ? 2 :  3,
                                                          maintenance: 0,
                                                          applicationRecommendationId: appRec.id,
                                                          typeRecomendationId: typeRecomendationId ? typeRecomendationId.applicationProducts[0].id : [],
                                                        }

                                                        if (appRec["application"].length) {
                                                          const lastAplication = appRec["application"].at(-1)
                                                          settings["typeRecomendationId"] = typeRecomendationId ? typeRecomendationId.applicationProducts[0].id : []
                                                          var zones = lastAplication["zones"]
                                                          var ncTotal = 0

                                                          zones = zones.map(zone => {
                                                            const totalNC = lastAplication.average ? zone.missingAVG : zone.missingT
                                                            ncTotal = ncTotal + (totalNC > 0 ? totalNC : 0)
                                                            return {
                                                              missingAVG: 0,
                                                              area: zone.area,
                                                              exedente: zone.exedente,
                                                              missingT: 0,
                                                              name: zone.name,
                                                              recAVG: 0,
                                                              recTotalNC: 0,
                                                              totalNC: totalNC > 0 ? totalNC : 0,
                                                            }
                                                          })
                                                          settings["preResultZones"]["totalNC"] = ncTotal
                                                          settings["preResultZones"]["zones"] = zones
                                                        }
                                                        setSettings(settings)
                                                        setViewNewAplication(true)
                                                      }}>
                                                      <AddIcon />
                                                    </IconButton>
                                                  </Tooltip>
                                                </Grid>
                                              </Grid>
                                            </>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <List component="div" disablePadding>
                                              {appRec["application"].map((application, index) => {
                                                let applicationName = AUX_APLICATION_NAME(appRec, application)

                                                return (
                                                  <>
                                                    <ListItemButton
                                                      style={{
                                                        zIndex: 0,
                                                        backgroundColor: applicationSelected && application && applicationSelected["id"] == application["id"] ? "rgb(0, 128, 13, 0.1)" : "transparent",
                                                      }}
                                                      onClick={() => auxZonesResults(application, appRec, applicationName) }>
                                                      <Grid container>
                                                        <Grid item xs={12}>
                                                          <ListItemText primary={`Aplicação ${index + 1}: ${applicationName}`} />
                                                        </Grid>

                                                        <Grid item xs={12} marginTop="-2%">
                                                          <Link
                                                            style={{ paddingRight: "5px", zIndex: 1 }}
                                                            component="button"
                                                            variant="body2"
                                                            onClick={() => {
                                                              detailsAplicationAux(application, appRec, index)
                                                            }}>
                                                            {`Detalhes`}
                                                          </Link>

                                                          {appRec["application"].length == index + 1 && (
                                                            <>
                                                              <Link
                                                                style={{ color: "red", textDecorationColor: "red", zIndex: 1 }}
                                                                component="button"
                                                                variant="body2"
                                                                onClick={() => {
                                                                  setconfirmData({
                                                                    applicationId: application["id"],
                                                                    recomendationId: appRec["id"],
                                                                  })
                                                                  setOpenDialogConfirm(true)
                                                                }}>
                                                                {`Excluir`}
                                                              </Link>
                                                            </>
                                                          )}
                                                        </Grid>
                                                      </Grid>
                                                    </ListItemButton>
                                                  </>
                                                )
                                              })}
                                            </List>
                                          </AccordionDetails>
                                        </Accordion>
                                      </>
                                    )
                                  })}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </div>

                      {rangeLabels && (
                        <>
                          <Grid container alignItems="end" style={{ position: "fixed", width: "fit-content", bottom: 10, right: 15 }}>
                            <Grid item>
                              <Card style={{ padding: "0%", borderRadius: "10px" }}>
                                {/* <CardContent style={{ backgroundColor: 'rgba(255, 255, 255, 0.93)', padding: '10px', borderRadius: '5px' }}> */}
                                <CardContent style={{ padding: "10px" }}>
                                  <Grid container xs={12} direction="column" rowSpacing={1}>
                                    {rangeLabels.map(rl => {
                                      return (
                                        <Grid item xs={12}>
                                          <Grid container direction="row" spacing={1} justifyContent={"flex-start"}>
                                            <Grid item>
                                              <FaSquareFull color={rl.color} />
                                            </Grid>
                                            <Grid item>
                                              <Typography fontSize="12px" fontWeight={1000}>
                                                {rl.start.toLocaleString()}-{rl.final.toLocaleString()} {rl.label.name}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )
                                    })}
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {viewLabel != "none" &&
                        zonesResults.map(zone => {
                          return (
                            <Marker
                              position={{
                                lat: zone.center.geometry.coordinates[0],
                                lng: zone.center.geometry.coordinates[1],
                              }}
                              draggable={true}
                              label={{
                                className: "textStroke",
                                color: "white",
                                fontWeight: "bold",
                                text: viewLabel == "zoneId" ? zone.name : (!zone.value && zone.value != 0) || zone.value == "-" ? "-" : `${localeString(zone.value)}`,
                              }}
                              zIndex={0}
                              icon={{
                                path: google.maps.SymbolPath.CIRCLE,
                                fillOpacity: 0,
                                strokeOpacity: 0,
                                scale: 5,
                              }}
                            />
                          )
                        })}
                    </>
                  </GoogleMap>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid id="NotPrint" container direction="row" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  )
}
export default RecommendationsPage
