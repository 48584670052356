import { Box, Dialog, DialogContent, DialogTitle, Grid, DialogActions } from "@mui/material"
import AppTextField from "../../../../@crema/core/AppFormComponents/AppTextField"
import { fetchError, fetchSuccess, showMessage } from "../../../../redux/actions"
import { THEME_INPUT_FORM } from "../../../../shared/themes/themeConst"
import TextMaskCustom from "../../../../@crema/core/TextMaskCustom"
import IntlMessages from "../../../../@crema/utility/IntlMessages"
import jwtAxios from "../../../../@crema/services/auth/jwt-auth"
import { LoadingButton } from "@mui/lab"
import { useDispatch } from "react-redux"
import { Form, Formik } from "formik"
import { useIntl } from "react-intl"
import { useState } from "react"
import * as yup from "yup"
import "moment/locale/pt-br"

export default function FormModal({ data }) {
  const { openModal, handleClose, listHarvest, initialValues } = data

  const dispatch = useDispatch()
  const { messages } = useIntl()

  const [loading, setLoading] = useState(false)
  

  const handleSubmit = async dataForm => {
    setLoading(true)
    try {
      const startDate = dataForm.startDate.split("/")
      const finalDate = dataForm.finalDate ? dataForm.finalDate.split("/") : null
      if (!dataForm["uuid"]) {
        let { data } = await jwtAxios.post("/harvest", {
          name: dataForm.name,
          startDate: `${startDate[2]}-${startDate[1]}-${startDate[0]}`,
          finalDate: finalDate ? `${finalDate[2]}-${finalDate[1]}-${finalDate[0]}` : null,
        })
        listHarvest()

        dispatch(fetchSuccess())
        dispatch(showMessage(`Safra salva`))
        handleClose()
      } else {
        let { data } = await jwtAxios.put(`/harvest/${dataForm.uuid}`, {
          name: dataForm.name,
          startDate: `${startDate[2]}-${startDate[1]}-${startDate[0]}`,
          finalDate: finalDate ? `${finalDate[2]}-${finalDate[1]}-${finalDate[0]}` : null,
        })
        listHarvest()
        dispatch(fetchSuccess())
        dispatch(showMessage(`Safra Atualizada`))
      }
    } catch (error) {
      console.error(error)
      dispatch(fetchError("Erro ao criar safra"))
    } finally {
      setLoading(false)
    }
  }

  const validationSchema = yup.object({
    name: yup.string().min(3, String(messages["validation.nameFormat"])).required(String(messages["validation.nameRequired"])),

    startDate: yup
      .date()
      .transform((value, originalValue) => {
        try {
          let date = originalValue.split("/")
          if (date.length === 3 && date[2].length === 4) {
            let newDate = `${date[2]}-${date[1]}-${date[0]}`
            return new Date(newDate)
          }
          return null
        } catch (e) {
          return null
        }
      })
      .typeError("Data inválida"),

    finalDate: yup
      .date()
      .transform((value, originalValue) => {
        try {
          let date = originalValue.split("/")
          if (date.length === 3 && date[2].length === 4) {
            let newDate = `${date[2]}-${date[1]}-${date[0]}`
            return new Date(newDate)
          }
          return null
        } catch (e) {
          return null
        }
      })
      .typeError("Data inválida"),
  })

  return (
    <>
      <Dialog open={openModal} onClose={handleClose} fullWidth={true}>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          validateOnChange={true}
          enableReinitialize
          onSubmit={data => {
            handleSubmit(data)
          }}
        >
          {() => (
            <Form>
              <DialogTitle sx={{ textAlign: "center" }}>
                <h1> {messages["common.harvest"]}</h1>
              </DialogTitle>
              <DialogContent>
                <Box sx={{ paddingY: "5px" }}>
                  <Grid container rowSpacing={4} columnSpacing={2}>
                    <Grid item xs={12}>
                      <AppTextField
                        fullWidth
                        placeholder={messages["common.name"] as string}
                        label={<IntlMessages id='common.name' />}
                        InputLabelProps={{ shrink: true }}
                        autoComplete='false'
                        variant='outlined'
                        name='name'
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            fontSize: 14,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <AppTextField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: TextMaskCustom as any,
                          inputProps: { mask: "##/##/####" },
                        }}
                        type='text'
                        autoComplete='false'
                        placeholder={messages["common.startDate"] as string}
                        label={<IntlMessages id='common.startDate' />}
                        variant='outlined'
                        name='startDate'
                        sx={THEME_INPUT_FORM}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <AppTextField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: TextMaskCustom as any,
                          inputProps: { mask: "##/##/####" },
                        }}
                        type='text'
                        autoComplete='false'
                        placeholder={messages["common.finalDate"] as string}
                        label={<IntlMessages id='common.finalDate' />}
                        variant='outlined'
                        name='finalDate'
                        sx={THEME_INPUT_FORM}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions>
                <LoadingButton sx={{ width: "30%", float: "right" }} onClick={handleClose} variant='outlined' color='error'>
                  <IntlMessages id={"common.close"} />
                </LoadingButton>

                <LoadingButton sx={{ width: "30%", float: "right", marginLeft: "1%" }} variant='contained' color='primary' type='submit' loading={loading}>
                  <IntlMessages id={"common.proceed"} />
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}
