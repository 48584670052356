import ReactECharts from 'echarts-for-react'

const Graph = ({ data }) => {
  const {
    element,
    workSelected,
  } = data

  const depth = Object.keys(workSelected.result)
  const xAxis = workSelected.result[depth[0]].map( wS => wS.zone.name )
  var series = [] as any
  var depthSeries = [] as any

  depth.forEach( dep => {
    let serieType = {
      name: '',
      type: 'line',
      data: []
    }

    const data = workSelected.result[dep].map(wS => wS[element.key])
    if(data[0] != null){
      depthSeries.push(dep)
    }
    serieType['name'] = dep
    serieType['data'] = data
    series.push(serieType)
  })

  const option = {
    title: {
      text: element.prettyName,
      // textStyle:{
      //   fontSize: 16
      // }
    },
    toolbox: {
        feature: {
            saveAsImage: {},
            dataZoom: {},
            // restore: {}
        }
    },
    tooltip: {
      trigger: 'axis',
      valueFormatter: (value) =>  value != null ? value.toLocaleString('pt-br', {minimumFractionDigits: 0,maximumFractionDigits: 2}) : value
    },
    legend: {
      data: depthSeries
    },
    xAxis: {
      data: xAxis
    },
    yAxis: {},
    series: series
  }
  depthSeries.sort()

  return <ReactECharts
  option={option}
  style={{ height: '100%', width: '100%' }}
  opts={{ locale: 'PT-BR' }}
/>
}

export default Graph