import React, { ReactNode } from "react";
import Box from "@mui/material/Box";
import { NavStyle } from "../../../shared/constants/AppEnums";

const getMainHeaderHeight = (navStyle: NavStyle, screenSize: number) => {
  if (navStyle === NavStyle.BIT_BUCKET) return screenSize >= 1200 ? 0 : 70; // Header Height
  return screenSize >= 600 ? 70 : 56;
};

interface AppContainerWrapperProps {
  children: ReactNode;
  navStyle: NavStyle;
  footer?: boolean;
}

const AppContainerWrapper: React.FC<AppContainerWrapperProps> = ({
  children,
  navStyle,
  footer,
  ...rest
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "auto",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default AppContainerWrapper;
