import {
  Box,
  Button,
  CardContent,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material"
import { ARCHIVER_COMPRESSION_SUPPORTED, areaToLocaleString, bboxAdjustingSquare, convertToGeojson } from "../../../shared/utils/utils"
import { Circle, GoogleMap, InfoWindow, Polygon, useJsApiLoader } from "@react-google-maps/api"
import { fetchError, fetchSuccess, showMessage } from "../../../redux/actions"
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded"
import UploadModern from "../../../@crema/core/AppUpload/UploadModern"
import IntlMessages from "../../../@crema/utility/IntlMessages"
import jwtAxios from "../../../@crema/services/auth/jwt-auth"
import RotateRightIcon from "@mui/icons-material/RotateRight"
import FileRow from "../../../@crema/core/AppUpload/FileRow"
import RotateLeftIcon from "@mui/icons-material/RotateLeft"
import { useCallback, useEffect, useState } from "react"
import { Form, Formik } from "formik"
import { useDropzone } from "react-dropzone"
import { AppList } from "../../../@crema"
import { useDispatch } from "react-redux"
import { LoadingButton } from "@mui/lab"
import { useIntl } from "react-intl"
import * as turf from "@turf/turf"

const shp = require("shpjs")

const NewWork = ({ data }) => {
  const {
    laboratory = [],
    propertiesCoodinates,
    edtDisable,
    newWorkPage = false,
    technicalTeam = [],
    listPropertiesFields,
    updateWorks,
    orderService,
    selectedWork,
    setModalNewWork,
    initialValuesNewWork,
    setInitialValuesNewWork,
  } = data

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC-5csGO5FhX4qZkTcSgj2rlJc7T4rV1B0",
  })

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])
  const [centerMap, setCenterMap] = useState([-23.7779078, -49.9608293])
  const [selectedPlace, setSelectedPlace] = useState(null) as any
  const [selectedPoint, setSelectedPoint] = useState(null) as any
  const [arrayPoint, setArrayPoint] = useState([]) as any
  const [showNewDepth, setShowNewDepth] = useState(true)
  const [loadingWork, setLoadingWork] = useState(true)

  const [processWorkFinish, setProcessWork] = useState(false)
  const [labAnalysis, setLabAnalysis] = useState([]) as any
  const [MapBounds, setMapBounds] = useState(null) as any
  const [workUUID, setWorkUUID] = useState("") as any
  const [workTypes, setWorkTypes] = useState([]) as any
  const [polygons, setPolygons] = useState([]) as any
  const [osStatus, setOsStatus] = useState("create")
  const [mapType, setMapType] = useState("roadmap")
  const [arrayColor, setArrayColor] = useState([])
  const [infoOpen, setInfoOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState("")
  const [map, setMap] = useState(null) as any
  const [zoomMap, setZoomMap] = useState(5)
  const [uploadedFiles] = useState([])
  const { messages } = useIntl()
  const dispatch = useDispatch()
  const dropzone = useDropzone()

  const [polygonField, setPolygonField] = useState([]) as any
  const [valueRotation, setValueRotation] = useState(5)
  const [gridSquare, setGridSquare] = useState(null) as any

  const [visibleSquare, setVisibleSquare] = useState(true)

  const [dephtList, setDephtList] = useState(["0-20", "20-40", "40-60", "0-10", "10-20", "20-30", "30-40", "40-50", "50-60"])
  const [selectedDephtList, setSelectedDephtList] = useState(["0-20", "20-40"]) as any
  const [analysisProfileUUID, setAnalysisProfileUUID] = useState(null) as any

  const [gridBbox, setGriBbox] = useState(null) as any
  const [valueTechnique, setValueTechnique] = useState(1)
  const [rectangleCoordinates, setrectangleCoordinates] = useState([]) as any
  const [subGridsCoodnates, setSubGridsCoodnates] = useState(null) as any

  const initialConfig = {
    squareArea: 10,
    gridAngle: 0,
    gridAngleTotal: 0,
    subsampleQuantity: 1,
    squareAreaUpdate: true,
    samplingType: "gridPerCell",
    sampleRadius: 30,
    polygonSelected: null as any,
    proccess: true,
    resetGrid: true,
  }

  function getRandomUniqueNumbers(min, max, n) {
    const result = [] as any
    while (result.length < n) {
      const randomNum = Math.floor(Math.random() * (max - min + 1)) + min
      if (!result.includes(randomNum)) {
        result.push(randomNum)
      }
    }
    return result
  }

  const [configGrid, setConfigGrid] = useState(initialConfig)

  useEffect(() => {
    if (newWorkPage && configGrid.polygonSelected && configGrid.proccess && map && gridBbox.length) {
      const squareSize = configGrid.squareArea == 0 ? 1 : Math.round(Math.sqrt(configGrid.squareArea * 10000))
      var squareGridBasic
      var squareGeojson

      if (gridSquare) {
        squareGeojson = turf.featureCollection(
          gridSquare
            .getPaths()
            .getArray()
            .map(sqt => {
              const coord = sqt.getArray().map(coord => {
                return [coord.lng(), coord.lat()]
              })
              return convertToGeojson([coord], "Polygon", {})
            })
        )

        if (!configGrid.squareAreaUpdate) {
          squareGridBasic = squareGeojson
        }
      }

      if (!gridSquare || configGrid.squareAreaUpdate) {
        var options = { units: "meters" } as any
        squareGridBasic = turf.squareGrid(gridBbox, squareSize, options)

        if (squareGeojson && !configGrid.resetGrid) {
          const grid1Coordinates = turf.center(squareGeojson)
          const grid2Coordinates = turf.center(squareGridBasic)

          const distance = turf.distance(grid2Coordinates, grid1Coordinates, { units: "meters" })
          const bearing = turf.bearing(grid2Coordinates, grid1Coordinates)

          squareGridBasic = turf.transformTranslate(squareGridBasic, distance, bearing, { units: "meters" })
        }
      }

      const polyTurf = turf.polygon([configGrid.polygonSelected.coordinates.map(polyCoord => [polyCoord[1], polyCoord[0]])])

      var squareGrid = { type: "FeatureCollection", features: [] } as any
      var allPolygonCoordinates = [] as any
      var center = turf.center(squareGridBasic)

      turf.featureEach(squareGridBasic, (currentFeature: any) => {
        var rotatedGridCell = turf.transformRotate(currentFeature, !configGrid.squareAreaUpdate ? configGrid.gridAngle : configGrid.gridAngleTotal, {
          pivot: center.geometry.coordinates,
          mutate: true,
        })
        squareGrid.features.push(rotatedGridCell)
        allPolygonCoordinates.push(
          rotatedGridCell.geometry.coordinates[0].map(coord => {
            return { lat: coord[1], lng: coord[0] }
          })
        )
      })

      if (gridSquare) gridSquare.setMap(null)

      const rectangle = new google.maps.Polygon({
        draggable: true,
        paths: allPolygonCoordinates,
        strokeColor: "white",
        strokeOpacity: 1,
        visible: visibleSquare,
        zIndex: 0,
        strokeWeight: 1,
        fillColor: "white",
        fillOpacity: 0.02,
      })

      window.google.maps.event.addListener(rectangle, "dragend", () => setConfigGrid({ ...configGrid, proccess: true, gridAngle: 0, squareAreaUpdate: false }))
      rectangle.setMap(map)
      setGridSquare(rectangle)

      const squareGridIntersect = [] as any

      turf.featureEach(squareGrid, (currentFeature: any) => {
        var intersected = turf.intersect(polyTurf, currentFeature) as any
        if (intersected) {
          intersected.geometry.coordinates.map((coord, index) => {
            if (coord.length == 1) {
              var newPoligon = turf.polygon(coord) as any
              newPoligon["properties"]["area"] = turf.area(newPoligon)
              const proportionArea = newPoligon["properties"]["area"] / Math.pow(squareSize, 2)

              if (proportionArea > 0.04) {
                squareGridIntersect.push(newPoligon)
              }
            } else {
              intersected["properties"]["area"] = turf.area(turf.polygon([coord]))
              const proportionArea = intersected["properties"]["area"] / Math.pow(squareSize, 2)
              if (proportionArea > 0.04) {
                squareGridIntersect.push(intersected)
              }
            }
          })
        }
      })

      var arrayPoints = [] as any
      var arrayPointsTotal = [] as any
      var arrayPointsSubG = [] as any
      var gridGoogleMap = [] as any

      squareGridIntersect.map((pol, index) => {
        var polygon = turf.polygon(pol.geometry.coordinates)
        var center = turf.centerOfMass(polygon)

        let ptsWithin = turf.pointsWithinPolygon(center, polygon)

        if (configGrid.subsampleQuantity <= 1 && ptsWithin.features.length) {
          var point = {
            identifier: index + 1,
            latCollected: null,
            lngCollected: null,
            lat: center.geometry.coordinates[1],
            lng: center.geometry.coordinates[0],
            utmX: 0,
            utmY: 0,
            wasCollected: false,
          }
          arrayPoints.push(point)
          arrayPointsTotal = arrayPointsTotal.concat(point)
        } else if (configGrid.samplingType == "gridPerCell") {
          var bboxPolygonGrid = turf.bbox(pol)
          const qtd = Math.ceil(Math.pow(configGrid.subsampleQuantity, 0.5))
          // const qtd = configGrid.subsampleQuantity

          var cellSide = Math.trunc(Math.sqrt(pol.properties.area) / qtd)
          // var cellSide = Math.trunc(Math.sqrt(configGrid.squareArea * 10000) / qtd)

          var squareGridBasic2 = turf.squareGrid(bboxPolygonGrid, cellSide * 0.99, { units: "meters", mask: pol })
          // var squareGridBasic2 = turf.squareGrid(bboxPolygonGrid, cellSide * .90, { units: 'meters', mask: pol })

          if (squareGridBasic2.features.length) {
            var squareGrid2 = { type: "FeatureCollection", features: [] } as any
            // var center = turf.center(squareGridBasic2)

            turf.featureEach(squareGridBasic2, (currentFeature: any) => {
              var rotatedGridCell = turf.transformRotate(currentFeature, configGrid.gridAngleTotal, { pivot: center.geometry.coordinates, mutate: true })
              squareGrid2.features.push(rotatedGridCell)
            })

            var points = [] as any
            const squareGridIntersect2 = [] as any

            turf.featureEach(squareGrid2, (currentFeature: any) => {
              var intersected2 = turf.intersect(pol, currentFeature) as any
              if (intersected2) {
                intersected2.geometry.coordinates.map((coord, index) => {
                  if (coord.length == 1) {
                    var newPoligon = turf.polygon(coord) as any
                    newPoligon["properties"]["area"] = turf.area(newPoligon)
                    const proportionArea = newPoligon["properties"]["area"] / Math.pow(squareSize, 2)

                    if (proportionArea > 0.001) {
                      squareGridIntersect2.push(newPoligon)
                    }
                  } else {
                    intersected2["properties"]["area"] = turf.area(turf.polygon([coord]))
                    const proportionArea = intersected2["properties"]["area"] / Math.pow(squareSize, 2)
                    if (proportionArea > 0.001) {
                      squareGridIntersect2.push(intersected2)
                    }
                  }
                })
              }
            })

            // arrayPointsSubG.push(squareGridIntersect2)

            if (squareGridIntersect2.length <= configGrid.subsampleQuantity) {
              squareGridIntersect2.forEach(sqrt => {
                let center = turf.center(sqrt)
                points = points.concat([[center.geometry.coordinates[0], center.geometry.coordinates[1]]])
              })
            } else {
              const randonNum = getRandomUniqueNumbers(0, squareGridIntersect2.length - 1, configGrid.subsampleQuantity)
              randonNum.forEach(numPos => {
                let center = turf.center(squareGridIntersect2[numPos])
                points = points.concat([[center.geometry.coordinates[0], center.geometry.coordinates[1]]])
              })
            }

            // @ts-ignore
            let ptsWithin = turf.pointsWithinPolygon(turf.points(points), turf.featureCollection(squareGridIntersect2))

            if (ptsWithin.features.length) {
              const pointsGoogle = ptsWithin.features.map((pts, index2) => {
                return {
                  identifier: arrayPoints.length + index2 + 1,
                  latCollected: null,
                  lngCollected: null,
                  lat: pts.geometry.coordinates[1],
                  lng: pts.geometry.coordinates[0],
                  utmX: 0,
                  utmY: 0,
                  wasCollected: false,
                }
              })
              arrayPoints = arrayPoints.concat(pointsGoogle)
              arrayPointsTotal = arrayPointsTotal.concat(pointsGoogle)
            }
          }
        } else if (configGrid.samplingType == "pointGrid") {
          const numPoints = configGrid.subsampleQuantity

          const center = turf.centerOfMass(pol)
          const centro_x = center.geometry.coordinates[0]
          const centro_y = center.geometry.coordinates[1]

          const raioMetros = configGrid.sampleRadius
          const grausPorMetroLatitude = 1 / 111000
          const raio = raioMetros * grausPorMetroLatitude

          const pontosNoPerimetro = [] as any

          for (let i = 0; i < numPoints; i++) {
            const angulo = (Math.PI * 2 * i) / numPoints
            const x = centro_x + raio * Math.cos(angulo)
            const y = centro_y + raio * Math.sin(angulo)
            pontosNoPerimetro.push([x, y])
          }
          let ptsWithin = turf.pointsWithinPolygon(turf.points(pontosNoPerimetro), pol)

          if (ptsWithin.features) {
            const pointsGoogle = ptsWithin.features.map((pts, index2) => {
              return {
                latCollected: null,
                lngCollected: null,
                lat: pts.geometry.coordinates[1],
                lng: pts.geometry.coordinates[0],
                identifier: arrayPoints.length + index2 + 1,
                utmX: 0,
                utmY: 0,
                wasCollected: false,
              }
            })
            arrayPoints = arrayPoints.concat(pointsGoogle)
            arrayPointsTotal = arrayPointsTotal.concat(pointsGoogle)
          }
        }

        if (arrayPoints.length) {
          const newZone = {
            zoneId: index + 1,
            name: index + 1,
            center: center,
            area: pol["properties"]["area"] / 10000,
            id: index + 1,
            pointsCollection: arrayPoints,
            coordinatesArray: pol.geometry.coordinates[0].map(coordinates => [coordinates[1], coordinates[0]]),
            technicalTeamId: pol.technicalTeamId ? pol.technicalTeam.uuid : "",
            coordinates: pol.geometry.coordinates[0].map(coordinates => {
              return {
                lat: coordinates[1],
                lng: coordinates[0],
              }
            }),
          }

          arrayPoints = []
          gridGoogleMap.push(newZone)
        }
      })

      if (valueTechnique) {
        setArrayPoint(arrayPointsTotal)
        setPolygons(gridGoogleMap)
      }

      // var test = [] as any

      // arrayPointsSubG.map(x => {
      //   return x.map(y => {

      //     const pp = y.geometry.coordinates[0].map(z => {
      //       return { lat: z[1], lng: z[0] }
      //     })

      //     test.push(pp)
      //     return pp
      //   })

      // })

      // if (subGridsCoodnates) subGridsCoodnates.setMap(null)

      // const rectangle2 = new google.maps.Polygon({
      //   draggable: true,
      //   paths: test,
      //   strokeColor: 'blue',
      //   strokeOpacity: 1,
      //   visible: true,
      //   zIndex: 5,
      //   strokeWeight: 1,
      //   fillColor: 'white',
      //   fillOpacity: 0.02
      // })

      // rectangle2.setMap(map)
      // setSubGridsCoodnates(rectangle2)

      setConfigGrid({ ...configGrid, squareAreaUpdate: false, gridAngle: 0, proccess: false, resetGrid: false })
    }
  }, [configGrid, map, gridBbox])

  const handleChange = async (event: React.SyntheticEvent, newValue: number) => {
    setInfoOpen(false)
    setSelectedPoint(null)

    if (gridSquare) gridSquare.setMap(null)
    setValueTechnique(newValue)
    setSelectedPoint(null)
    setArrayPoint([])
    setPolygons([])

    if (newValue == 1) {
      setCenterMap(MapBounds)
      fildMapPlot(configGrid.polygonSelected)
      setConfigGrid({ ...initialConfig, polygonSelected: configGrid.polygonSelected })
    }
  }

  useEffect(() => {
    try {
      if (laboratory && laboratory.length && workTypes.length) {

        if(listPropertiesFields.length){
          fildMapPlot(listPropertiesFields[0]["polygon"])
          setConfigGrid({ ...configGrid, polygonSelected: listPropertiesFields[0]["polygon"], proccess: true })

        }
      
        
        setInitialValuesNewWork({
          ...initialValuesNewWork,
          typeId: initialValuesNewWork.typeId ? initialValuesNewWork.typeId : workTypes[0].id,
          typeName: initialValuesNewWork.typeName ? initialValuesNewWork.typeName : workTypes[0].name,
          laboratoryUUID: initialValuesNewWork.laboratoryUUID ? initialValuesNewWork.laboratoryUUID : laboratory[0].uuid,
          fildUUID: listPropertiesFields.length ? listPropertiesFields[0].uuid : '',
          analysisDepth: selectedDephtList.map(dephtList => {
            const startFinal = dephtList.split("-")
            return {
              start: startFinal[0],
              final: startFinal[1],
              analysisProfileUUID: laboratory[0].analysis[0].uuid,
            }
          }),
        })
        setLabAnalysis(laboratory[0].analysis)
        setShowNewDepth(false)
      }
      
    } catch (error) {
      console.error(error)
    }
  }, [laboratory, workTypes, listPropertiesFields])

  // useEffect(() => {
  //   if (listPropertiesFields && listPropertiesFields.length) {
  //     // auxGridMAp(listPropertiesFields[0]['polygon'], true)
  //     fildMapPlot(listPropertiesFields[0]["polygon"])
  //     setConfigGrid({ ...configGrid, polygonSelected: listPropertiesFields[0]["polygon"], proccess: true })
  //     setInitialValuesNewWork({
  //       ...initialValuesNewWork,
  //       fildUUID: listPropertiesFields[0].uuid,
  //     })
  //   }
  // }, [listPropertiesFields])

  useEffect(() => {

    if(map){
      if(MapBounds){
        map.fitBounds(MapBounds)
      } else if(propertiesCoodinates.length){
        const bounds = new google.maps.LatLngBounds()
        var arrayPoint = [] as any
        propertiesCoodinates[0].forEach(element => {
          bounds.extend(new google.maps.LatLng(element[0], element[1]))
          arrayPoint.push({
            lat: element[0],
            lng: element[1],
          })
        })
        map.fitBounds(bounds)
      }
    }
  }, [map, MapBounds])

  useEffect(() => {
    try {
      if (dropzone.acceptedFiles[0]) {
        if (!ARCHIVER_COMPRESSION_SUPPORTED.find(e => e == dropzone.acceptedFiles[0]["type"])) {
          dispatch(fetchError("Extenção não suportada"))
          return
        }
        processZip(dropzone.acceptedFiles[0])
      }
    } catch {
      dispatch(fetchError("Erro ao Carregar arquivo"))
    }
  }, [dropzone.acceptedFiles])

  useEffect(() => {
    getWorkTypes()
  }, [])

  useEffect(() => {
    if (gridSquare) gridSquare.setMap(visibleSquare ? map : null)
  }, [visibleSquare, gridSquare])

  const getWorkTypes = async () => {
    try {
      let { data } = await jwtAxios.get(`/types/work-group`)
      setWorkTypes(data)
    } catch (e: any) {
      dispatch(fetchError(e.message))
    }
  }

  const processZip = async (file: any) => {
    var pointProccess = null
    var zoneProccess = null

    const reader = new FileReader()
    reader.onabort = () => dispatch(fetchError("Upload Abortado"))
    reader.onerror = () => dispatch(fetchError("Upload Error"))
    reader.onload = async () => {
      const binaryStr = reader.result
      try {
        const geojson = await shp(binaryStr)

        var polygon
        var point
        setFileName(geojson[0]["fileName"])
        setSelectedPlace(null)
        setSelectedPoint(null)
        setArrayPoint([])

        geojson.forEach(item => {
          item.features.every(FeatureCollection => {
            if (FeatureCollection.geometry.type === "Polygon") {
              polygon = item.features.map(pol => {
                zoneProccess = pol.properties.ZNM

                if (pol.properties["Área (ha)"]) {
                  pol.properties["Area"] = pol.properties["Área (ha)"]
                } else if (pol.properties["area"]) {
                  pol.properties["Area"] = pol.properties["area"]
                } else if (pol.properties["área"]) {
                  pol.properties["Area"] = pol.properties["área"]
                } else if (pol.properties["Área(ha)"]) {
                  pol.properties["Area"] = pol.properties["Área(ha)"]
                }
                if (pol.geometry.type != "Polygon") {
                  throw "INVALID_GEOMETRY_TYPE"
                }

                if (!pol.properties["Area"] || !pol.properties["ZNM"]) throw "AREA_ZONE_NOT_INFORMED"

                return {
                  ...pol,
                  center: turf.centerOfMass(turf.polygon(pol.geometry.coordinates)),
                }
              })
              return false
            } else if (FeatureCollection.geometry.type === "Point") {
              point = item.features
              return false
            } else {
              throw "ERROR"
            }
          })
        })

        if (!polygon || !point) {
          throw "INCOMPLETE_FILE"
        }

        polygon.map(poly => {
          zoneProccess = poly["properties"]["ZNM"]
          if (!poly["center"]) throw "POLYGON_NOT_CENTER"
          if (!poly["geometry"]) throw "POLYGON_NOT_GEOMETRY"
          if (!poly["geometry"]["coordinates"].length) throw "POLYGON_NOT_GEOMETRY_COODINATES"
        })

        point.map(pt => {
          if (!pt["properties"]) throw "POINT_NOT_PROPERTIES"
          if (!pt["properties"]["Pontos"]) throw "POINT_NOT_PROPERTIES"
          pointProccess = pt["properties"]["Pontos"]

          if (!pt["properties"]["UTM_X"]) throw "POINT_NOT_PROPERTIES"
          if (!pt["properties"]["UTM_Y"]) throw "POINT_NOT_PROPERTIES"
          if (!pt["geometry"]) throw "POINT_NOT_GEOMETRY"
          if (!pt["geometry"]["coordinates"].length) throw "POINT_NOT_GEOMETRY_COODINATES"
        })

        const data = await polygon.map((pol: any) => {
          return {
            name: pol.properties.ZNM,
            id: pol.properties.ZNM,
            area: pol.properties.Area,
            center: pol.center,
            technicalTeamId: "",
            pointsCollection: [],
            coordinatesArray: pol.geometry.coordinates[0].map(coordinates => [coordinates[1], coordinates[0]]),
            coordinates: pol.geometry.coordinates[0].map(coordinates => {
              return {
                lat: coordinates[1],
                lng: coordinates[0],
              }
            }),
          }
        })

        const pointsTurf = turf.featureCollection([])

        const pointObj = await point.map(element => {
          const getPointId = element.properties.Pontos ? element.properties.Pontos : element.properties.Ponto
          const newElement = {
            identifier: getPointId,
            lat: element.geometry.coordinates[1],
            lng: element.geometry.coordinates[0],
            utmX: element.properties.UTM_X,
            utmY: element.properties.UTM_Y,
            wasCollected: false,
          }
          pointsTurf.features.push(
            turf.point([element.geometry.coordinates[1], element.geometry.coordinates[0]], { identifier: getPointId, utmX: element.properties.UTM_X, utmY: element.properties.UTM_Y })
          )
          return newElement
        })

        const bounds = new google.maps.LatLngBounds()
        data.forEach(element => {
          element.coordinates.forEach(point => {
            bounds.extend(point)
          })
        })

        // @ts-ignore
        setCenterMap(bounds.getCenter().toJSON())
        setMapBounds(bounds)

        data.forEach(poly => {
          const polygonTurf = turf.polygon([poly["coordinatesArray"]])
          // @ts-ignore
          const turfWithiIn = turf.pointsWithinPolygon(pointsTurf, polygonTurf)

          poly["pointsCollection"] = turfWithiIn.features.map((point: any) => {
            return {
              lat: point.geometry.coordinates[0],
              lng: point.geometry.coordinates[1],
              identifier: point.properties.identifier,
              utmX: point.properties.utmX,
              utmY: point.properties.utmY,
              wasCollected: false,
            }
          })
        })
        data.sort((a, b) => (a.name > b.name ? 1 : -1))
        setPolygons(data)
        setMapType("satellite")
        setArrayPoint(pointObj)
      } catch (e) {
        console.log(e)

        if ("INVALID_GEOMETRY_TYPE" == e) {
          dispatch(fetchError(`Arquivo invalido, zona não possue um tipo valido de Poligono. ${zoneProccess ? ` Ex: zone ${zoneProccess}` : ""}`))
        } else if ("POLYGON_NOT_CENTER" == e) {
          dispatch(fetchError(`Arquivo incompleto, zona não possue informações centro. ${zoneProccess ? ` Ex: zone ${zoneProccess}` : ""}`))
        } else if ("POLYGON_NOT_GEOMETRY" == e) {
          dispatch(fetchError(`Arquivo incompleto, informe junto a geometry da zona. ${zoneProccess ? ` Ex: zone ${zoneProccess}` : ""} `))
        } else if ("POLYGON_NOT_GEOMETRY_COODINATES" == e) {
          dispatch(fetchError(`Arquivo incompleto, informe junto as coodenadas da zonas. ${zoneProccess ? ` Ex: zone ${zoneProccess}` : ""}`))
        } else if ("POINT_NOT_GEOMETRY_COODINATES" == e) {
          dispatch(fetchError(`Arquivo incompleto, informe junto as coodenadas do ponto. ${pointProccess ? ` Ex: ponto ${pointProccess}` : ""}`))
        } else if ("POINT_NOT_GEOMETRY" == e) {
          dispatch(fetchError(`Arquivo incompleto, informe junto o campo geometry do ponto. ${pointProccess ? ` Ex: ponto ${pointProccess}` : ""}`))
        } else if ("POINT_NOT_PROPERTIES" == e) {
          dispatch(fetchError(`Arquivo incompleto, informe junto as propriedades pertencentes nos pontos: Pontos, UTM_X e UTM_Y. ${pointProccess ? ` Ex: ponto ${pointProccess}` : ""}`))
        } else if ("AREA_ZONE_NOT_INFORMED" == e) {
          dispatch(fetchError("Arquivo incompleto, informe junto a propriedade os campos ZNM e Area"))
        } else {
          dispatch(fetchError(e == "INCOMPLETE_FILE" ? "Aquivo Incompleto" : "Arquivos Inválidos"))
        }
      }
    }
    reader.readAsArrayBuffer(file)
  }

  const markerClickHandler = (event, poly) => {
    setSelectedPlace(poly)
    if (infoOpen) {
      setInfoOpen(false)
    }
    setInfoOpen(true)
  }

  const auxLabAnaslysis = LaboratoryUUID => {
    const lab = laboratory.find(element => element.uuid == LaboratoryUUID)
    setAnalysisProfileUUID(lab["analysis"].length ? lab!["analysis"][0].uuid : null)
    setLabAnalysis(lab!["analysis"])
  }

  const fildMapPlot = polygon => {
    if (polygon && newWorkPage) {
      const polyTurf = turf.polygon([polygon.coordinates.map(polyCoord => [polyCoord[1], polyCoord[0]])])

      const bounds = new google.maps.LatLngBounds()
      var arrayPoint = [] as any

      polygon.coordinates.forEach(element => {
        bounds.extend(new google.maps.LatLng(element[0], element[1]))
        arrayPoint.push({
          lat: element[0],
          lng: element[1],
        })
      })
      setGriBbox([])

      setGriBbox(bboxAdjustingSquare(turf.bbox(polyTurf)))

      // setrectangleCoordinates([
      //   { lat: minX, lng: minY },
      //   { lat: maxX, lng: minY },
      //   { lat: maxX, lng: maxY },
      //   { lat: minX, lng: maxY },
      //   { lat: minX, lng: minY },
      // ])

      // @ts-ignore
      setCenterMap(bounds.getCenter().toJSON())
      setPolygonField(arrayPoint)
      setMapBounds(bounds)

      if (valueTechnique == 1) setConfigGrid({ ...initialConfig, polygonSelected: polygon })
    }
  }

  const submitWork = async formdata => {
    setLoading(true)
    try {
      const { data } = await jwtAxios.post(`/v2/orderService/${orderService.uuid}/work`, formdata)
      setModalNewWork(false)
      updateWorks()
      dispatch(fetchSuccess())
      dispatch(showMessage(`${messages["newOS.createWork"]}`))
      setModalNewWork(false)
    } catch (e: any) {
      console.log(e)
      dispatch(fetchError(e.message))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={initialValuesNewWork}
        enableReinitialize
        onSubmit={data => {
          data["polygons"] = polygons
          data["fileName"] = fileName

          if (!data.typeId) {
            dispatch(fetchError(messages["newOS.error.analyseType"] as string))
            return
          }

          if (!polygons.length) {
            dispatch(fetchError(messages["newOS.error.field"] as string))
            return
          }

          if (data.analysisDepth.length < 1) {
            dispatch(fetchError(messages["newOS.error.analyseDepth"] as string))
            return
          }

          submitWork(data)
        }}
      >
        {({ values, setFieldValue, setValues }) => (
          <Form style={{ textAlign: "left", padding: "0px" }} noValidate autoComplete='off'>
            {laboratory.length && labAnalysis.length ? (
              <>
                <Grid container style={{ width: "100%", height: "100vh" }}>
                  <Grid item xs={2.5}>
                    <Grid container rowSpacing={4} justifyContent='center' alignItems='flex-start' style={{ maxHeight: "95vh", overflow: "auto", display: "flex" }}>
                      <Grid item sx={{ backgroundColor: "rgba(21, 101, 192, .9)", padding: "8px !important", margin: "0px", marginTop: "15px", width: "100%", borderRadius: "0px 0px 2px 2px " }}>
                        <Button onClick={() => setModalNewWork(false)} fullWidth component='label' startIcon={<ArrowBackRoundedIcon htmlColor='#fff' />}>
                          <Typography gutterBottom variant='h5' align='center' textAlign='center' component='div' color='white' fontWeight='bold' margin='auto'>
                            Nova Análise
                          </Typography>
                        </Button>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          sx={{ width: "100%", paddingX: "5px" }}
                          required
                          size='small'
                          disabled={edtDisable}
                          autoComplete='false'
                          onChange={e => {
                            setFieldValue("name", e.target.value)
                          }}
                          placeholder={messages["common.name"] as string}
                          name='name'
                          label={<IntlMessages id='common.name' />}
                          variant='outlined'
                          type='text'
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          select
                          size='small'
                          disabled={edtDisable}
                          sx={{ width: "100%", paddingX: "5px" }}
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          placeholder={messages["work.workType"] as string}
                          label={<IntlMessages id='work.workType' />}
                          value={values.typeId}
                          name='typeId'
                        >
                          {workTypes.map((element: any) => (
                            <MenuItem
                              disabled={edtDisable}
                              key={element.id}
                              value={element.id}
                              onClick={() => {
                                setGriBbox([])
                                setFieldValue("typeId", element.id)
                                setFieldValue("typeName", element.name)
                              }}
                            >
                              {element.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          select
                          sx={{ width: "100%", paddingX: "5px" }}
                          size='small'
                          required
                          disabled={edtDisable}
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          placeholder={messages["common.field"] as string}
                          value={values.fildUUID}
                          label={<IntlMessages id='common.field' />}
                          name='fildUUID'
                        >
                          {listPropertiesFields.map((element: any) => (
                            <MenuItem
                              disabled={edtDisable}
                              onClick={() => {
                                setInfoOpen(false)
                                setSelectedPoint(null)
                                setFieldValue("fildUUID", element["uuid"])
                                fildMapPlot(element["polygon"])

                                if (gridSquare) {
                                  gridSquare.setMap(null)
                                  setGridSquare(null)
                                }
                              }}
                              key={element.uuid}
                              value={element.uuid}
                            >
                              {element.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          select
                          required
                          size='small'
                          disabled={edtDisable}
                          sx={{ width: "100%", paddingX: "5px" }}
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          placeholder={messages["common.laboratory"] as string}
                          value={values.laboratoryUUID}
                          label={<IntlMessages id='common.laboratory' />}
                          name='laboratoryUUID'
                        >
                          {laboratory.map((element: any) => (
                            <MenuItem
                              disabled={edtDisable}
                              key={element.uuid}
                              value={element.uuid}
                              onClick={() => {
                                auxLabAnaslysis(element.uuid)
                              }}
                            >
                              {element.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          // defaultValue={values.analysisDepth[0]["analysisProfileUUID"]}
                          select
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          size='small'
                          sx={{ width: "100%", paddingX: "5px" }}
                          disabled={edtDisable}
                          label={<IntlMessages id='work.labAnalysis' />}
                          placeholder={messages["work.labAnalysis"] as string}
                          value={analysisProfileUUID ? analysisProfileUUID : labAnalysis[0].uuid}
                          name={"analysisProfileUUID"}
                          onChange={e => {
                            const newDepths = selectedDephtList.map(dephtList => {
                              const startFinal = dephtList.split("-")
                              return {
                                start: startFinal[0],
                                final: startFinal[1],
                                analysisProfileUUID: e.target.value,
                              }
                            })

                            setFieldValue("analysisDepth", newDepths)
                            setAnalysisProfileUUID(e.target.value)
                          }}
                        >
                          {labAnalysis.map((element: any) => (
                            <MenuItem key={element.uuid} id={element.uuid} disabled={edtDisable} value={element.uuid}>
                              {element.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          sx={{ width: "100%", paddingX: "5px" }}
                          required
                          size='small'
                          disabled={edtDisable}
                          autoComplete='false'
                          placeholder={messages["work.qtyControlSample"] as string}
                          name='qtyControlSample'
                          label={<IntlMessages id='work.qtyControlSample' />}
                          variant='outlined'
                          type='number'
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl sx={{ width: "100%", paddingX: "5px" }}>
                          <InputLabel id='demo-multiple-chip-label'>Profundidades</InputLabel>
                          <Select
                            labelId='demo-multiple-chip-label'
                            id='demo-multiple-chip'
                            sx={{ width: "100%" }}
                            size='small'
                            multiple
                            value={selectedDephtList}
                            onChange={e => {
                              const valuesOrder = e.target.value.sort()
                              const newDepths = valuesOrder.map(depht => {
                                const startFinal = depht.split("-")
                                return {
                                  start: startFinal[0],
                                  final: startFinal[1],
                                  analysisProfileUUID: analysisProfileUUID ? analysisProfileUUID : labAnalysis[0].uuid,
                                }
                              })
                              setFieldValue("analysisDepth", newDepths)
                              setSelectedDephtList(e.target.value)
                            }}
                            input={<OutlinedInput id='select-multiple-chip' label='Profundidades' />}
                            renderValue={selected => (
                              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {selected.map(value => (
                                  <Chip size='small' key={value} label={value} />
                                ))}
                              </Box>
                            )}
                          >
                            {dephtList.map(name => (
                              <MenuItem key={name} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Tabs
                          sx={{
                            ".css-y18bho-MuiButtonBase-root-MuiTab-root": {
                              padding: "0px !important",
                            },
                            marginTop: "-20px",
                            marginBottom: "0px",
                            paddingX: "5px !important",
                          }}
                          aria-label='full width tabs example'
                          onChange={(e, value) => {
                            handleChange(e, value)
                          }}
                          value={valueTechnique}
                          // variant="fullWidth"
                          centered
                        >
                          <Tab sx={{ padding: "0px !important" }} value={1} label='Grid' />
                          <Tab sx={{ padding: "0px !important" }} value={2} label='Zona de Manejo' />
                        </Tabs>
                      </Grid>

                      <Grid item xs={12}>
                        {valueTechnique == 1 && newWorkPage && (
                          <>
                            <Grid container spacing={2} xs={12} justifyContent={"center"} justifyItems={"center"} alignItems={"center"} sx={{ margin: "0px", marginTop: "-6px", padding: "0px" }}>
                              <Grid item sx={{ backgroundColor: "rgba(21, 101, 192, .9)", padding: "8px !important", margin: "0px !important", width: "100%", borderRadius: "3px" }}>
                                <Typography gutterBottom variant='h5' align='center' textAlign='center' component='div' color='white' fontWeight='bold' margin='auto'>
                                  Configurar Grid
                                </Typography>
                              </Grid>

                              <Grid item sx={{ padding: "12px 5px 5px 5px !important", width: "100%" }}>
                                <TextField
                                  InputProps={{ endAdornment: <InputAdornment position='end'>ha</InputAdornment> }}
                                  // error
                                  onChange={event => {
                                    var newArea = Number(event.target.value)
                                    newArea = newArea > 0 ? newArea : 1
                                    setConfigGrid({ ...configGrid, proccess: true, squareArea: newArea, squareAreaUpdate: configGrid.squareArea != newArea })
                                  }}
                                  id='outlined-error'
                                  type='number'
                                  sx={{ width: "100%" }}
                                  variant='outlined'
                                  label='Tamanho Grid'
                                  size='small'
                                  name='squareGrid'
                                  defaultValue={configGrid.squareArea}
                                  value={configGrid.squareArea}
                                />
                              </Grid>

                              <Grid item>
                                <Grid container alignItems='center' spacing={1} justifyItems='center' justifyContent='left'>
                                  <Grid item xs={9} sx={{ width: "100%" }}>
                                    <TextField
                                      // error
                                      type='number'
                                      defaultValue={0}
                                      style={{ width: "100%" }}
                                      size='small'
                                      variant='outlined'
                                      name='valueRotation'
                                      onChange={event => {
                                        setValueRotation(event.target.value ? Number(event.target.value) : 0)
                                      }}
                                      value={valueRotation}
                                      label='Rotação'
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <h6> graus </h6>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>

                                  <Grid item>
                                    <IconButton
                                      size='small'
                                      color='info'
                                      sx={{ padding: "1px" }}
                                      onClick={() => setConfigGrid({ ...configGrid, proccess: true, gridAngleTotal: configGrid.gridAngleTotal - valueRotation, gridAngle: -valueRotation })}
                                    >
                                      <RotateLeftIcon />
                                    </IconButton>
                                  </Grid>

                                  <Grid item>
                                    <IconButton
                                      size='small'
                                      color='info'
                                      sx={{ padding: "1px" }}
                                      onClick={() => setConfigGrid({ ...configGrid, proccess: true, gridAngleTotal: configGrid.gridAngleTotal + valueRotation, gridAngle: valueRotation })}
                                    >
                                      <RotateRightIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid sx={{ padding: "5px !important", width: "100%" }}>
                              <FormControlLabel
                                label='Mostrar Grid'
                                sx={{ marginLeft: "3px", marginTop: "-10px" }}
                                control={<Switch color='info' checked={visibleSquare} onChange={() => setVisibleSquare(!visibleSquare)} />}
                              />
                            </Grid>

                            <Grid sx={{ backgroundColor: "rgba(21, 101, 192, .9)", padding: "8px !important", width: "100%", borderRadius: "3px" }}>
                              <Typography gutterBottom variant='h5' align='center' textAlign='center' component='div' color='white' fontWeight='bold' margin='auto'>
                                Configurar Subamostras
                              </Typography>
                            </Grid>

                            <Grid sx={{ padding: "12px 5px 5px 5px !important", width: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id='demo-simple-select-label'>Tipo de Amostragem</InputLabel>
                                <Select
                                  labelId='demo-simple-select-label'
                                  id='demo-simple-select'
                                  size='small'
                                  label='Tipo de Amostragem'
                                  defaultValue={configGrid.samplingType}
                                  value={configGrid.samplingType}
                                  onChange={event => setConfigGrid({ ...configGrid, proccess: true, samplingType: event.target.value })}
                                >
                                  <MenuItem value={"gridPerCell"}>Grade por célula</MenuItem>
                                  <MenuItem value={"pointGrid"}>Grade por ponto</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid sx={{ padding: "5px !important", width: "100%" }}>
                              <TextField
                                // error
                                id='outlined-error'
                                size='small'
                                sx={{ width: "100%" }}
                                variant='outlined'
                                type='number'
                                label='Qtde subamostra'
                                value={configGrid.subsampleQuantity}
                                onChange={event => setConfigGrid({ ...configGrid, proccess: true, subsampleQuantity: Number(event.target.value) })}
                                defaultValue={1}
                              />
                            </Grid>

                            {configGrid.samplingType == "pointGrid" && (
                              <>
                                <CardContent sx={{ padding: "5px !important", width: "100%" }}>
                                  <TextField
                                    // error
                                    sx={{ width: "100%" }}
                                    type='number'
                                    id='outlined-error'
                                    variant='outlined'
                                    label='Raio Subamostra'
                                    size='small'
                                    name='subsample'
                                    onChange={event => setConfigGrid({ ...configGrid, proccess: true, sampleRadius: Number(event.target.value) })}
                                    value={configGrid.sampleRadius}
                                    InputProps={{ endAdornment: <InputAdornment position='end'>m</InputAdornment> }}
                                    defaultValue={1}
                                  />
                                </CardContent>
                              </>
                            )}
                          </>
                        )}
                        {valueTechnique == 2 && (
                          <>
                            <Grid item xs={11} sx={{ margin: "auto", marginTop: "10px" }}>
                              <UploadModern uploadText='Importar zip do talhão' dropzone={dropzone} />
                              <AppList data={uploadedFiles} renderRow={(file, index) => <FileRow key={index + file.path} file={file} onDeleteUploadFile={() => {}} />} />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      direction='row'
                      alignItems='center'
                      sx={{
                        position: "fixed",
                        bottom: 0,
                        marginBottom: "5px",
                      }}
                    >
                      <Grid container xs={2.5} justifyContent={"space-around"}>
                        <Grid item xs={5.6}>
                          <LoadingButton sx={{ width: "100%" }} variant='outlined' color='error' size='large' onClick={() => setModalNewWork(false)}>
                            <h4>Voltar</h4>
                          </LoadingButton>
                        </Grid>

                        <Grid item xs={5.6}>
                          <LoadingButton
                            loading={loading}
                            sx={{ width: "100%" }}
                            // disabled={valueTechnique == 1 && newWorkPage}
                            variant='contained'
                            color='primary'
                            type='submit'
                            size='large'
                          >
                            <h4>
                              <IntlMessages id={"common.save"} />
                            </h4>
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={9.5}>
                    {isLoaded && (
                      <GoogleMap
                        mapContainerStyle={{ width: "auto", height: "100vh" }}
                        options={{
                          streetViewControl: false,
                          mapTypeId: google.maps.MapTypeId.SATELLITE,
                          fullscreenControl: false,
                          panControl: false,
                          isFractionalZoomEnabled: true,
                          mapTypeControl: false,
                          zoomControl: false,
                        }}
                        id='google-map-script'
                        onLoad={map => {
                          setMap(map)
                        }}
                        zoom={zoomMap}
                        mapTypeId={mapType}
                        onUnmount={onUnmount}
                      >
                        <>
                          {
                            <Box sx={{ position: "relative", width: "100%", paddingRight: "5px", paddingTop: "5px" }}>
                              <div style={{ float: "right", width: "fit-content", padding: 10, borderRadius: "3px", backgroundColor: "rgba(255, 255, 255, .8)" }}>
                                <h4 style={{ fontWeight: 600, margin: "auto", width: "fit-content", color: "black", fontSize: 20 }}> {`Zonas: ${polygons.length}`} </h4>
                                <h4 style={{ fontWeight: 600, margin: "auto", width: "fit-content", color: "black", fontSize: 20 }}> {`Pontos: ${arrayPoint.length}`} </h4>
                              </div>
                            </Box>
                          }

                          {polygons.map((poly: any) => {
                            return (
                              <Polygon
                                key={poly.name}
                                options={{
                                  fillColor: osStatus && osStatus == "create" ? (poly.technicalTeamId ? "#3ce26f" : "#9d9d93") : "#9d9d93",
                                  fillOpacity: 0.4,
                                  strokeWeight: 2,
                                  strokeColor: "black",
                                  zIndex: 1,
                                }}
                                onClick={event => markerClickHandler(event, poly)}
                                path={poly.coordinates}
                              />
                            )
                          })}

                          {infoOpen && selectedPlace && (
                            <>
                              <InfoWindow
                                position={{
                                  lat: selectedPlace.center.geometry.coordinates[1],
                                  lng: selectedPlace.center.geometry.coordinates[0],
                                }}
                                onCloseClick={() => setInfoOpen(false)}
                              >
                                <div style={{ minWidth: "200px" }}>
                                  <h2 style={{ padding: "3px" }}>Zona: {selectedPlace.name}</h2>
                                  <h5 style={{ padding: "3px" }}>Área: {areaToLocaleString(selectedPlace.area, 1, 1)} ha</h5>
                                  <h5 style={{ padding: "px" }}>Pontos: {selectedPlace.pointsCollection.length}</h5>

                                  <TextField
                                    select
                                    disabled={edtDisable}
                                    size='small'
                                    variant='outlined'
                                    placeholder={messages["common.team"] as string}
                                    label={<IntlMessages id='common.team' />}
                                    InputLabelProps={{ shrink: true }}
                                    value={selectedPlace.technicalTeamId}
                                    defaultValue={selectedPlace.technicalTeamId}
                                    sx={{ width: "100%", padding: "3px", marginTop: "10px" }}
                                    name={"technicalTeamId" + selectedPlace.name}
                                  >
                                    {technicalTeam.map((element: any) => (
                                      <MenuItem
                                        disabled={edtDisable}
                                        key={element.uuid}
                                        value={element.uuid}
                                        onClick={() => {
                                          let arrayPoligons = polygons
                                          let posIndex = arrayPoligons.findIndex(e => e.name == selectedPlace.name)
                                          arrayPoligons[posIndex].technicalTeamId = element.uuid
                                          setPolygons(arrayPoligons)
                                          const centriodPoly = turf.centroid(turf.polygon([selectedPlace["coordinatesArray"]]))
                                          setCenterMap([centriodPoly.geometry.coordinates[0], centriodPoly.geometry.coordinates[1]])
                                        }}
                                      >
                                        {element.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </div>
                              </InfoWindow>
                            </>
                          )}

                          {arrayPoint.map(point => {
                            return (
                              <>
                                <Circle
                                  center={{ lat: point.lat, lng: point.lng }}
                                  onClick={() => {
                                    setSelectedPoint(point)
                                    markerClickHandler({}, null)
                                    setCenterMap([point.lat, point.lng])
                                  }}
                                  radius={
                                    configGrid.subsampleQuantity > 1 && configGrid.samplingType == "pointGrid"
                                      ? configGrid.sampleRadius <= 5
                                        ? 1
                                        : configGrid.sampleRadius <= 10
                                        ? 2
                                        : configGrid.sampleRadius <= 15
                                        ? 3
                                        : 4
                                      : 5
                                  }
                                  options={{
                                    fillColor: osStatus ? (point.wasCollected ? "#00CC0A" : "#EA1414") : "#EA1414",
                                    strokeColor: osStatus ? (point.wasCollected ? "#00CC0A" : "#EA1414") : "#EA1414",
                                    strokeOpacity: 1,
                                    fillOpacity: 1,
                                    zIndex: 2,
                                  }}
                                />
                              </>
                            )
                          })}

                          {selectedPoint && (
                            <>
                              <InfoWindow
                                position={{
                                  lat: selectedPoint.lat,
                                  lng: selectedPoint.lng,
                                }}
                                onCloseClick={() => {
                                  setSelectedPoint(null)
                                  // setCenterMap([selectedPoint.lat, selectedPoint.coordinatesArray.lng])
                                }}
                              >
                                <div>
                                  <br />
                                  <h4>Ponto: {selectedPoint.identifier}</h4>
                                  <br />
                                  <h6>Lat: {selectedPoint.lat}</h6>
                                  <h6>Long: {selectedPoint.lng}</h6>
                                  <h6>Lat. Coletada: {selectedPoint.latCollected ? selectedPoint.latCollected : ""}</h6>
                                  <h6>Long. Coletada: {selectedPoint.lngCollected ? selectedPoint.lngCollected : ""}</h6>
                                  <h6>UTM_X: {selectedPoint.utmX}</h6>
                                  <h6>UTM_Y: {selectedPoint.utmX}</h6>
                                  <br />
                                </div>
                              </InfoWindow>
                            </>
                          )}
                        </>
                      </GoogleMap>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container height={'100vh'} direction='row' justifyContent='center' alignItems='center'>
                  <CircularProgress />
                </Grid>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}
export default NewWork
