import { Grid, IconButton, Tooltip } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Close from "@mui/icons-material/Close"

const TableItem = ({ data }) => {
  const {
    harvest,
    setInitialValues,
    handleClickOpen,
    setHarvestDelete,
    handleOpenDeleteModal
  } = data

  const auxEdtHarvest = () => {
    setInitialValues({
      uuid: harvest.uuid,
      name: harvest.name,
      startDate: harvest.startDate,
      finalDate: harvest.finalDate,
    })
    handleClickOpen()
  }

  return (
    <TableRow
      className='item-hover'
      hover={true}
      key={harvest.uuid}
      onDoubleClick={() => auxEdtHarvest()}
      sx={{
        cursor: "pointer",
        zIndex: 0,
        "& .tableCell": {
          fontSize: 13,

          padding: "12px 8px",
          "&:first-of-type": {
            pl: 5,
          },
          "&:last-of-type": {
            pr: 5,
          },
        },
      }}
    >
      <TableCell width={"50%"} align='left' scope='row' className='tableCell'>
        {harvest.name}
      </TableCell>

      <TableCell align='left' scope='row' className='tableCell'>
        {harvest.startDate}
      </TableCell>

      <TableCell align='left' scope='row' className='tableCell'>
        {harvest.finalDate}
      </TableCell>

      <TableCell align='right' className='tableCell'>
        <Grid container xs={12}>
          <Grid item xs={6}>
            <Tooltip title='Editar'>
              <IconButton aria-label='editIcon' onClick={() => auxEdtHarvest()} >
                <EditIcon />
              </IconButton>
            </Tooltip>

          </Grid>

          <Grid item xs={6}>
            <Tooltip title='Excluir'>
              <IconButton aria-label='deleteIcon' onClick={() =>{
                setHarvestDelete(harvest)
                handleOpenDeleteModal()
              }}
                color='error'>
                <Close />
              </IconButton>
            </Tooltip>
          </Grid>

        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default TableItem
