import { Grid, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { aplicationZinc, areaToLocaleString, auxRecommendationPhosphatage, auxRecommendationV2, localeString, roundApplicationValue, roundSimplyApplicationValue } from "../../../../shared/utils/utils"
import AppTextField from "../../../../@crema/core/AppFormComponents/AppTextField"
import { THEME_APP_TEXTDIELD, THEME_H4_APLICATION, THEME_TABLE_ROW } from "../../../../shared/themes/themeConst"
import IntlMessages from "../../../../@crema/utility/IntlMessages"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import AplicationNotes from "../../../../shared/components/notesApplication"
import ApplicationFormConfig from "../../../../shared/components/auxApplication"


export default function ApplicationZinc({ data }) {
  const {
    applicationRecommendationNotes,
    setNewAplicationZones,
    recomendationTypes,
    newAplicationZones,
    formSetFieldValues,
    preResultZones,
    formValues,
    setNotes,
  } = data


  const { messages } = useIntl()
  const [totalNCAplication, setTotalNCAplication] = useState([0, 0]) as any
  const [depthApl, setDepthApl] = useState('0-20')
  const [totalArea, setTotalArea] = useState(0)

  useEffect(() => {
    var totalApp = [0, 0]
    var auxTotalArea = 0

    const zones = preResultZones.zones.map((zone) => {
      const depth = Object.keys(zone).find(k => k.includes('-')) as string
      setDepthApl(depth)
      const findRecomendation = recomendationTypes.find(r => r.id == formValues.typeRecomendationId)

      var exedenteValue

      try {
        exedenteValue = zone['exedente'] || zone['exedente'] == 0 ? zone.exedente : zone[depth!].exedente
      } catch (error) {
        exedenteValue = 0
      }
      exedenteValue = exedenteValue == '-' ? 0 : exedenteValue

      var manAddExc = formValues.maintenance + formValues.additionalRecommendation
      manAddExc = manAddExc < 0 ? 0 : manAddExc

      const auxRec = auxRecommendationV2((zone.totalNC + manAddExc), formValues.minimumLimit, formValues.maximumLimit, formValues.zeroBelowMinimum,  formValues.disposalLimit)
      var recTotalNC = aplicationZinc(auxRec, findRecomendation.concentration, formValues.efficiencyFactor)
      var missingT = (zone.totalNC + manAddExc) - auxRec

      const auxRecAvg = auxRecommendationV2((zone.totalNC + manAddExc) / 2, formValues.minimumLimit, formValues.maximumLimit, formValues.zeroBelowMinimum,  formValues.disposalLimit)
      var recAVG = aplicationZinc(auxRecAvg, findRecomendation.concentration, formValues.efficiencyFactor)
      var missingAVG = (zone.totalNC + manAddExc) - auxRecAvg

      recTotalNC = recTotalNC > 0 ? recTotalNC : 0
      missingT = missingT > 0 ? missingT : 0

      recAVG = recAVG > 0 ? recAVG : 0
      missingAVG = missingAVG > 0 ? missingAVG : 0

      const result = zone.area * recTotalNC
      const resultAvg = zone.area * recAVG

      const newRecTotal = roundSimplyApplicationValue(result, formValues.round)
      const newRecTotalAvg = roundSimplyApplicationValue(resultAvg, formValues.round)

      totalApp = [
        totalApp[0] += newRecTotal,
        totalApp[1] += newRecTotalAvg,
      ]
      auxTotalArea += zone['area']

      return {
        recTotalNC,
        recTotalNCArea: newRecTotal,
        recAVGArea: newRecTotalAvg,
        elementTotal: auxRec > 0 ? auxRec : 0,
        elementTotalAvg: auxRecAvg > 0 ? auxRecAvg : 0,
        missingT,
        manAddExc: manAddExc,
        recAVG: recAVG,
        missingAVG: missingAVG,
        name: zone.name,
        area: zone.area,
        totalNC: zone.totalNC,
        exedente: exedenteValue && exedenteValue > 0 ? exedenteValue : 0,
        totalApp
      }
    })

    setNewAplicationZones(zones)
    setTotalNCAplication(totalApp)
    setTotalArea(auxTotalArea)

  }, [formValues])

  return (
    <>
      <Grid container sx={{ width: '100%' }}>
        <TableContainer>
          <Grid container xs={12} sx={{ paddingX: '10px' }} >

            <Grid item xs={4} padding='10px'>
              <AppTextField
                placeholder={messages["recommendations.boron"] as string}
                label={<IntlMessages id="recommendations.boron" />}
                defaultValue={formValues.typeRecomendationId}
                value={formValues.typeRecomendationId}
                InputLabelProps={{ shrink: true }}
                disabled={formValues.disable}
                sx={THEME_APP_TEXTDIELD}
                name="typeRecomendationId"
                variant="outlined"
                select
              >
                {
                  recomendationTypes.map((rec: any) => (
                    <MenuItem
                      key={rec.id}
                      value={rec.id}
                      onClick={() => { formSetFieldValues("typeRecomendationId", rec.id) }}
                    >
                      {
                        rec.name
                      }
                    </MenuItem>
                  ))
                }
              </AppTextField>
            </Grid>

            <ApplicationFormConfig data={data} />
           
            <Grid item xs={12} style={{ height: '65vh', overflow: 'auto' }}>
              <Grid item xs={12}>
                <AplicationNotes data={{ formValues, formSetFieldValues, applicationRecommendationNotes, setNotes }} />
              </Grid>

              <Grid item xs={12} padding='10px'>
                <TableContainer >
                  <Table size="small" stickyHeader >
                    <caption>
                      <h4 style={THEME_H4_APLICATION}>
                        Aplicação Atual: {localeString(totalNCAplication[formValues.average ? 1 : 0])}  (Kg)
                      </h4>
                    </caption>

                    <caption>
                      <h3 style={THEME_H4_APLICATION}>
                        Área Total: {localeString(totalArea)} (ha)
                      </h3>
                    </caption>

                    <TableHead>
                      <TableRow  sx={THEME_TABLE_ROW}>
                        <TableCell style={{ minWidth: 100 }} align="center">Zona</TableCell>
                        <TableCell style={{ minWidth: 100 }} align="center">Área</TableCell>
                        <TableCell style={{ minWidth: 100 }} align="center">NZ (Zn)</TableCell>
                        <TableCell style={{ minWidth: 100 }} align="center">Mautenção + adicional (Zn)</TableCell>
                        <TableCell style={{ minWidth: 100 }} align="center">Excedente (Zn)</TableCell>
                        <TableCell style={{ minWidth: 100 }} align="center">Faltante (Zn) </TableCell>
                        <TableCell style={{ minWidth: 100 }} align="center">Aplicação (Zn) </TableCell>
                        <TableCell style={{ minWidth: 100 }} align="center">Aplicação (Kg/ha) </TableCell>
                        <TableCell style={{ minWidth: 100 }} align="center">Total (kg) </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {
                        newAplicationZones.map(((zone, index) => {
                          return (
                            <TableRow hover key={index}  sx={THEME_TABLE_ROW}>
                              <TableCell align="center"> {zone.name} </TableCell>
                              <TableCell align="center"> {areaToLocaleString(zone.area)} </TableCell>
                              <TableCell align="center"> {areaToLocaleString(zone.totalNC, 1, 1)} </TableCell>
                              <TableCell align="center"> {areaToLocaleString(zone.manAddExc, 1, 1)} </TableCell>

                              {
                                formValues.average ? <>
                                  <TableCell align="center"> {localeString(zone.exedente)} </TableCell>
                                  <TableCell align="center"> {localeString(zone.missingAVG)}</TableCell>
                                  <TableCell align="center"> {areaToLocaleString(zone.elementTotalAvg, 1, 1)}</TableCell>
                                  <TableCell align="center"> {localeString(zone.recAVG)}</TableCell>
                                  <TableCell align="center"> {localeString(zone.recAVGArea)} </TableCell>
                                </> : <>
                                  <TableCell align="center"> {localeString(zone.exedente)} </TableCell>
                                  <TableCell align="center"> {localeString(zone.missingT)} </TableCell>
                                  <TableCell align="center"> {areaToLocaleString(zone.elementTotal, 1, 1)} </TableCell>
                                  <TableCell align="center"> {localeString(zone.recTotalNC)} </TableCell>
                                  <TableCell align="center"> {localeString(zone.recTotalNCArea)} </TableCell>

                                </>
                              }

                            </TableRow>
                          )
                        }))
                      }
                    </TableBody>

                  </Table>
                </TableContainer>

              </Grid>
            </Grid>

          </Grid>
        </TableContainer>
      </Grid>
    </>
  )
}