import { authRole } from "../../../shared/constants/AppConst";

export const getUserFromAuth0 = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.user,
    };
  return user;
};

export const getUserFromFirebase = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName ? user.displayName : "Crema User",
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : "/assets/images/avatar/A11.jpg",
      role: authRole.user,
    };
  return user;
};
export const getUserFromAWS = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : "Crema User",
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.user,
    };
  return user;
};

export const getUserFromJwtAuth = (user: any) => {
  if (user)
    return {
      id: user.id,
      uuid: user.uuid,
      displayName: user.name,
      photoURL: user.avatar,
      role: authRole.user,
      phone: user.name,
      enterpriseName: user.enterprise.name,
      enterprisePhone: user.enterprise.phone,
      enterpriseEmail: user.enterprise.email,
      enterpriseSite: user.enterprise.site,
      enterpriseCnpj: user.enterprise.cnpj,
      enterpriseUUID: user.enterprise.uuid,
      enterpriseBanner: user.enterprise.bannerBase64,
    };
  return user;
};
