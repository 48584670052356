import { Circle, GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api'
import UploadModern from '../../../@crema/core/AppUpload/UploadModern'
import FileRow from '../../../@crema/core/AppUpload/FileRow'
import { DEFAULT_COORDENATES } from '../../utils/utils'
import { fetchError } from '../../../redux/actions'
import { useDropzone } from 'react-dropzone'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppList } from '../../../@crema'
import kmlParser from 'js-kml-parser'
import Box from '@mui/material/Box'
import * as turf from "@turf/turf"


export default ({ data }) => {
  const {
    setPropertiesCoodinates,
    mapContainerStyle = {},
    selectedFildID = null,
    propertiesCoodinates,
    importFile = false,
    fields = [],
    works = [],
  } = data

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC-5csGO5FhX4qZkTcSgj2rlJc7T4rV1B0",
  })

  const [centerMap, setCenterMap] = useState(DEFAULT_COORDENATES)
  const [propertyCood, setPropertyCood] = useState([]) as any
  const [MapBounds, setMapBounds] = useState(null) as any
  const [arrayPoint, setArrayPoint] = useState([]) as any
  const [fieldCood, setFieldCood] = useState([]) as any
  const [zones, setZones] = useState([]) as any
  const [mapType, setMapType] = useState("roadmap")
  const [map, setMap] = useState(null) as any
  const [zoomMap, setZoomMap] = useState(5)
  const [uploadedFiles] = useState([])
  const dispatch = useDispatch()
  const dropzone = useDropzone()

  useEffect(() => {
    try {
      if (dropzone.acceptedFiles[0]) {
        if (dropzone.acceptedFiles[0]["type"] != 'kml' && dropzone.acceptedFiles[0]["name"].split('.')[1] != 'kml') {
          dispatch(fetchError("Extenção não suportada"))
          return
        }
        proccessKml(dropzone.acceptedFiles[0])
      }
    } catch {
      dispatch(fetchError("Erro ao Carregar arquivo"))
    }
  }, [dropzone.acceptedFiles])

  useEffect(() => {
    if (map && MapBounds) {
      map.fitBounds(MapBounds)
    }
  }, [map, MapBounds])

  useEffect(() => {
    try {
      if (isLoaded && fields.length) {
        const fieldCoodinates = fields.map((fil, index) => {
          return {
            id: fil.id,
            coodinates: fil.polygon ? fil.polygon.coordinates.map(pol => {
              return {
                lat: pol[0],
                lng: pol[1],
              }
            }) : []
          }
        })

        setFieldCood(fieldCoodinates)
      }

    } catch (error) {
      console.log(error)
      dispatch(fetchError("Erro ao Carregar arquivo"))
    }
  }, [isLoaded, fields])

  useEffect(() => {
    try {
      let fullZones = []
      let arrayPoint = [] as any


      if (isLoaded && works && works.length) {
        works.map(work => {
          const zonesPol = work.field.zones.map((pol: any) => {
            arrayPoint = arrayPoint.concat(pol.points)
            return {
              zoneId: pol.id,
              name: pol.name,
              center: turf.centerOfMass(turf.polygon(pol.polygon.coordinates)),
              area: pol.area,
              id: pol.fieldId,
              pointsCollection: pol.points,
              coordinatesArray: pol.polygon.coordinates[0],
              technicalTeamId: pol.technicalTeamId ? pol.technicalTeam.uuid : '',
              coordinates: pol.polygon.coordinates[0].map((coordinates) => {
                return {
                  lat: coordinates[0],
                  lng: coordinates[1],
                }
              }),
            }
          })
          fullZones = fullZones.concat(zonesPol)
        })

        arrayPoint = arrayPoint.map((element) => {
          return {
            identifier: element.identifier,
            coordinatesArray: {
              lat: element.lat,
              lng: element.lng,
              identifier: element.identifier,
              utmX: element.utmX,
              utmY: element.utmY,
              wasCollected: element.wasCollected
            }
          }
        })
        if (isLoaded && propertiesCoodinates.length == 0 && fullZones.length > 0) {

          // @ts-ignore
          let realCenter = [fullZones.map(z => [z.center.geometry.coordinates[0], z.center.geometry.coordinates[1]])]

          realCenter[0].push(realCenter[0][0])
          if (realCenter[0].length < 4) {
            while (realCenter[0].length < 4) {
              realCenter[0].push(realCenter[0][0])
            }
          }
          const polyTurf = turf.polygon(realCenter)
          var centerPolygon = turf.center(polyTurf)


          setCenterMap([
            centerPolygon.geometry.coordinates[0],
            centerPolygon.geometry.coordinates[1],
          ])

          const bounds = new google.maps.LatLngBounds()
          arrayPoint.forEach(point => {
            bounds.extend(new google.maps.LatLng(point.coordinatesArray.lat, point.coordinatesArray.lng));
          })

          const padding = .04
          const ne = bounds.getNorthEast()
          const sw = bounds.getSouthWest()
          const dx = ne.lng() - sw.lng()
          const dy = ne.lat() - sw.lat()
          bounds.extend(new window.google.maps.LatLng(ne.lat() + dy * padding, ne.lng() + dx * padding))
          bounds.extend(new window.google.maps.LatLng(sw.lat() - dy * padding, sw.lng() - dx * padding))
          
          setMapBounds(bounds)
        }

        setZones(fullZones)
        setArrayPoint(arrayPoint)
      }

    } catch (error) {
      console.log(error)
    }
  }, [isLoaded, works])


  useEffect(() => {
    if (isLoaded && propertiesCoodinates.length) {
      setPropertyCood(
        [
          {
            id: 1,
            coodinates: propertiesCoodinates[0].map(pol => {
              return {
                lat: pol[0],
                lng: pol[1],
              }
            })
          }
        ]
      )
      const polyTurf = turf.polygon(propertiesCoodinates)
      var centerPolygon = turf.center(polyTurf)

      setCenterMap([
        centerPolygon.geometry.coordinates[0],
        centerPolygon.geometry.coordinates[1],
      ])
      setMapType("satellite")

      const bounds = new google.maps.LatLngBounds()
      propertiesCoodinates.forEach(element => {
        element.forEach(point => {
          bounds.extend(new google.maps.LatLng(point[0], point[1]));
        })
      })

      const padding = 0.04
      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()
      const dx = ne.lng() - sw.lng()
      const dy = ne.lat() - sw.lat()
      bounds.extend(new window.google.maps.LatLng(ne.lat() + dy * padding, ne.lng() + dx * padding))
      bounds.extend(new window.google.maps.LatLng(sw.lat() - dy * padding, sw.lng() - dx * padding))
      setMapBounds(bounds)
    }
  }, [propertiesCoodinates, isLoaded])

  const proccessKml = (kmlRaw) => {
    try {
      const reader = new FileReader()
      reader.onabort = () => dispatch(fetchError("Upload Abortado"))
      reader.onerror = () => dispatch(fetchError("Upload Error"))
      reader.onload = async () => {
        try {
          const binaryStr = reader.result
          const geoJson = await kmlParser.toGeoJson(binaryStr)
          if (!geoJson.features.length) throw 'ZERO_FEATURES'
          var polygon = [geoJson.features[0].geometry.coordinates[0].map(cood => {
            return [cood[1], cood[0]]
          })]
          setPropertiesCoodinates(polygon)

        } catch (error) {
          fetchError("Arquivo Inválido")
        }
      }
      reader.readAsText(kmlRaw)

    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>

      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          id="google-map-script"
          onLoad={map => setMap(map)}
          zoom={zoomMap}
          options={{
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.SATELLITE,
            fullscreenControl: false,
            isFractionalZoomEnabled: true,
            mapTypeControl: false,
          }}
          center={
            new window.google.maps.LatLng(
              centerMap[0],
              centerMap[1]
            )
          }
          mapTypeId={mapType}
        >
          {
            propertyCood.map((pol) => {
              return <>
                <Polygon
                  key={pol.id}
                  options={{
                    fillOpacity: .2,
                    strokeWeight: 2,
                    strokeColor: 'black',
                    zIndex: 0
                  }}
                  path={pol.coodinates}
                />
              </>
            })
          }

          {
            fieldCood.map((pol) => {
              return <>
                <Polygon
                  key={pol.id}
                  options={{
                    fillColor: selectedFildID == pol.id ? '#00800d' : 'black',
                    fillOpacity: selectedFildID == pol.id ? .5 : 0.2,
                    strokeWeight: 2,
                    strokeColor: 'black',
                    zIndex: selectedFildID == pol.id ? 1 : 0
                  }}
                  path={pol.coodinates}
                />
              </>
            })
          }

          {
            zones.map((poly: any) => {
              return <>
                <Polygon
                  key={poly.name}
                  options={{
                    fillOpacity: 0,
                    strokeWeight: 2,
                    strokeColor: 'black',
                    zIndex: 2
                  }}
                  path={poly.coordinates}
                />

                <Marker
                  options={{ collisionBehavior: google.maps.CollisionBehavior.REQUIRED }}
                  position={{
                    lat: poly.center.geometry.coordinates[0],
                    lng: poly.center.geometry.coordinates[1],
                  }}
                  label={{
                    color: 'rgb(255, 255, 255)',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    className: "textStroke",
                    text: `ZN${poly.name}`,
                  }}
                  zIndex={0}
                  icon={{
                    path: google.maps.SymbolPath.CIRCLE,
                    fillOpacity: 0,
                    strokeOpacity: 0
                  }}
                />

                {/* {
                  poly['area'] && <>
                    <Marker
                      options={{ collisionBehavior: google.maps.CollisionBehavior.REQUIRED }}
                      position={{
                        lat: poly.center.geometry.coordinates[0] * 1.000025,
                        lng: poly.center.geometry.coordinates[1],
                      }}
                      label={{
                        color: 'rgb(255, 255, 255)',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        className: "textStroke",
                        text: `${areaToLocaleString(poly['area'])}ha`,
                      }}
                      zIndex={0}
                      icon={{
                        path: google.maps.SymbolPath.CIRCLE,
                        fillOpacity: 0,
                        strokeOpacity: 0
                      }}
                    />
                  </>
                } */}



              </>
            })}

          {
            arrayPoint.map((point) => {
              return <>
                <Circle
                  center={{ lat: point.coordinatesArray.lat, lng: point.coordinatesArray.lng }}
                  radius={5}
                  options={{
                    fillColor: point.coordinatesArray.wasCollected ? '#00CC0A' : "#FF0000",
                    strokeColor: point.coordinatesArray.wasCollected ? '#00CC0A' : "#FF0000",
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    zIndex: 3,
                  }}
                />
              </>
            })
          }
        </GoogleMap>
      )}

      {
        importFile ? <>
          <Box style={{ marginTop: '5px' }}>
            <UploadModern
              uploadText="Import um arquivo Kml"
              dropzone={dropzone}
            />
            <AppList
              data={uploadedFiles}
              renderRow={(file, index) => (
                <FileRow
                  key={index + file.path}
                  file={file}
                  onDeleteUploadFile={() => { }}
                />
              )}
            />
          </Box>
        </> : <></>
      }
    </>
  )
}