import { CircularProgress, Grid } from '@mui/material'
import Box from '@mui/material/Box'


const CircularProg = () => {
  return (
    <Box>
      <Grid container xs={12} justifyContent='center' alignItems='center' sx={{ height: 300 }}>
        <Grid item>
          <CircularProgress size={50}  color="primary" />
        </Grid>
      </Grid>  
    </Box>
  )
}

export default CircularProg