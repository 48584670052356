import {
  ClientsActionTypes,
  GET_CLIENT,
} from "../../types/actions/Clients.action";

const initialState: any = {
  client: { },
  loading: false,
};

const Client = (state = initialState, action: ClientsActionTypes) => {
  switch (action.type) {
    case GET_CLIENT:
      return {
        ...state,
        client: action.payload,
      };

    default:
      return state;
  }
};

export default Client;
