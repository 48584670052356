import * as React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: string;
  value: string;

}


export const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={props.mask}
        definitions={{
          '#': /[0-9]/,
        }}
        inputRef={ref as any}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);
export const TextMaskCustomCpfOrCnpj = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustomCpfOrCnpj(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={(props.value && props.value.length > 14) ? "00.000.000/0000-00": "000.000.000-000000-00" }
        definitions={{
          '#': /[0-9]/,
        }}
        inputRef={ref as any}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);
export default TextMaskCustom;

