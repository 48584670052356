import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"
import { allPreResultVariables, areaToLocaleString, localeString, preResultBoron, preResultZinc } from "../../../../shared/utils/utils"
import { Fonts } from "../../../../shared/constants/AppEnums"
import { useEffect, useState } from "react"
import { THEME_H4_APLICATION, THEME_TABLE_ROW } from "../../../../shared/themes/themeConst"


export default function Boron({ data }) {
  const {
    setPreResultZones,
    preResultZones,
    setKeys,
    labKeys,
    labResult,
    formValues
  } = data

  const [totalArea, setTotalArea] = useState(0)
  
  useEffect(() => {
    const critical_level = formValues.methodologyId == 81 ? false : true

    const arrayKeys = Object.keys(labResult).sort((a: any, b: any) => a.split('-')[0] - b.split('-')[0])
    setKeys(arrayKeys)

    let auxZones = {}
    var auxTotalNC = 0
    var auxTotalArea = 0
    
    const key = arrayKeys[0]

    labResult[key].forEach((result) => {
      if (!(result.zone.name in auxZones)) {
        auxZones[result.zone.name] = {
          totalNC: 0
        }
      }

      var preResult = 0

      if (critical_level) {
        preResult = preResultZinc(result['Zn'])
      }

      if (preResult > 0) {
        auxTotalNC = auxTotalNC + (result.zone.area * preResult)
      }

      auxZones[result.zone.name]['name'] = result.zone.name
      auxZones[result.zone.name]['area'] = result.zone.area
      auxZones[result.zone.name]['totalNC'] = preResult > 0 ? preResult : 0
      auxZones[result.zone.name][key] = {
        ...allPreResultVariables,
        preResult: preResult > 0 ? preResult : 0,
        Zn: result['Zn'],
      }
      auxTotalArea += result.zone.area ? result.zone.area : 0
    })

    setPreResultZones({
      totalNC: auxTotalNC,
      zones: Object.values(auxZones)
    })
    setTotalArea(auxTotalArea)

  }, [])

  return (
    <Grid container sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ overflow: 'hidden' }}>
        <Table size="small" stickyHeader >
          <caption><h4 style={THEME_H4_APLICATION}>Total NC: {localeString(preResultZones.totalNC)} (Kg)</h4></caption>
          <caption><h4 style={THEME_H4_APLICATION}>Área Total: {localeString(totalArea)} (ha)</h4></caption>
          <TableHead>
            <TableRow sx={THEME_TABLE_ROW}>
              <TableCell style={{ minWidth: 75 }} align="center">Zona</TableCell>
              <TableCell style={{ minWidth: 75 }} align="center">Área (ha)</TableCell>
              <TableCell style={{ minWidth: 75 }} align="center">Profundidade</TableCell>
              <TableCell style={{ minWidth: 75 }} align="center">Zn (mg/dm³)</TableCell>
              <TableCell style={{ minWidth: 75 }} align="center">NZ (kg/ha)</TableCell>
              <TableCell style={{ minWidth: 75 }} align="center">Total (Kg)</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              preResultZones.zones.map(((zone, index) => {
                if (!zone[labKeys[0]]) return <></>
                return (
                  <TableRow hover key={index} sx={THEME_TABLE_ROW}>
                    <TableCell align="center"> {zone.name} </TableCell>
                    <TableCell align="center"> {areaToLocaleString(zone.area)} </TableCell>
                    <TableCell align="center"> {labKeys[0]} </TableCell>
                    <TableCell align="center">{!zone[labKeys[0]]['Zn'] ? 0 : areaToLocaleString(zone[labKeys[0]]['Zn'])}</TableCell>
                    <TableCell align="center">{areaToLocaleString(zone[labKeys[0]]['preResult'], 1, 1)}</TableCell>
                    <TableCell align="center">{areaToLocaleString(zone.area * zone['totalNC'], 1, 1)}</TableCell>
                  </TableRow>
                )
              }))
            }
          </TableBody>
        </Table>

      </TableContainer>
    </Grid>
  )
}