import Settings from "./Setting";
import Clients from './Clients';
import Common from "./Common";
import Cities from "./Cities";
import Client from './Client'

const reducers = {
  settings: Settings,
  common: Common,
  cities: Cities,
  clients: Clients,
  client: Client,
};

export default reducers;
