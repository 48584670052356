import React, { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { fetchError, fetchStart, fetchSuccess } from "../../../../redux/actions"
import { AuthUser } from "../../../../types/models/AuthUser"
import jwtAxios, { setAuthToken } from "./index"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"

interface JWTAuthContextProps {
  user: AuthUser | null | undefined
  isAuthenticated: boolean
  isLoading: boolean
}

interface SignUpProps {
  name: string
  email: string
  password: string
}

interface SignInProps {
  email: string
  password: string
}


interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void
  signInUser: (data: SignInProps) => void
  refreshUser: () => void
  logout: () => void
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
})
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  refreshUser: () => {},
  signUpUser: () => {},
  signInUser: () => {},
  logout: () => {},
})

export const useJWTAuth = () => useContext(JWTAuthContext)

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext)

interface JWTAuthAuthProviderProps {
  children: ReactNode
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const [firebaseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  })

  const dispatch = useDispatch()
  const { messages } = useIntl()

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem("token")
      const refreshToken = localStorage.getItem("refreshToken")
      if (!token || !refreshToken) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        })
        return
      }
      setAuthToken(token, refreshToken)
      jwtAxios
        .get("/auth/me")
        .then(({ data }) =>
          setJWTAuthData({
            user: data,
            isLoading: false,
            isAuthenticated: true,
          })
        )
        .catch(() =>
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          })
        )
    }

    getAuthUser()
  }, [])


  const refreshUser = async () => {
    const res = await jwtAxios.get("/auth/me")
    setJWTAuthData({ user: res.data, isAuthenticated: true, isLoading: false })
  }

  const signInUser = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    dispatch(fetchStart())
    try {
      const { data } = await jwtAxios.post("/auth/login", { email, password })
      localStorage.setItem("token", data.token)
      localStorage.setItem("refreshToken", data.refreshToken)
      
      setAuthToken(data.token, data.refreshToken)

      const res = await jwtAxios.get("/auth/me")
      setJWTAuthData({ user: res.data, isAuthenticated: true, isLoading: false })
      dispatch(fetchSuccess())
      

    } catch (error: any) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      })
      console.log(error)
      dispatch(fetchError(error['message'] ? error['message'] : 'Não foi possível completar a solicitação'))
    }
  }

  const signUpUser = async ({
    name,
    email,
    password,
  }: {
    name: string
    email: string
    password: string
  }) => {
    dispatch(fetchStart())
    try {
      const { data } = await jwtAxios.post("users", { name, email, password })
      localStorage.setItem("token", data.token)
      localStorage.setItem("refreshToken", data.refreshToken)
      setAuthToken(data.token, data.refreshToken)
      const res = await jwtAxios.get("/auth")
      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      })
      dispatch(fetchSuccess())
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      })
      dispatch(fetchError("Something went wrong"))
    }
  }

  const logout = async () => {
    setAuthToken()
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    })
  }

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          refreshUser,
          signUpUser,
          signInUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  )
}
export default JWTAuthAuthProvider
