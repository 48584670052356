import jwtAxios from "@crema/services/auth/jwt-auth";
import { Dispatch } from "redux";
import { AppActions } from "types";
import { GET_CLIENTS, GET_CLIENT } from "types/actions/Clients.action";
import { SEARCH_CITY } from "types/actions/Cities.action";
import { useNavigate } from 'react-router-dom';
import { history } from 'redux/store';

export const getClients = (
  name: string,
  page: 1
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      let { data } = await jwtAxios
        .get("/clients", {
          params: {
            page: page,
            query: name
          },
        })
      dispatch({ type: GET_CLIENTS, payload: data });

    } catch (e) {

    }
  };
};

export const getClient = (
  uuid: string
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      let { data } = await jwtAxios
        .get("/clients/" + uuid)
      dispatch({ type: GET_CLIENT, payload: data });
    } catch (e) {

    }
  };
};

export const saveClient = (client: any) => {

  return async (dispatch: Dispatch<AppActions>) => {
    try {
      let { data } = await jwtAxios.post("/clients/", client);
     
    } catch (e) {

    }
  };
};

export const editClient = (
  uuid: string,
  client: any,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      let { data } = await jwtAxios
        .put("/clients/" + uuid)
      dispatch({ type: GET_CLIENT, payload: data });
    } catch (e) {

    }
  };
};