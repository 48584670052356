import { Box, List, ListItemText, Grid, Button, Typography, Divider, ListItemButton, ListItemSecondaryAction, Tooltip } from "@mui/material"
import VerticalNavItem from "@crema/core/AppLayout/components/VerticalNav/VerticalItem/VerticalNavItem"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import Close from "@mui/icons-material/Close"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import LayoutPadrao from "@crema/core/AppLayout/LayoutPadrao"
import jwtAxios from "../../../@crema/services/auth/jwt-auth"
import GoogleMap from "../../../shared/components/googleMap"
import AppNavLink from "@crema/core/AppNavLink"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { NewOSCreate } from "../newOsModal"
import Dialog from "@mui/material/Dialog"
import Field from "../field"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { THEME_INPUT_LIST_TEXT_PRIMARY } from "../../../shared/themes/themeConst"
import { OsEdt } from "../osEdt"
import AlertDelete from "../../utils/AlertDelete"
import { useDispatch } from "react-redux"
import { fetchError, fetchSuccess, showMessage } from "../../../redux/actions"

declare module "react" {
  interface CSSProperties {
    "--tree-view-color"?: string
    "--tree-view-bg-color"?: string
  }
}

interface VerticalItemProps {
  url?: string
  text?: string
  clientName?: string
}

const VerticalItemCliente: React.FC<VerticalItemProps> = ({ url, text }) => {
  return (
    <VerticalNavItem level={1} button to={url} component={AppNavLink} activeClassName='active' exact={"true"}>
      <ListItemText
        className='nav-item-content'
        primary={text}
        classes={{ primary: "nav-item-text" }}
        primaryTypographyProps={{
          style: {
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          },
        }}
      />
    </VerticalNavItem>
  )
}

const VerticalNav = data => {
  return (
    <List
      sx={{
        position: "relative",
        padding: 0,
      }}
      component='div'
    >
      <VerticalItemCliente text={data.client.name} url={`/clientes/${data.client.uuid}/fazendas`} />
      <VerticalItemCliente text={data.name} url={`/fazenda/${data.uuid}`} />
    </List>
  )
}

const DetailsProperties = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let { farmId } = useParams()

  const initialValue = {
    coordinatesArray: [],
    propertyUUID: farmId,
    name: "",
    note: "",
    harvestId: null,
  }

  const [fildInitialValues, setFildInitialValues] = useState(initialValue) as any
  const [oSInitialValues, setOSInitialValues] = useState({}) as any
  const [propertiesCoodinates, setPropertiesCoodinates] = useState([]) as any
  const [fieldCoodinates, setFieldCoodinates] = useState([]) as any
  const [selectedFildID, setSelectedFildID] = useState(null) as any
  const [orderService, setOrderService] = useState([]) as any
  const [modalField, setModalField] = useState(false) as any
  const [subMenuOpened, setSubMenuOpened] = useState(true)
  const [properties, setProperty] = useState(null) as any
  const [modalOS, setModalOS] = useState(false) as any
  const [modalOsEdt, setModalOsEdt] = useState(false)
  const [fields, setFilds] = useState([]) as any
  const [loading, setLoading] = useState(false)
  const [arrayStatus, setArrayStatus] = useState([]) as any
  const [osSelected, setOsEdt] = useState({})
  const [osStatus, setOsStatus] = useState([]) as any
  const [listAllHarvest, setListAllHarvest] = useState([]) as any
  const [listOsByHarvest, setListOsByHarvest] = useState({}) as any
  const [loadingDeleteOs, setLoadingDeleteOs] = useState(false)

  useEffect(() => {
    setLoading(true)
    getProperties()
    getOsStatus()
    getHarvest()
  }, [])

  const getOsStatus = async () => {
    try {
      const { data } = await jwtAxios.get(`/types/os-edtable`)
      setOsStatus(data)
    } catch (e: any) {
      console.error(e)
    }
  }

  const getHarvest = async () => {
    try {
      const { data } = await jwtAxios.get(`/harvest/all`)
      setListAllHarvest(data)
    } catch (e: any) {
      console.error(e)
    }
  }

  const getProperties = async () => {
    try {
      let { data } = await jwtAxios.get(`/properties/${farmId}`)
      setProperty(data)

      setOSInitialValues({
        clientName: data.client.name,
        clientUUID: data.client.uuid,
        propertyName: data.name,
        propertyUUID: farmId,
        name: "",
        note: "",
      })

      if (data["polygon"]) setPropertiesCoodinates(data["polygon"]["coordinates"])

      setFilds(data["fields"])

      if (data["fields"].length) setSelectedFildID(data["fields"][0].id)

      var listNewOs = [
        {
          name: "Safras não definidas",
          id: -1,
          osList: [] as any,
        },
      ]
      data["orderService"].forEach((element: any) => {
        if (element["harvest"]) {
          const positionIndex = listNewOs.findIndex(lNO => lNO.id == element["harvestId"])
          if (positionIndex > 0) listNewOs[positionIndex].osList.push(element)
          else {
            listNewOs.push({
              name: element["harvest"]["name"],
              id: element["harvest"]["id"],
              osList: [element],
            })
          }
        } else {
          const positionIndex = listNewOs.findIndex(lNO => lNO.id == -1)
          listNewOs[positionIndex].osList.push(element)
        }
      })

      const arrayStatusLocal = new Array(listNewOs.length).fill(true)
      setArrayStatus(arrayStatusLocal)

      setListOsByHarvest(listNewOs)
      setOrderService(data["orderService"])
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const _renderVerticalNav = () => VerticalNav(properties)
  const [modalDeleteOs, setModalDeleteOs] = useState(false)

  const handleCloseDeleteModal = () => {
    setModalDeleteOs(false)
  }

  const deleteOS = async () => {
    try {
      setLoadingDeleteOs(true)
      let { data } = await jwtAxios.delete(`/v2/orderService/${osSelected["uuid"]}`)
      await getProperties()
      dispatch(fetchSuccess())
      dispatch(showMessage(`Ordem de Serviço Excluída`))

      handleCloseDeleteModal()
    } catch (error) {
      console.error(error)
      dispatch(fetchError("Erro ao deletar Ordem de Serviço"))
    } finally {
      setLoadingDeleteOs(false)
    }
  }

  const deleteField = async (uuid) => {
    try {
      let { data } = await jwtAxios.delete(`/fields/${uuid}`)
      dispatch(fetchSuccess())
      dispatch(showMessage(`Talhão Excluido`))
      const newFields = fields.filter(field => field.uuid != uuid)
      setFilds(newFields)

      handleCloseDeleteModal()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <AlertDelete
        data={{
          open: modalDeleteOs,
          handleClose: handleCloseDeleteModal,
          textMessage: `Excluir a ordem de serviço ${
            osSelected["name"] ? osSelected["name"] : "Ordem de Serviço #" + osSelected["id"]
          } removerá permanentemente todas as análises associadas a ela. Gostaria de continuar?`,
          textTitle: "Exclusão de Ordem de Serviço!",
          loading: loadingDeleteOs,
          submitForm: deleteOS,
        }}
      />

      <Dialog fullWidth={true} open={modalField} onClose={() => setModalField(false)} maxWidth={"lg"}>
        <Field data={{ setModalField, fieldCoodinates, setFieldCoodinates, fildInitialValues, setFildInitialValues, getProperties }} />
      </Dialog>

      <Dialog fullWidth={true} open={modalOS} onClose={() => setModalOS(false)} maxWidth={"sm"}>
        <NewOSCreate data={{ oSInitialValues, setModalOS, listAllHarvest }} />
      </Dialog>

      <Dialog fullWidth={true} open={modalOsEdt} onClose={() => setModalOsEdt(false)} maxWidth={"sm"}>
        <OsEdt data={{ osSelected, listAllHarvest, setModalOsEdt, getProperties, osStatus, propertyName: oSInitialValues.propertyName, clientName: oSInitialValues.clientName }} />
      </Dialog>

      {!loading && properties ? (
        <>
          <LayoutPadrao
            sxBox={{
              background: "white",
              p: { xs: 0, md: 0, xl: 0 },
            }}
            verticalNav={_renderVerticalNav()}
          >
            <Box
              sx={{
                height: "100%",
                "& .bit-bucket-btn": {
                  position: "absolute",
                  top: 20,
                  marginLeft: "-10px",
                  borderRadius: "50%",
                  backgroundColor: theme => theme.palette.primary.main,
                  color: theme => theme.palette.primary.contrastText,
                  cursor: "pointer",
                  zIndex: 99,
                  display: { xs: "none", lg: "block" },
                  "& svg": {
                    display: "block",
                  },
                },
                display: "flex",
              }}
            >
              <Grid container>
                <Grid item md={3} xs={4} className={subMenuOpened ? "" : "subCollapsed"} sx={{ height: "100vh", display: subMenuOpened ? "flex" : "none", mt: 0 }}>
                  <Box sx={{ width: "100%", height: "100vh" }}>
                    <Grid container direction='column' height={"90vh"} sx={{ flexWrap: "nowrap", overflow: "auto" }}>
                      <Grid item padding={"5px"}>
                        <Typography variant='h1' component='h2' align={"center"}>
                          {properties.name}
                        </Typography>
                      </Grid>

                      <Grid item sx={{ paddingY: 2, mt: 3 }}>
                        <Typography variant='h5' component='h5' align={"left"} paddingLeft={"3%"}>
                          <b> Talhões: </b>
                        </Typography>

                        <Grid item sx={{ maxHeight: "100%" }}>
                          {fields.map(item => {
                            return (
                              <ListItemButton selected={selectedFildID === item.id} sx={{ p: "2px 0px 2px 5%", mt: 0 }}>
                                <ListItemText
                                  sx={{ wordWrap: "break-word", maxWidth: "75%", ...THEME_INPUT_LIST_TEXT_PRIMARY }}
                                  onClick={() => {
                                    setSelectedFildID(item.id)
                                  }}
                                  key={item.id}
                                  primary={item.name}
                                />

                                <ListItemSecondaryAction>
                                  <Grid container>
                                    <Grid item>
                                      <Tooltip title='Editar'>
                                        <EditOutlinedIcon
                                          color='action'
                                          onClick={() => {
                                            setFildInitialValues({
                                              ...fildInitialValues,
                                              coordinatesArray: item.polygon ? [item.polygon.coordinates] : [],
                                              name: item.name,
                                              note: item.note,
                                              id: item.uuid,
                                            })
                                            setFieldCoodinates(item.polygon ? [item.polygon.coordinates] : [])
                                            setModalField(true)
                                          }}
                                        />
                                      </Tooltip>
                                    </Grid>
                                    <Grid item>
                                      <Tooltip title= {item.work ? '' : 'Excluir'} >
                                        <Close
                                          onClick={() => {
                                            if(!item.work) deleteField(item.uuid) 
                                          }}
                                          color={item.work ? 'disabled' : 'error'}
                                        />
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </ListItemSecondaryAction>
                              </ListItemButton>
                            )
                          })}
                        </Grid>
                      </Grid>

                      <Grid item sx={{ mt: 0, paddingY: 5 }}>
                        <Typography variant='h4' component='h4' align={"left"} paddingLeft={"3%"}>
                          Safras:
                        </Typography>
                        <Grid item md={12} sx={{ maxHeight: "100%", ...THEME_INPUT_LIST_TEXT_PRIMARY, margin: 0 }}>
                          {listOsByHarvest.map((harvestOs, index) => {
                            if (!harvestOs.osList.length) return <></>
                            return (
                              <>
                                <ListItemButton
                                  id={harvestOs.id}
                                  sx={{ backgroundColor: arrayStatus[index] ? "rgba(0,0,0,.1)" : "none" }}
                                  onClick={e => {
                                    let newArrayStatus = arrayStatus
                                    newArrayStatus[index] = !newArrayStatus[index]
                                    setArrayStatus(newArrayStatus)
                                    setListOsByHarvest([...listOsByHarvest])
                                  }}
                                >
                                  <ListItemText primary={`${harvestOs.name}`} style={{ wordWrap: "break-word" }} />
                                  <ListItemText />
                                  {arrayStatus[index] ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>

                                {arrayStatus[index] &&
                                  harvestOs.osList.map(os => {
                                    return (
                                      <ListItemButton sx={{ p: "2px 0px 2px 5%", mt: 0 }}>
                                        <ListItemText
                                          onClick={() => navigate(`/ordem-servico/${os.uuid}?page=fazenda`)}
                                          primary={os.name ? `#${os.id} ${os.name.slice(0, 1).toUpperCase() + os.name.substr(1)}` : `Ordem de Serviço #${os.id}`}
                                          sx={{ wordWrap: "break-word", maxWidth: "75%" }}
                                        />
                                        <ListItemSecondaryAction>
                                          <Grid container>
                                            <Grid item>
                                              <Tooltip title='Editar'>
                                                <EditOutlinedIcon
                                                  color='action'
                                                  onClick={() => {
                                                    setModalOsEdt(true)
                                                    setOsEdt(os)
                                                  }}
                                                />
                                              </Tooltip>
                                            </Grid>
                                            <Grid item>
                                              <Tooltip title='Excluir'>
                                                <Close
                                                  onClick={() => {
                                                    setOsEdt(os)
                                                    setModalDeleteOs(true)
                                                  }}
                                                  color='error'
                                                />
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                        </ListItemSecondaryAction>
                                      </ListItemButton>
                                    )
                                  })}
                              </>
                            )
                          })}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container direction='column' justifyContent='center'>
                      <Grid item md={12} xs={1}>
                        <Button
                          sx={{ fontSize: 16 }}
                          size='small'
                          fullWidth
                          onClick={() => {
                            setFildInitialValues(initialValue)
                            setFieldCoodinates([])
                            setModalField(true)
                          }}
                        >
                          <b>Novo talhão</b>
                        </Button>
                      </Grid>
                      <Divider />
                      {/* <Grid item md={12} xs={1}>
                            <Button sx={{ width: "100%" }}>Editar talhão</Button>
                          </Grid> */}
                      <Divider />
                      <Grid item md={12} xs={1}>
                        <Button size='small' fullWidth onClick={() => setModalOS(true)} sx={{ fontSize: 16 }}>
                          <b>Nova OS</b>
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item md={subMenuOpened ? 9 : 12} sx={{ borderLeft: "1px solid #c6c6c6", pl: 0, mt: 0 }}>
                  <Box display='flex' justifyContent='center' alignItems='start' sx={{ height: "100vh" }}>
                    <GoogleMap
                      data={{
                        mapContainerStyle: {
                          width: "100%",
                          height: "100%",
                          overflow: "visible !important",
                          position: "static !important",
                        },
                        setPropertiesCoodinates,
                        propertiesCoodinates,
                        importFile: false,
                        selectedFildID,
                        fields,
                      }}
                    />
                  </Box>

                  <Box
                    className='bit-bucket-btn'
                    onClick={() => {
                      setSubMenuOpened(!subMenuOpened)
                    }}
                  >
                    {subMenuOpened ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </LayoutPadrao>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default DetailsProperties
