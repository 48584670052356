import { Grid, Typography } from "@mui/material"
import NewWork from "../newWork"
import { Box } from "@mui/system"


const DetailsOrderServiceFull = ({ data }) => {
  return <>
    {
      data.selectedWork && <Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h3" gutterBottom component="div">
            {data.selectedWork['field'] ? `#${data.selectedWork.id}: ${  data.selectedWork['name']? data.selectedWork['name'] : data.selectedWork['field']['name']}` : ''}
          </Typography>
        </Box>
        <Grid item xs={12} sx={{ padding: '15px' }} >
          <Grid item >
            <NewWork data={{
              ...data,
              orderId: data.uuid,
              modalNewWork: false,
            }} />
          </Grid>
        </Grid>
      </Box>
    }
  </>
}

export default DetailsOrderServiceFull