
import { fetchError, fetchSuccess, showMessage } from "../../../redux/actions"
import AppTextField from "@crema/core/AppFormComponents/AppTextField"
import GoogleMap from "../../../shared/components/googleMap"
import { Fonts } from "../../../shared/constants/AppEnums"
import IntlMessages from "@crema/utility/IntlMessages"
import { Box, Grid, IconButton, Typography } from "@mui/material"
import jwtAxios from "@crema/services/auth/jwt-auth"
import LoadingButton from "@mui/lab/LoadingButton"
import { useDispatch } from "react-redux"
import { Form, Formik } from "formik"
import { useIntl } from "react-intl"
import * as yup from "yup"
import CloseIcon from "@mui/icons-material/Close"


const RegisterTalhao = ({ data }) => {
  const {
    setFieldCoodinates,
    fildInitialValues,
    fieldCoodinates,
    setModalField,
    getProperties,
  } = data
  const dispatch = useDispatch()
  const { messages } = useIntl()

  const validationSchema = yup.object({
    name: yup
      .string()
      .min(3, String(messages["validation.nameFormat"]))
      .required(String(messages["validation.nameRequired"])),
  })

  return (
    <>
      <Box sx={{ textAlign: "center", paddingY: 1,  backgroundColor: '#00800d' }}>
        <Typography
            sx={{
              fontSize: 28,
              fontWeight: Fonts.REGULAR,
              color: 'white'
            }}
          >
            Talhão{ fildInitialValues.name ? `: ${fildInitialValues.name}` : `` }
          </Typography>
      </Box>

      <Box sx={{ position: "absolute", padding: "0px", width: "100%" }}>
        <IconButton sx={{ float: "right", color: "white" }} onClick={() => setModalField(false)} size='large'>
          <CloseIcon fontSize='inherit' />
        </IconButton>
      </Box>
    
      <Box style={{width: '100%', height: '650px', overflow: 'auto'}} >
        <Box
          sx={{ paddingY: '15px', paddingX: '20px' }}
        >
          <Formik
            validateOnChange={true}
            initialValues={fildInitialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={async (dataForm: any, {setSubmitting}) => {
              try {
                if(!fieldCoodinates || !fieldCoodinates.length) dispatch(fetchError('Não foi possivel completar a requisição'))
                dataForm = {
                  ...dataForm,
                  coordinatesArray: fieldCoodinates[0]
                }

                if(dataForm['id']){
                  await jwtAxios.put("/fields/" + dataForm['id'], dataForm)
                  getProperties()
                  setModalField(false)
                  dispatch(fetchSuccess())
                  dispatch(showMessage('Alteração Salva'))

                } else {
                  await jwtAxios.post("/fields", dataForm)
                  getProperties()
                  setModalField(false)
                  dispatch(fetchSuccess())
                  dispatch(showMessage('Talhão Criado'))

                } 
                setSubmitting(false)
                
              } catch (e) {
                console.error(e)
                dispatch(fetchError('Não foi possivel completar a requisição'))
              }
            }}
          >
            {({isSubmitting}) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <AppTextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="false"
                      placeholder={messages["common.name"] as string}
                      name="name"
                      label={<IntlMessages id="common.name" />}
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AppTextField
                      autoComplete="false"
                      multiline
                      rows={2}
                      maxRows={4}
                      InputLabelProps={{ shrink: true }}
                      placeholder={messages["common.observation"] as string}
                      name="note"
                      label={<IntlMessages id="common.observation" />}
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <GoogleMap data={{
                      mapContainerStyle: {
                        width: "100%",
                        height: "400px",
                        overflow: 'visible !important',
                        position: 'static !important'
                      },
                      setPropertiesCoodinates: setFieldCoodinates,
                      propertiesCoodinates: fieldCoodinates,
                      importFile: true,
                    }} /> 

                  </Grid>
                </Grid>
                
                <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <LoadingButton
                      loading={isSubmitting}
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                    >
                      <IntlMessages id="common.save" />
                    </LoadingButton>
                  </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  )
}

export default RegisterTalhao
