export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENT = "GET_CLIENT";

export interface SearchClientsAction {
  type: typeof GET_CLIENTS;
  payload: any
}

export interface GetClientAction {
  type: typeof GET_CLIENT;
  payload: any
}


export type ClientsActionTypes = SearchClientsAction | GetClientAction
