import jwtAxios from "../../../@crema/services/auth/jwt-auth"
import messages from "../../../@crema/services/db/messages"
import { Fonts } from "../../../shared/constants/AppEnums"
import AppTextField from "@crema/core/AppFormComponents/AppTextField"
import { Grid, MenuItem, IconButton, TextField, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { LoadingButton } from "@mui/lab"
import { Form, Formik } from "formik"
import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import IntlMessages from "../../../@crema/utility/IntlMessages"
import { THEME_INPUT_FORM } from "../../../shared/themes/themeConst"
import CloseIcon from "@mui/icons-material/Close"
import moment from "moment"
import { useDispatch } from "react-redux"
import { fetchSuccess, showMessage } from "../../../redux/actions"

export const OsEdt = ({ data }) => {
  const dispatch = useDispatch()

  const {
    osSelected,
    setModalOsEdt,
    getProperties,
    propertyName,
    clientName,
    osStatus,
    listAllHarvest
  } = data

  const [loading, setLoading] = useState(false)
  const [InitialValues, setInitialValues] = useState({
    name: '',
    note: '',
    uuid: '',
    statusKey: '',
    harvestId: null
  })

  useEffect(() => {
    try {

      setInitialValues({
        name: osSelected['name'],
        note: osSelected['note'],
        uuid: osSelected['uuid'],
        statusKey: osSelected['status']['key'],
        harvestId: osSelected['harvestId']
      })

    } catch (error) {
      console.error(error)

    }

  }, [osSelected])

  const submitOS = async (dataSubimit) => {
    try {
      setLoading(true)
      let { data } = await jwtAxios.put("/orderService/" + osSelected.uuid, dataSubimit)
      await getProperties()
      
      dispatch(fetchSuccess())
      dispatch(showMessage('Ordem de Serviço atualizada'))
    } catch (error) {
      console.log(error)

    } finally {
      setLoading(false)

    }
  }
  return (
    <>
      <Box sx={{ textAlign: "center", paddingY: 2, mb: 3, backgroundColor: '#00800d' }}>
        <Typography
          sx={{
            fontSize: 30,
            fontWeight: Fonts.BOLD,
            color: 'white'
          }}>
          {`Ordem de Serviço: #${osSelected.id}`}
        </Typography>
      </Box>

      <Box sx={{ position: "absolute", padding: "0px", width: "100%" }}>
        <IconButton sx={{ float: "right", color: 'white' }} onClick={() => setModalOsEdt(false)} size='large'>
          <CloseIcon fontSize='inherit' />
        </IconButton>
      </Box>

      <Box>
        <Box>
          <Formik
            validateOnChange={true}
            initialValues={InitialValues}
            enableReinitialize
            onSubmit={osData => {
              submitOS(osData)
            }}>
            {({ values, setFieldValue }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete='off'>
                <Grid container sx={{ padding: "10px" }} spacing={3}>
                  <Grid justifyContent='space-evenly' item xs={12} sx={{ display: "flex", marginBottom: "10px" }}>
                    <Box sx={{ maxWidth: "50%", minWidth: "40%" }}>
                      <Typography variant='h4'>
                        <b>Cliente:</b> {`${clientName}`}
                      </Typography>
                    </Box>
                    <Box sx={{ maxWidth: "50%", minWidth: "40%" }}>
                      <Typography variant='h4'>
                        <b>Fazenda:</b> {`${propertyName}`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder={"Ordem de serviço"}
                      label={<IntlMessages id='common.name' />}
                      type='text'
                      onChange={e => { setFieldValue("name", e.target.value) }}
                      name='name'
                      value={values['name']}
                      InputLabelProps={{ shrink: true }}
                      sx={THEME_INPUT_FORM}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AppTextField
                      select
                      required
                      variant="outlined"
                      sx={THEME_INPUT_FORM}
                      InputLabelProps={{ shrink: true }}
                      placeholder={messages["common.harvest"] as string}
                      label={<IntlMessages id="common.harvest" />}
                      value={values['harvestId']}
                      name="harvestId"
                    >
                      {
                        listAllHarvest.map((element: any) => (
                          <MenuItem
                            key={element.id}
                            value={element.id}
                            onClick={() => { setFieldValue('harvestId', element.id) }}
                          >
                            {`${element.name}`}
                          </MenuItem>
                        ))
                      }
                    </AppTextField>

                  </Grid>


                  <Grid item xs={6}>

                    <AppTextField
                      select
                      required
                      variant="outlined"
                      sx={THEME_INPUT_FORM}
                      InputLabelProps={{ shrink: true }}
                      placeholder={messages["common.status"] as string}
                      label={<IntlMessages id="common.status" />}
                      value={values['statusId']}
                      name="statusKey"
                    >
                      {
                        osStatus.map((element: any) => (
                          <MenuItem
                            key={element.key}
                            value={element.key}
                            onClick={() => { setFieldValue('statusKey', element.key) }}
                          >
                            {`${element.name}`}
                          </MenuItem>
                        ))
                      }
                    </AppTextField>
                  </Grid>

                  <Grid item xs={12}>
                    <AppTextField
                      sx={THEME_INPUT_FORM}
                      placeholder={messages["common.observation"] as string}
                      label={<IntlMessages id='common.observation' />}
                      InputLabelProps={{ shrink: true }}
                      autoComplete='false'
                      variant='outlined'
                      name='note'
                      multiline
                      rows={6}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LoadingButton sx={{ width: "30%", float: "left" }} variant='contained' color='error' size='large' onClick={() => setModalOsEdt(false)}>
                      <IntlMessages id={"common.close"} />
                    </LoadingButton>
                    <LoadingButton loading={loading} sx={{ width: "30%", float: "right" }} variant='contained' color='primary' type='submit' size='large'>
                      <IntlMessages id={"common.save"} />
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  )
}