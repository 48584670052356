import React from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import IntlMessages from "../../@crema/utility/IntlMessages"

interface KeyModel {
  open: boolean,
  handleClose: any,
  textMessage: string,
  textTitle: string,
  loading: boolean,
  submitForm: any
}

interface Props {
  data: KeyModel
}

const AlertDelete: React.FC<Props> = ({ data }) => {

  const {
    open,
    handleClose,
    textMessage,
    submitForm,
    textTitle,
    loading,
  } = data

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3>
            {textTitle}
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {textMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Cancelar</Button>
          <LoadingButton sx={{
            width: "30%",
            float: "right",
            marginLeft: "1%"
          }}
            onClick={submitForm}
            variant='text'
            color='error'
            type='submit'
            loading={loading}
          >
            <IntlMessages id={"common.proceed"} />
          </LoadingButton>
        </DialogActions>
      </Dialog>

    </>

  )
}

export default AlertDelete
