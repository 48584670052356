import EdtProperties from "./Properties/edtProperty"
import ClientProperties from "./Properties"
import RegisterV2 from "./RegisterV2"
import React from "react"

const RegisterTalhao =  React.lazy(() => import("./Register/Properties/register-talhao"))
const List = React.lazy(() => import("./List"))

export const samplePagesConfigs = [
  {
    path: "/clientes",
    element: <List/>,
  },
  {
    path: "/clientes/novo",
    element: <RegisterV2 />,
  },
  {
    path: "/clientes/:id",
    element: <RegisterV2 />,
  },
  {
    path: "/clientes/:id/fazendas",
    element: <ClientProperties />,
  },
  {
    path: "/cliente/:clientId/fazenda",
    element: <EdtProperties />,
  },
  {
    path: "/cliente/:clientId/fazenda/:farmId",
    element: <EdtProperties />,
  },
  {
    path: "/clientes/:id/propriedades/:idPropriedade/talhao/:idTalhao",
    element: <RegisterTalhao />,
  },
]