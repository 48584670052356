import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"
import { allPreResultVariables, areaToLocaleString, fosfatagem, fosfatagemExedente, localeString } from "../../../../shared/utils/utils"
import { Fonts } from "../../../../shared/constants/AppEnums"
import { useEffect, useState } from "react"
import { THEME_H4_APLICATION, THEME_TABLE_ROW } from "../../../../shared/themes/themeConst"


export default function Phosphating({ data }) {
  const {
    setPreResultZones,
    preResultZones,
    setKeys,
    labKeys,
    labResult,
    formValues
  } = data
  
  const [totalArea, setTotalArea] = useState(0)
 
  useEffect(() => {

    const arrayKeys = Object.keys(labResult).sort((a:any, b:any) => a.split('-')[0] - b.split('-')[0])
    setKeys(arrayKeys)
    
    let auxZones = {}
    var auxTotalNC = 0
    var auxTotalArea = 0

    const key = arrayKeys[0]
    
    labResult[key].forEach((result) => {
      
      if(!(result.zone.name in auxZones)){
        auxZones[result.zone.name] = {
          totalNC: 0
        }
      }
      
      var resFosfatagem = fosfatagem( formValues['parameters']['P'], result['PRes'], result['SatArg'], formValues['parameters']['efficiencyFactor'] )
      var resFosfatagemExedente = fosfatagemExedente( formValues['parameters']['P'], result['PRes'])
      // var MAP = resFosfatagemExedente / 0.52 * formValues['parameters']['efficiencyFactor']
      // var _4_30_10 = resFosfatagemExedente / 0.3 * formValues['parameters']['efficiencyFactor']
      // var SFT = resFosfatagemExedente / 0.42 * formValues['parameters']['efficiencyFactor']

      if(resFosfatagem > 0){
        auxTotalNC = auxTotalNC + (result.zone.area * resFosfatagem)
      }

      auxZones[result.zone.name]['name'] = result.zone.name
      auxZones[result.zone.name]['area'] = result.zone.area
      auxZones[result.zone.name]['totalNC'] = resFosfatagem > 0 ? resFosfatagem : 0
      auxZones[result.zone.name][key] = {
        ...allPreResultVariables,
        fosfatagem: resFosfatagem > 0 ? resFosfatagem : 0,
        exedente: resFosfatagemExedente > 0 ? resFosfatagemExedente : 0,
        PRes: result['PRes'],
        SatArg: result['SatArg'],
        // MAP: MAP > 0 ? MAP : '-',
        // _4_30_10: _4_30_10 > 0 ? _4_30_10 : '-',
        // SFT: SFT > 0 ? SFT : '-'
      }

      auxTotalArea += result.zone.area ? result.zone.area : 0
    })

    setPreResultZones({
      totalNC: auxTotalNC,
      zones: Object.values(auxZones)
    })
    setTotalArea(auxTotalArea)
  
  }, [])

  return (
    <Grid container sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ overflow: 'hidden' }}>
        <Table size="small" stickyHeader >
        <caption><h4 style={THEME_H4_APLICATION}>Total NC: {localeString(preResultZones.totalNC)} (Kg)</h4></caption>
        <caption><h4 style={THEME_H4_APLICATION}>Área Total: {localeString(totalArea)} (ha)</h4></caption>
          <TableHead>
            <TableRow sx={THEME_TABLE_ROW}> 
              <TableCell style={{minWidth: 75}} align="center">Zona</TableCell>
              <TableCell style={{minWidth: 75}} align="center">Área</TableCell>
              <TableCell style={{minWidth: 75}} align="center">Profundidade</TableCell>
              <TableCell style={{minWidth: 75}} align="center">P (Res)</TableCell>      
              <TableCell style={{minWidth: 75}} align="center">Arg (g/kg)</TableCell>
              <TableCell style={{minWidth: 75}} align="center">P₂O₂ (Kg/ha)</TableCell>
              <TableCell style={{minWidth: 75}} align="center">Excedente P₂O₂ (Kg/ha)</TableCell>
              <TableCell style={{minWidth: 75}} align="center">Total</TableCell>
              {/* <TableCell style={{minWidth: 75}} align="center">MAP</TableCell>
              <TableCell style={{minWidth: 75}} align="center">4-30-10</TableCell>
              <TableCell style={{minWidth: 75}} align="center">SFT</TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {
              preResultZones.zones.map(((zone, index) => {
                if(!zone[labKeys[0]]) return <></>
                return (
                  <TableRow hover key={index} sx={THEME_TABLE_ROW}>
                    <TableCell align="center"> {zone.name} </TableCell>
                    <TableCell align="center"> {areaToLocaleString(zone.area)} </TableCell>
                    <TableCell align="center"> {labKeys[0]} </TableCell>
                    <TableCell align="center">{!zone[labKeys[0]]['PRes'] ? '' : areaToLocaleString(zone[labKeys[0]]['PRes'])}</TableCell>
                    <TableCell align="center">{localeString(zone[labKeys[0]]['SatArg'])}</TableCell>
                    <TableCell align="center">{localeString(zone[labKeys[0]]['fosfatagem'])}</TableCell>
                    <TableCell align="center">{localeString(zone[labKeys[0]]['exedente'])}</TableCell>
                    <TableCell align="center">{localeString(zone.area * zone['totalNC'])}</TableCell>
                    {/* <TableCell align="center">{zone[labKeys[0]]['MAP')}</TableCell>
                    <TableCell align="center">{zone[labKeys[0]]['_4_30_10')}</TableCell>
                    <TableCell align="center">{zone[labKeys[0]]['SFT')}</TableCell> */}
                  </TableRow>
                )
              }))
            }
          </TableBody>
        </Table>
        
      </TableContainer>
    </Grid>
  )
}